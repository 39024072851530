import React from "react";
import styles from "../styles/Card.module.css";

export default function Card({img, title, desc, children}) {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img src={img} alt={title} />
      </div>
      <div className={styles.info}>
        <div className={styles.title}> {title} </div>
        <div className={styles.desc}> {desc} </div>
        {children}
      </div>
    </div>
  );
}
