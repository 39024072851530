import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Trainee/TraineeInfo.module.css";
import { VscTriangleRight } from "react-icons/vsc";
import { FaSquare } from "react-icons/fa";
import { FRONTEND_URI } from "../../../components/ClientWrapper";
import { Link } from "react-router-dom";

const KYA_PATH = `${FRONTEND_URI}/KYA_ΠρακτικήΆσκηση.pdf`;
const DETAILED_INFO_PATH = `${FRONTEND_URI}/ΑναλυτικηΠεριγραφη.pdf`;
const MY_APPS_PATH = `/trainee/application`;
const MANUAL_PATH = `${FRONTEND_URI}/ΕγχειρίδιοΧρήσηςΩφελούμενων.pdf`;
const CONTACT_PATH = `${FRONTEND_URI}/Επικοινωνία.pdf`;

export const TraineeInfo = () => {
  return (
    <Panel headerBar="Πληροφορίες">
      <div className={styles.infoContainer}>
        <div className={styles.infoSegment}>
          <div className={styles.title}>
            {" "}
            Το έργο «Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και
            απόκτησης επαγγελματικής εμπειρίας»{" "}
          </div>
          <div className={styles.info}>
            Σκοπός του έργου με τίτλο «Ειδικά προγράμματα διεξαγωγής πρακτικής
            άσκησης και απόκτησης επαγγελματικής εμπειρίας» αποτελεί η ανάπτυξη
            ενός ολοκληρωμένου συστήματος ένταξης των εκπαιδευόμενων Ι.Ε.Κ.,
            στην αγορά εργασίας, μέσω της απόκτησης επαγγελματικής εμπειρίας με
            πρακτική άσκηση. Απευθύνεται σε 2.000 εκπαιδευόμενους από δημόσια
            Ι.Ε.Κ. του Υπουργείου Παιδείας και Θρησκευμάτων και ιδιωτικά Ι.Ε.Κ.,
            οι οποίοι έχουν ολοκληρώσει το θεωρητικό μέρος των σπουδών τους, ενώ
            δεν έχουν πραγματοποιήσει το εξάμηνο της πρακτικής άσκησης και ως εκ
            τούτου δεν έχουν λάβει την αντίστοιχη πιστοποίηση από τον
            Ε.Ο.Π.Π.Ε.Π. Αφορά τόσο τους ωφελούμενους που ξεκινούν για πρώτη
            φορά την πρακτική τους άσκηση όσο και αυτούς που, για οποιοδήποτε
            λόγο, διέκοψαν ή δεν πρόλαβαν να ολοκληρώσουν την πρακτική τους
            άσκηση.
          </div>
          <div className={styles.info}>
            Το έργο υλοποιείται από σύμπραξη φορέων των κοινωνικών εταίρων: ΙΝΕ
            & ΚΑΝΕΠ ΓΣΕΕ, ΙΜΕ/ ΓΣΕΒΕΕ, ΚΑΕΛΕ/ ΕΣΕΕ, καθώς και ΕΟΠΠΕΠ με
            Δικαιούχο-Συντονιστή Φορέα το ΙΝΕ/ ΓΣΕΕ. Οι φορείς του έργου
            αναλαμβάνουν την υλοποίηση του στις περιφέρειες εφαρμογής
            (διαφορετικός φορέας ανά περιφέρεια).
          </div>
          <div className={styles.title}> Καινοτομικά στοιχεία του έργου </div>
          <div className={styles.info}>
            Τα καινοτομικά στοιχεία του έργου αναφέρονται κυρίως στη μεθοδολογία
            υλοποίησης της πρακτικής άσκησης που εστιάζει σε ποιοτικά στοιχεία
            στα επίπεδα: <br></br>
            <br></br>- Της σύζευξης ωφελούμενων και επιχειρήσεων (σύμβουλοι
            σύζευξης που διερευνούν τη συμβατότητα ωφελούμενου – θέσης
            πρακτικής, με εργαλεία σύζευξης και ατομικές συνεδρίες)<br></br>-
            Της διερεύνησης των αναγκών, προσδοκιών και δυνατοτήτων των
            ωφελούμενων αλλά και της ικανοποίησης τους ή όχι από τη θέση
            πρακτικής (μέντορες υποστήριξης)<br></br>- Της διερεύνησης των
            συνθηκών υλοποίησης της πρακτικής άσκησης, ώστε να αποφεύγονται
            καταχρηστικές πρακτικές από την πλευρά των επιχειρήσεων, να
            διασφαλίζεται ο εκπαιδευτικός χαρακτήρας της πρακτικής άσκησης κλπ.
            (Ποιοτικός έλεγχος πρακτικής άσκησης).
          </div>
          <div className={styles.title}> Δράσεις του έργου </div>
          <div className={styles.infoBullets}>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Πρόσκληση εκπαιδευόμενων και επιχειρήσεων για συμμετοχή στο έργο
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Επιλογή 2.000 εκπαιδευόμενων ΙΕΚ, ως εξής: <br></br>
              </div>
            </div>
            <div className={styles.infoBullets}>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Ανατολική Μακεδονία / Θράκη 178 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Δυτική Ελλάδα 314 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στη Δυτική Μακεδονία 40 εκπαιδευόμενοι (ΙΝΕ ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βόρειο Αιγαίο, 41 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Ήπειρο 118 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Θεσσαλία 313 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στα Ιόνια Νησιά 22 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βόρειο Αιγαίο, 41 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στη Στερεά Ελλάδα 128 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Κεντρική Μακεδονία 270 εκπαιδευόμενοι (ΚΑΕΛΕ/ΕΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Πελοπόννησο 100 εκπαιδευόμενοι (ΚΑΕΛΕ/ΕΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Κρήτη 120 εκπαιδευόμενοι (ΚΑΕΛΕ /ΕΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Αττική 314 εκπαιδευόμενοι (ΚΑΝΕΠ/ΓΣΕΕ)
                </div>
              </div>
            </div>

            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Παροχή εξ αποστάσεως ασύγχρονης θεωρητικής κατάρτισης 25 ωρών σε
                οριζόντιες επαγγελματικές και κοινωνικές δεξιότητες προς όλους
                τους εκπαιδευόμενους που θα επιλεγούν. Οι θεματικές ενότητες που
                θα διδαχθούν είναι οι εξής:
              </div>
            </div>
            <div className={styles.infoBullets}>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Επαγγελματικό περιβάλλον & δεοντολογία
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Επικοινωνιακές δεξιότητες και διαχείριση συγκρούσεων
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Υγεία και ασφάλεια στην εργασία
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Δεξιότητες πλοήγησης στην αγορά εργασίας
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βασικές αρχές λειτουργίας των επιχειρήσεων
                </div>
              </div>
            </div>
            <div className={styles.underlinedInfo}>
              Επισημαίνεται ότι η επιτυχής ολοκλήρωση της θεωρητικής κατάρτισης
              αποτελεί απαραίτητη προϋπόθεση για την περαιτέρω συμμετοχή των
              εκπαιδευόμενων στο έργο
            </div>

            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Συμβουλευτική υποστήριξη των εκπαιδευόμενων, από την εισαγωγή
                τους στο πρόγραμμα και σε όλη τη διάρκεια της θεωρητικής τους
                εκπαίδευσης και της πρακτικής τους άσκησης από μέντορες
                συμβουλευτικής υποστήριξης. (8 συνεδρίες συμβουλευτικής
                εκπαιδευόμενο)
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Σύζευξη / τοποθέτηση των 2.000 εκπαιδευόμενων Ι.Ε.Κ. με
                αντίστοιχες θέσεις σε επιχειρήσεις βάσει συγκεκριμένου
                συστήματος/μεθοδολογίας. Εξειδικευμένοι σύμβουλοι εκπαιδευομένων
                και επιχειρήσεων (τακτικό και έκτακτο προσωπικό των εταίρων του
                έργου) με τη χρήση ειδικά διαμορφωμένων εργαλείων θα
                εξειδικεύσουν το ταίριασμα του προφίλ των εκπαιδευομένων με τις
                προσφερόμενες θέσεις πρακτικής άσκησης.
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Υλοποίηση πρακτικής άσκησης διάρκειας 960 ώρες (έως 6 μήνες) για
                τους εκπαιδευόμενους του προγράμματος.
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Παρακολούθηση και ποιοτικός έλεγχος της διαδικασίας πρακτικής
                άσκησης.
              </div>
            </div>
          </div>

          <div className={styles.title}>
            H πρακτική άσκηση και η συμμετοχή των ωφελούμενων
          </div>
          <div className={styles.info}>
            Η πρακτική άσκηση καθώς και η συμμετοχή ωφελούμενων και επιχειρήσεων
            στο έργο γίνεται βάση του{" "}
            <a href={`${KYA_PATH}`} target="_blank" rel="noopener noreferrer">
              ΦΕΚ 3938/Β/26-8-2021 Κοινή Υπουργική Απόφαση υπ’ αριθμ. Κ5/97484.
            </a>{" "}
            Στο πλαίσιο του έργου καλούμε τους ωφελούμενους / Εκπαιδευόμενους
            ΙΕΚ που έχουν ολοκληρώσει τη θεωρητική τους εκπαίδευση να κάνουν την
            αίτησή τους για συμμετοχή στο έργο. Η σύζευξη των εκπαιδευόμενων με
            τις προσφερόμενες θέσεις πρακτικής θα γίνει με βάση τα κριτήρια:
            ειδικότητα, πόλη, περιφέρεια των αιτούμενων, μέσω της ηλεκτρονικής
            πλατφόρμας και θα εξειδικευτεί περαιτέρω από τους σύμβουλους του
            έργου.
          </div>
          <div className={styles.title}>Επιλέξιμοι ωφελούμενοι</div>

          <div className={styles.info}>
            Δυνητικά επιλέξιμοι για συμμετοχή είναι όσοι:
          </div>
          <div className={styles.infoBullets}>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Είναι εκπαιδευόμενοι από δημόσια Ι.Ε.Κ. του Υπουργείου Παιδείας
                και Θρησκευμάτων καθώς και από ιδιωτικά
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Δεν έχουν πραγματοποιήσει το εξάμηνο της πρακτικής άσκησης
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Δεν έχουν λάβει την αντίστοιχη πιστοποίηση από τον Ε.Ο.Π.Π.Ε.Π.
              </div>
            </div>
          </div>
          <div className={styles.underlinedInfo}>Επισημαίνεται ότι:</div>
          <div className={styles.info}>
            - Και οι παλιότεροι ωφελούμενοι που ολοκλήρωσαν τη θεωρητική
            κατάρτιση, αλλά δεν έχουν πραγματοποιήσει πρακτική άσκηση, έως την
            έναρξη ισχύος του 4763/2020, είναι επιλέξιμοι για συμμετοχή στο έργο
            <br></br>- Οι υποψήφιοι δεν θα πρέπει να συμμετέχουν σε άλλο
            πρόγραμμα πρακτικής άσκησης κατά το ίδιο χρονικό διάστημα. Εφόσον
            πληρώνονται οι παραπάνω προϋποθέσεις, η επιλογή των ωφελούμενων θα
            γίνει με χρονολογική σειρά υποβολής της αίτησης. Εφόσον πληρώνονται
            οι παραπάνω προϋποθέσεις, η επιλογή των ωφελούμενων θα γίνει με
            χρονολογική σειρά υποβολής της αίτησης. <br></br>- Μέχρι να
            συμπληρωθεί ο απαιτούμενος αριθμός ανά περιφέρεια. Το τελικό Μητρώο
            των ωφελούμενων για τοποθέτηση σε πρακτική άσκηση θα περιλαμβάνει
            όσους από τους επιτυχώς αιτούντες ολοκληρώσουν τη θεωρητική
            εκπαίδευση των 25 ωρών (εξ αποστάσεως, ασύγχρονη)
          </div>

          <div className={styles.title}>Το επίδομα πρακτικής άσκησης </div>
          <div className={styles.info}>
            Προβλέπεται η χορήγηση αμοιβής πρακτικής άσκησης στους αποδέκτες του
            προγράμματος (επίδομα ύψους 3,185€/ώρα).
          </div>
          <div className={styles.title}>Οι επιχειρήσεις πρακτικής άσκησης</div>
          <div className={styles.info}>
            Σύμφωνα με το ισχύον θεσμικό πλαίσιο που διέπει τη λειτουργία των
            δημοσίων και ιδιωτικών Ι.Ε.Κ. η πρακτική άσκηση πραγματοποιείται σε
            φορείς του δημόσιου τομέα, σε φυσικό ή νομικό πρόσωπο ιδιωτικού
            δικαίου ή επιχείρηση, σε αντικείμενα αντίστοιχα της ειδικότητας
            του/της εκπαιδευόμενου/ης (Νόμος 4186-2013 ΦΕΚ 17/9/2013).
            <br></br>Εξαιρούνται οι φορείς:{" "}
          </div>
          <div className={styles.infoBullets}>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>Προσωρινής απασχόλησης</div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>Τα νυχτερινά κέντρα</div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Παροχής καθαριότητας και φύλαξης
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Τα πρακτορεία τυχερών παιχνιδιών
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Κάθε επιχείρηση στην οποία δεν είναι εφικτός ο έλεγχος της
                εκπαίδευσης από τον αρμόδιο φορέα.
              </div>
            </div>
          </div>
          <div className={styles.info}>
            Η υλοποίηση της πρακτικής άσκησης υλοποιείται σύμφωνα με τα
            οριζόμενα από το{" "}
            <a href={`${KYA_PATH}`} target="_blank" rel="noopener noreferrer">
              ΦΕΚ 3938/Β/26-8-2021 Κοινή Υπουργική Απόφαση υπ’ αριθμ. Κ5/97484.
            </a>{" "}
            Πρακτική άσκηση σπουδαστών Ινστιτούτων Επαγγελματικής Κατάρτισης
            αρμοδιότητας Υπουργείου Παιδείας και Θρησκευμάτων. <br></br>
            <br></br>Η πρακτική άσκηση συνδέεται άρρηκτα με τη θεωρητική
            κατάρτιση, αφού κατά τη διάρκειά της οι πρακτικά ασκούμενοι/ες
            ανακαλούν τη θεωρητική και εργαστηριακή γνώση για να την εφαρμόσουν
            στην πράξη και να ανταπεξέλθουν στις εργασίες που τους ανατίθενται.
            Καλούνται να αναλάβουν συγκεκριμένα καθήκοντα και να δώσουν λύση σε
            πρακτικά προβλήματα που ανακύπτουν, υπό την εποπτεία των
            εκπαιδευτών/τριών. Έτσι, ο θεσμός της πρακτικής άσκησης στοχεύει
            στην ανάπτυξη επαγγελματικών ικανοτήτων/ δεξιοτήτων σχετικών με την
            ειδικότητα, στην ενίσχυση της επαφής με τον εργασιακό χώρο και την
            προετοιμασία των εκπαιδευομένων για την παραγωγική διαδικασία - μέσω
            της απόκτησης εμπειριών ιδιαίτερα χρήσιμων για την μετέπειτα
            επαγγελματική τους πορεία.
            <br></br>
            <br></br>
            Αναλυτικότερα, η πρακτική άσκηση είναι υποχρεωτική για τους
            εκπαιδευόμενους των Ινστιτούτων Επαγγελματικής Κατάρτισης (Ι.Ε.Κ.)
            και θεωρείται απαραίτητη προϋπόθεση για την απόκτηση Βεβαίωσης
            Επαγγελματικής Κατάρτισης (Άρθρο 27 του Ν. 4763/2020 για το Εθνικό
            Σύστημα Επαγγελματικής Εκπαίδευσης, Κατάρτισης και Διά Βίου Μάθησης
            <br></br>
            Για περισσότερες πληροφορίες σχετικά με το έργο{" "}
            <a
              href={`${DETAILED_INFO_PATH}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              δείτε εδώ
            </a>
          </div>

          <div className={styles.title}>Η υποβολή της αίτησης</div>
          <div className={styles.info}>
            Η αίτηση συμμετοχής πραγματοποιείται ηλεκτρονικά στην πλατφόρμα{" "}
            <Link to={`${MY_APPS_PATH}`}>εδώ</Link>
            <br></br>
            Για την ολοκλήρωση της αίτησής θα χρειαστεί να επισυνάψετε (αρχεία
            pdf):<br></br>
            <br></br>
            <div className={styles.infoBullets}>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βεβαίωση ολοκλήρωσης θεωρητικής εκπαίδευσης με αναφορά στο ότι
                  δεν έχετε υλοποιήσει πρακτική άσκηση (από το ΙΕΚ φοίτησης)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Υπεύθυνη δήλωση περί μη συμμετοχής σας σε άλλο πρόγραμμα
                  πρακτικής.
                </div>
              </div>
            </div>
            <br></br>Η πρόσκληση είναι ανοιχτή και μπορείτε να κάνετε την αίτηση
            σας, μέχρι να συμπληρωθούν οι διαθέσιμες θέσεις ανά περιφέρεια. Οι
            ωφελούμενοι γίνονται δεκτοί με βάση τη χρονική σειρά κατάθεσης της
            αίτησης και εφόσον πληρούν τα κριτήρια συμμετοχής.
            <br></br>
            <br></br>
            Μετά την υποβολή και οριστικοποίηση της αίτησής θα σας δοθεί ο
            Κωδικός Αριθμός Υποβολής Αίτησης Συμμετοχής (ΚΑΥΑΣ)
            <br></br>
            Παρακαλούμε σημειώστε τον (ΚΑΥΑΣ) γιατί θα τον χρειαστείτε για κάθε
            μελλοντική αναφορά στην αίτησή σας.
            <br></br>
            <br></br>
            Πριν την ένταξή σας στο έργο θα πραγματοποιηθεί έλεγχος των
            στοιχείων που έχετε αναρτήσει, από τον πάροχο κατάρτισης και σε
            περίπτωση αρνητικού ελέγχου, η αίτησή σας θα απορριφθεί.
            <br></br>
            Μπορείτε να δείτε αναλυτικά τις{" "}
            <a
              href={`${MANUAL_PATH}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              οδηγίες για τη χρήση της πλατφόρμας και τη συμπλήρωση της αίτησης
              σας εδώ.
            </a>
            <br></br>
            <br></br>
            Σε περίπτωση που αντιμετωπίζετε οποιοδήποτε πρόβλημα σχετικά με την
            υποβολή της αίτησής σας παρακαλούμε{" "}
            <a
              href={`${CONTACT_PATH}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              επικοινωνήστε
            </a>{" "}
            με τα κατά τόπους παραρτήματα των φορέων υλοποίησης<br></br>
          </div>
        </div>
      </div>

      <div className={styles.infoSegment}>
        <div className={styles.title}> Απαιτούμενα Δικαιολογητικά </div>
        <div className={styles.infoBullets}>
          <div className={styles.infoBullet}>
            <VscTriangleRight className={styles.bullet} />
            <div className={styles.info}>
              Βεβαίωση ολοκλήρωσης θεωρητικής εκπαίδευσης με αναφορά στο ότι δεν
              έχετε υλοποιήσει πρακτική άσκηση (από το ΙΕΚ φοίτησης)
            </div>
          </div>
          <div className={styles.infoBullet}>
            <VscTriangleRight className={styles.bullet} />
            <div className={styles.info}>
              Υπεύθυνη δήλωση περί μη συμμετοχής σας σε άλλο πρόγραμμα
              πρακτικής.
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
