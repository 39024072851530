import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

import { ReactComponent as Pdf } from '../../assets/images/pdf.svg';
import { ReactComponent as Zip } from '../../assets/images/zip.svg';
import { ReactComponent as Png } from '../../assets/images/png.svg';
import { ReactComponent as Doc } from '../../assets/images/doc.svg';
import { ReactComponent as File } from '../../assets/images/file.svg';

export const DispatchFileIcon = (name) => {
  const extension = name.split('.').pop();

  if (
    extension === '7z' ||
    extension === 'arj' ||
    extension === 'deb' ||
    extension === 'pkg' ||
    extension === 'rar' ||
    extension === 'rpm' ||
    extension === 'tar.gz' ||
    extension === 'z' ||
    extension === 'zip'
  ) {
    return <Zip className="zip" />
  }

  if (
    extension === 'ai' ||
    extension === 'bmp' ||
    extension === 'gif' ||
    extension === 'ico' ||
    extension === 'jpeg' ||
    extension === 'jpg' ||
    extension === 'png' ||
    extension === 'ps' ||
    extension === 'psd' ||
    extension === 'svg' ||
    extension === 'tif' ||
    extension === 'tiff'
  ) {
    return <Png className="png" />;
  }

  if (
    extension === 'doc' ||
    extension === 'docx'
  ) {
    return <Doc className="doc" />;
  }

  if (
    extension === 'pdf'
  ) {
    return <Pdf className="pdf" />;
  }

  return <File className="defaultFile" />;

}

// ---------------------------------------------------------------------------------------

export class FileInput extends Input {

  constructor(props) {

    super(props);

    this.onChange = this.onChange.bind(this);

    this.state.dropDepth = 0;
    this.state.inDropContainer = false;

    const value = props.initialValue || props.description.value || [];

    this.state.files = Array.isArray(value) ? value : [value];
  }

  setData(data, onFinish) {
    let data_ = data;

    if (!data) data_ = [];
    else if (!Array.isArray(data)) data_ = [data];

    this.valueUpdate(this.state.files, data);

    this.setState({
      files: data_
    }, onFinish);
  }

  getData() {
    if (!this.state.files.length) return null;

    if (this.props.description.maxFiles === 1)
      return this.state.files[0];

    return this.state.files;
  }

  clear() {
    this.setState({ files: [] });
  }

  hideQuestion({ clearValue = true }) {
    const newVal = (clearValue) ? [] : this.state.files;
    !newVal && this.valueUpdate(this.state.value);
    this.setState({ files: newVal, isHidden: true });
  }

  onDragEnter = e => {
    this.setState(prevState => (
      {
        inDropContainer: true,
        dropDepth: prevState.dropDepth + 1
      }
    ));
  }

  onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ inDropContainer: true });
  }

  onDragLeave = e => {
    this.setState(prevState => {
      return {
        inDropContainer: prevState.dropDepth <= 1 ? false : true,
        dropDepth: prevState.dropDepth - 1
      };
    });
  }

  onDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);

    this.addFiles(files);

    e.dataTransfer.clearData()
  }

  addFiles = files => {
    if (files && files.length > 0 && files.length) {
      this.setState(prevState => {
        if (prevState.files.length + files.length > this.props.description.maxFiles) {
          return { inDropContainer: false, dropDepth: 0 };
        }
        else {
          const files_ = [...prevState.files, ...files];

          return { files: files_, inDropContainer: false, dropDepth: 0 };
        }
      });
    }
  }
  
  onChange = e => {
    const files = Array.from(e.target.files);

    e.target.value = null; /* clears the input DOM node so that deleting a file and repicking it works as intended */

    this.valueUpdate(this.state.value, [this.state.files, ...files]);
    this.addFiles(files);
  }

  removeFile = index => {
    this.setState(prevState => {
      const files = [...prevState.files];

      files.splice(index, 1);

      return { files };
    });
  }

  render() {

    if (this.state.isHidden) {
      return null;
    }

    return <>

      {
        this.props.readOnly ?
          this.props.label
          :
          <div
            ref={this.labelRef}
            className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
          >
            {this.props.label}
          </div>
      }

      {
        !this.props.readOnly && (
          this.props.description.maxFiles === undefined ||
          this.state.files < this.props.description.maxFiles) &&

        <div
          className={this.state.inDropContainer ? "drag-over drop-container" : "drop-container"}
          onDragEnter={this.onDragEnter}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
        >
          <div className='drop-message'>
            <div> Σύρετε εδώ ή </div>
            <label className='link'>
              Επιλογή Αρχείου
              <input type='file' onChange={this.onChange} />
            </label>
          </div>
        </div>
      }

      {
        this.props.readOnly && this.state.files.length === 0 &&
        <div className="readOnly-value-wrap"> - </div>
      }

      {
        this.state.files.length > 0 &&
        <div className={`files ${this.props.readOnly ? 'readOnly-value-wrap' : ''}`}>
          {
            this.state.files.map((file, index) =>
              <div
                key={index}
                className="file"
                onClick={
                  () => this.props.description.callbacks?.onClick?.(file)
                }
              >
                {DispatchFileIcon(file.name)}
                {file.name}
                {
                  !this.props.readOnly &&
                  <div
                    className="file-x"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.removeFile(index)
                    }}
                  > ✖
                  </div>
                }
              </div>
            )
          }
        </div>
      }

      {
        !this.props.readOnly && this.state.problem &&
        <ErrorPrompt>{this.state.problem}</ErrorPrompt>
      }
    </>
  }

};

// ---------------------------------------------------------------------------------------