import { FRONTEND_URI } from "../components/ClientWrapper";

export const formValueToArray = (formValue, description, file2csv) => {
  const headers = [];
  const data = [];
  const arrayIndices = [];

  description = JSON.parse(JSON.stringify(description));

  const helper = (app, description) => {
    if (!description || description.type === 'label') return;

    if (!description.questions) {
      const value = app?.[description.name];

      headers.push(description.csvTitle || description.title);

      if (value === null || value === undefined) data.push('-');
      else if (description.type === 'multiple-choice') data.push(value.join(', '));
      else if (description.type === 'dropdown') {
        const selectedChoice = description.choices.find(choice => choice.value === value);

        data.push(selectedChoice?.title || value);
      }
      else if (description.type === 'file') {
        data.push( file2csv?.(value, formValue._id) || (value.name !== undefined ? value.name : '✓') );
      }
      else if (description.type === 'checkbox') data.push(value ? '✓' : '✕');
      else data.push(value);

      return;
    }

    if (description.type === 'form-array') {
      const formArray = app[description.name];

      if (!formArray) return;

      // keep the start and end of the arrays that get created so that
      // they can be aligned (for multiple rows)
      const startIndex = data.length;

      description.type = 'form';

      for (const form of formArray)
        for (const question of description.questions)
          helper(form, question)

      description.type = 'form-array';

      const endIndex = data.length;

      arrayIndices.push({ start: startIndex, end: endIndex })
    }
    else {
      for (const question of description.questions)
        helper(app[description.name], question);
    }
  }

  helper({ [description.name]: formValue }, description);

  const result = { headers, data: data, arrayIndices };

  return result;
}

export const mergeCsvRows = (headers, data, arrayIndices) => {
  if (!arrayIndices.length) return { headers: headers[0] || [], data: data};

  for (let i = arrayIndices[0].length - 1; i >= 0; --i) {
    let maxEndArrayIndexPair = arrayIndices[0][i];
    let maxEndArrayIndexPairIndex = 0;

    for (let j = 0; j < arrayIndices.length; ++j) {
      const arrayIndexPair = arrayIndices[j][i];

      if (arrayIndexPair.end - arrayIndexPair.start > maxEndArrayIndexPair.end - maxEndArrayIndexPair.start) {
        maxEndArrayIndexPair = arrayIndexPair;
        maxEndArrayIndexPairIndex = j;
      }
    }

    const maxEndArrayIndexPairLength = maxEndArrayIndexPair.end - maxEndArrayIndexPair.start;

    // make empty data and insert headers so that the csv columns match
    for (let j = 0; j < arrayIndices.length; ++j) {
      const arrayIndexPair = arrayIndices[j][i];

      const length = arrayIndexPair.end - arrayIndexPair.start;
      const lengthDifference =  maxEndArrayIndexPairLength - length;

      if (j === 0) {
        // handle headers
        const extraHeaders = headers[maxEndArrayIndexPairIndex].slice(
          maxEndArrayIndexPair.end - lengthDifference,
          maxEndArrayIndexPair.end
        );

        headers[0].splice(arrayIndexPair.end, 0, ...extraHeaders)
      }

      const extraData = Array(lengthDifference).fill('-');

      data[j].splice(arrayIndexPair.end, 0, ...extraData);
    }
  }

  return {
    headers: headers[0],
    data: data
  };
}

// ----------------------------------------------------------------------------------------------

export const file2csv = (subsystem) => {
  console.assert(subsystem);

  return (file, appId) => {
    if (!file || !appId) return false;

    const id = typeof file === 'string' ? file : file._id;
    
    return id ? `${FRONTEND_URI}/${subsystem}/application/file/download/${appId}/${id}`: false;
  };
}

export const generateApplicantsCsv = (apps, description, file2csv) => {
  const data = [], headers = [], arrayIndices = [];
  
  for (const app of apps){
    const csvData = formValueToArray(app, description, file2csv);

    data.push(csvData.data);
    headers.push(csvData.headers);
    arrayIndices.push(csvData.arrayIndices);
  }

  const mergedRows = mergeCsvRows(headers, data, arrayIndices);

  mergedRows.headers.push('Αξιολόγηση', 'Σχόλιο Αξιολόγησης');
  mergedRows.headers.unshift('ΚΑΥΑΣ');

  for (let i = 0; i < mergedRows.data.length; ++i) {
    const dataRow = mergedRows.data[i];
    const app = apps[i];

    dataRow.unshift(app.kayas);
    dataRow.push(app.isEvaluated === false ? '-' : app.evaluation.accepted ? 'Αποδεκτή' : 'Απορριπτέα');
    dataRow.push(app.evaluation.comment || '-');
  }

  return { headers: mergedRows.headers, data: mergedRows.data };
}