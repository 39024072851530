import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import { URI, useClient } from '../../../components/ClientWrapper';
import GridWithFilters from '../../../components/GridWithFilters';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { usePageInfo } from '../../../components/PageInfoWrapper';
import { Panel } from '../../../components/Panel'
import { _ } from "gridjs-react";
import ProgramDropdown from '../../../components/ProgramDropdown';
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import styles from '../../../styles/Business/BusinessRegistryRankings.module.css'
import { IoOpenOutline } from "react-icons/io5";
import { useToasts } from '../../../components/ToastMessageWrapper';
import { AcceptRejectModal } from '../../../components/AcceptRejectModal';
import { file2csv, generateApplicantsCsv } from '../../../lib/csv';
import businessApplicationJson from '../../../assets/data/business-application.json';
import { localeDateStringWithTime } from '../../../util/util';

//TODO complete case when program has no rankings

const columns = [
    {
        id: "id",
        name: "id",
        hidden: true,
    },
    {
        id: "ranking",
        name: "#",
    },
    {
        id: "kayas",
        name: "ΚΑΥΑΣ",
    },
    {
        id: "name",
        name: "Επωνυμία",
    },
    {
        id: "afm",
        name: "Α.Φ.Μ",
    },
    {
        id: "finalizeDate",
        name: "Οριστικοποίηση",
    },
    {
        id: "open",
        width: 30,
        sort: false,
    },
];


const language = {
    search: {
        placeholder: "Αναζήτηση",
    },
    pagination: {
        previous: "Προηγούμενο",
        next: "Επόμενο",
        showing: "Προβάλλονται οι Αιτήσεις",
        to: "-",
        of: "από",
        results: () => "",
    },
    noRecordsFound: "Δεν βρέθηκαν αιτήσεις",
};



function BusinessRegistryAllRankings() {
    const [applicants, setApplicants] = useState();
    const [applicantsCsv, setApplicantsCsv] = useState({ data: [] });
    const [selectedProgram, setSelectedProgram] = useState();
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const addToast = useToasts();
    const axios = useClient();
    const navigate = useNavigate();

    const openIndex = columns.findIndex(col => col.id === "open");

    columns[openIndex].formatter = (cell, row) => {
        return _(
            <div className={styles.openButtonContainer}>
                <IoOpenOutline
                    className={styles.openButton}
                    onClick={() => navigate(`../${row.cells[0].data}`)}
                />
            </div>
        );
    };

    function beginRanking() {
        axios
            .put(`/registry/business/rank?programId=${selectedProgram._id}`)
            .then((res) => {
                getApplications(selectedProgram);
            })
    }

    function getApplications(program = undefined) {
        if (!program) return;

        setSelectedProgram(program);
        setApplicants(null);

        // if (!(program.isRanked)) {
        //     setApplicants([]);
        //     return;
        // }

        axios
            .get(`/registry/business/getApplications?isEvaluated=${true}&isAccepted=${true}&programId=${program._id}`)
            .then((res) => {
                console.log(res.data);

                const filteredApps = res.data.map((app) => { app = { ...app.businessInfo, ...app }; return app; })

                const extractResults = () => {
                    return filteredApps.map(
                        ({
                            _id,
                            kayas,
                            basicInfo: { name, taxId },
                            finalizeDate,
                        }, i) => [
                                _id,
                                `${i + 1}`,
                                kayas,
                                name,
                                taxId,
                                localeDateStringWithTime(finalizeDate)
                            ]
                    );
                }
                setApplicantsCsv(
                    generateApplicantsCsv(
                        filteredApps,
                        businessApplicationJson,
                        file2csv('business')
                    )
                );
                setApplicants(extractResults());
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const onSubmitSuccess = useCallback(res => {
        addToast({
            type: "success",
            message: "Η Κατάταξη Ολοκληρώθηκε Επιτυχώς",
            duration: 5000,
        });

    }, [])

    const onSubmitFailure = useCallback(err => {
        addToast({
            type: "failure",
            message: err,
            duration: 5000,
        });
    }, []);

    const onClose = () => {
        setModalIsVisible(false);
    }

    const onNoPrograms = () => setApplicants([])

    return (
        <Panel
            headerBar="Προσωρινή Κατάταξη Επιχειρήσεων"
            footerBar={
                selectedProgram && applicants &&
                <ButtonWithLoading onClick={() => setModalIsVisible(true)}>
                    Εφαρμογή
                </ButtonWithLoading>
            }
        >
            <div className={styles.appsContainer}>
                <ProgramDropdown onChange={getApplications} onNoPrograms={onNoPrograms} isCompleted={false} />
                <LoaderWrapper size={"75"} thickness={"7.5px"} loading={!applicants}>
                    <div className={styles.container}>
                        {selectedProgram && applicants &&
                            <div className={styles.grid}>
                                <GridWithFilters
                                    dataInit={applicants} //to keep track with gridjs private vars...
                                    data={applicants}
                                    columns={columns}
                                    search={true}
                                    sort={true}
                                    pagination={{ limit: 13 }}
                                    language={language}
                                    csv={{
                                        ...applicantsCsv,
                                        filename: 'Αιτήσεις Επιχειρήσεων'
                                    }}
                                />
                            </div>
                        }
                        {
                            !selectedProgram && applicants && applicants.length === 0 &&
                            <div className={styles.noApplications}> Δεν βρέθηκαν αιτήσεις </div>
                        }
                    </div>
                </LoaderWrapper>
                {
                    modalIsVisible &&
                    <AcceptRejectModal
                        title='Εφαρμογή Κατάταξης'
                        message='Είστε σίγουροι ότι θέλετε να εφαρμόσετε την 
                        προσωρινή κατάταξη των επιχειρήσεων?'
                        continueButtonText='Εφαρμογή'
                        submit={beginRanking}
                        onSubmitFailure={onSubmitFailure}
                        onSubmitSuccess={onSubmitSuccess}
                        onClose={onClose}
                    />
                }
            </div>
        </Panel>
    );
}

export default BusinessRegistryAllRankings