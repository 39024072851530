import React, { useEffect, useRef, useState } from "react";
import { useClient } from "../ClientWrapper";
import styles from "../../styles/RegistryTable.module.css";
import { _ } from "gridjs-react";
import { IoOpenOutline } from "react-icons/io5";
import { useUser } from "../UserWrapper";
import { useNavigate } from "react-router-dom";
import { useToasts } from "../ToastMessageWrapper";
import GridWithFilters from "../GridWithFilters";
import Manager from "./Manager";
import { MdGppGood, MdGppBad, MdPending } from "react-icons/md";

const STATUS = {
    success: "Αποδεκτή",
    failure: "Απορριπτέα",
    pending: "Εκκρεμεί",
};

const language = {
    search: {
        placeholder: "Αναζήτηση",
    },
    pagination: {
        previous: "Προηγούμενο",
        next: "Επόμενο",
        showing: "Προβάλλονται οι Αιτήσεις",
        to: "-",
        of: "από",
        results: () => "",
    },
    noRecordsFound: "Δεν βρέθηκαν αιτήσεις",
};

function Status({ status }) {
    const Icon =
        status === STATUS.success ? (
            <MdGppGood className={styles.success} />
        ) : status === STATUS.failure ? (
            <MdGppBad className={styles.failure} />
        ) : (
            <MdPending className={styles.pending} />
        );
    return (
        <div className={styles.statusContainer}>
            {Icon}
            {status}
        </div>
    );
}

function RegistryTable({ columns, applicants, applicationGroup, applicantsCsv }) {
    const navigate = useNavigate();
    const [applicantsInit, setApplicantsInit] = useState();
    const axios = useClient();
    const { user } = useUser();
    const addToast = useToasts();
    
    const managerEmailIndex = columns.findIndex(col => col.id === "managerEmail");
    const managerIndex = columns.findIndex(col => col.id === "manager");
    const statusIndex = columns.findIndex(col => col.id === "status");
    const openIndex = columns.findIndex(col => col.id === "open");

    useEffect(() => {
        setApplicantsInit(JSON.parse(JSON.stringify(applicants)));
    }, [applicants]);

    const updateManager = (row, managerName, managerEmail) => {
        row.cells[managerIndex].data = managerName;
        row.cells[managerEmailIndex].data = managerEmail;
        
        const row_ = applicantsInit.find(
            (row_) => row_[0] === row.cells[0].data
        );

        row_[managerIndex] = managerName;
        row_[managerEmailIndex] = managerEmail;
    };

    const onUpdateManagerError = () => {
        addToast({
            type: "failure",
            message:
                "Υπήρξε κάποιο πρόβλημα με την ανάθεση αίτησης. Παρακαλώ ανανεώστε τη σελίδα και ξαναπροσπαθήστε.",
            duration: 5000,
        });
    };

    columns[managerIndex].formatter = (cell, row) => {
        return _(
            <Manager
                client={axios}
                applicationGroup={applicationGroup}
                applicationId={row.cells[0].data}
                loggedUserName={`${user?.firstName} ${user?.lastName}`}
                loggedUserEmail={user?.email}
                userName={cell}
                userEmail={row.cells[managerEmailIndex].data}
                onUpdate={(name, email) => updateManager(row, name, email)}
                onError={onUpdateManagerError}
            ></Manager>
        );
    };

    columns[statusIndex].formatter = (cell, row) => {
        return _(<Status status={cell} />);
    };

    columns[openIndex].formatter = (cell, row) => {
        return _(
            <div className={styles.openButtonContainer}>
                <IoOpenOutline
                    className={styles.openButton}
                    onClick={() => navigate(`../${row.cells[0].data}`)}
                />
            </div>
        );
    };

    if (!applicantsInit) return null;

    return (
        <div className={styles.container}>
            <div className={styles.grid}>
                <GridWithFilters
                    dataInit={applicantsInit} //to keep track with gridjs private vars...
                    data={applicants}
                    columns={columns}
                    search={true}
                    sort={true}
                    pagination={{ limit: 13 }}
                    language={language}
                    csv={applicantsCsv}
                />
            </div>
        </div>
    );
}

export default RegistryTable;
