import React, { useEffect, useState, useRef } from "react";
import { useClient } from "../../components/ClientWrapper";
import { usePageInfo } from "../../components/PageInfoWrapper";
import { useToasts } from "../../components/ToastMessageWrapper";
import { Panel } from "../../components/Panel";
import styles from "../../styles/Admin/Users.module.css";
import { BsPlus } from "react-icons/bs";
import { _ } from "gridjs-react";
import GridWithFilters from "../../components/GridWithFilters";
import { MdSettings } from "react-icons/md";
import Modal from "../../components/Modal";
import { Form } from "../../react-form/src/Form";
import createUserJson from "../../assets/data/admin/createUser.json";
import createCarrierJson from "../../assets/data/admin/createCarrier.json";
import editUserJson from "../../assets/data/admin/editUser.json";
import resetUserPassword from "../../assets/data/resetPassword.json";
import { FaUsersSlash } from "react-icons/fa";
import { IoKey } from "react-icons/io5";
import { Expandable } from "../../components/Expandable";
import { Tabs } from "../../components/Tabs";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import LoaderWrapper from "../../components/LoaderWrapper";
import { useUser } from "../../components/UserWrapper";

const language = {
  noRecordsFound: "Δεν Βρέθηκαν Χρήστες",
};

const UserTable = ({ carrier }) => {
  const axios = useClient();
  const addToast = useToasts();

  const [users, setUsers] = useState(null);
  const { user } = useUser();

  const columns = [
    {
      id: "id",
      name: "id",
      hidden: true,
    },
    {
      id: "firstName",
      name: "Όνομα",
    },
    {
      id: "lastName",
      name: "Επώνυμο",
    },
    {
      id: "email",
      name: "Email",
    },
    {
      id: "role",
      name: "Ρόλος",
    },
    {
      id: "actions",
      name: "Ενέργειες",
      formatter: (cell, row) => {
        return _(
          <ManageUserButton
            onUpdate={updateUser}
            onChangePassword={changePassword}
            onDelete={deleteUser}
            user={{
              _id: row.cells[0].data,
              firstName: row.cells[1].data,
              lastName: row.cells[2].data,
              email: row.cells[3].data,
              role: row.cells[4].data,
              carrier: carrier.title,
            }}
          />
        );
      },
    },
  ];

  const user2array = (user) => {
    return [
      user._id,
      user.firstName,
      user.lastName,
      user.email,
      user.isAdmin ? "Διαχειριστής" : "Χρήστης",
    ];
  };

  const getUsers = () => {
    axios
      .get(`admin/users/?carrier=${carrier.id}`)
      .then((res) => {
        setUsers(
          res.data
            .filter(user_ => user_.email !== user.email)
            .map(user => user2array(user))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUser = (newUser) => {
    newUser.isAdmin = newUser.role === "Διαχειριστής";

    const promise = new Promise((resolve, reject) => {
      axios
        .put("/admin/users", newUser)
        .then((res) => {
          setUsers(
            users.map((user) => {
              if (user[0] === newUser._id) return user2array(newUser);
              else return user;
            })
          );

          addToast({
            type: "success",
            message: "Οι αλλαγές αποθηκεύτηκαν επιτυχώς.",
            duration: 3000,
          });

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  };

  const changePassword = (newUser) => {
    const promise = new Promise((resolve, reject) => {
      axios
        .put("/admin/users/password", newUser)
        .then((res) => {
          addToast({
            type: "success",
            message: "Ο κωδικός πρόσβασης άλλαξε επιτυχώς.",
            duration: 3000,
          });

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  };

  const deleteUser = (_id) => {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete("/admin/users", { data: { _id } })
        .then((res) => {
          setUsers(users.filter((user) => user[0] !== _id));

          addToast({
            type: "success",
            message: "Ο χρήστης διαγράφηκε επιτυχώς",
            duration: 3000,
          });

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  };

  useEffect(() => {
    getUsers();
  }, []);

  const addUser = (user) => setUsers([...users, user2array(user)]);

  return (
    <LoaderWrapper loading={!users} size={"50px"} thickness={"5px"}>
      <GridWithFilters data={users} columns={columns} language={language} />
      <CreateUserButton carrier={carrier} onSuccess={addUser} />
    </LoaderWrapper>
  );
};

const CreateUserButton = ({ carrier, onSuccess }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const formData = { carrier: carrier.title };

  const addToast = useToasts();
  const axios = useClient();

  const formRef = useRef();
  const buttonRef = useRef();

  const createUser = () => {
    buttonRef.current?.setLoading(true);

    const payload = { ...formRef.current.getData() };

    payload.isAdmin = payload.role === "admin";

    delete payload.role;

    axios
      .post(`/admin/users`, payload)
      .then((res) => {
        payload._id = res.data._id;

        onSuccess?.(payload);

        addToast({
          type: "success",
          message: "Ο χρήστης δημιουργήθηκε επιτυχώς.",
          duration: 3000,
        });

        setPopupIsVisible(false);
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message:
            "Υπήρξε κάποιο πρόβλημα με τη δημιουργία του χρήστη. Παρακαλώ προσπαθήστε ξανά.",
          duration: 3000,
        });
      })
      .finally(() => {
        buttonRef.current?.setLoading(false);
      });
  };

  return (
    <div className={styles.createUserWrapper}>
      <button
        className={`defaultButton ${styles.createUser}`}
        onClick={() => setPopupIsVisible(true)}
      >
        <BsPlus size="25px" />
        Δημιουργία Χρήστη
      </button>
      {popupIsVisible && (
        <Modal
          header="Δημιουργία Χρήστη"
          content={
            <div className={`${styles.formContainer} ${styles.createUser}`}>
              <Form ref={formRef} initialValue={formData} description={createUserJson} />
            </div>
          }
          footer={
            <>
              <ButtonWithLoading ref={buttonRef} onClick={createUser}>
                Ολοκλήρωση
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </div>
  );
};

const ManageUserButton = ({ user, onUpdate, onChangePassword, onDelete }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  const userFormRef = useRef();
  const passwordFormRef = useRef();

  const buttonRef = useRef();

  const update = () => {
    userFormRef.current.clearSanityCheck();

    const newUser = userFormRef.current.getData();
    newUser._id = user._id;

    buttonRef.current.setLoading(true);

    onUpdate(newUser)
      .then((res) => setPopupIsVisible(false))
      .catch(() => {
        Form.registerRestrictionCheck({
          tag: 'validateEditFailed_Edit',
          explanation: 'Πιθανόν να δοκιμάσατε να εισάγετε email που υπάρχει ήδη στο σύστημα. Παρακαλώ ξαναπροσπαθήστε.',
          func: () => false
        });

        userFormRef.current.sanityCheckForm();
      })
      .finally(() => buttonRef.current?.setLoading(false));
  };

  const changePassword = () => {
    const newUser = passwordFormRef.current.getData();
    newUser._id = user._id;

    buttonRef.current.setLoading(true);

    onChangePassword(newUser)
      .then((res) => setPopupIsVisible(false))
      .finally(() => buttonRef.current?.setLoading(false));
  };

  const deleteUser = () => {
    buttonRef.current.setLoading(true);

    onDelete(user._id)
      .then((res) => setPopupIsVisible(false))
      .finally(() => buttonRef.current?.setLoading(false));
  };

  const tabs = [
    {
      title: "Επεξεργασία Στοιχείων",
      content: (
        <div className={`${styles.formContainer} ${styles.tabContent}`}>
          <Form ref={userFormRef} description={editUserJson} initialValue={user} />
          <ButtonWithLoading ref={buttonRef} onClick={update}>
            Αποθήκευση
          </ButtonWithLoading>
        </div>
      ),
    },
    {
      title: "Αλλαγή Κωδικού",
      content: (
        <div className={`${styles.formContainer} ${styles.tabContent}`}>
          <IoKey className={styles.cosmetic} />
          <Form ref={passwordFormRef} description={resetUserPassword} />
          <ButtonWithLoading ref={buttonRef} onClick={changePassword}>
            Αλλαγή Κωδικού
          </ButtonWithLoading>
        </div>
      ),
    },
    {
      title: "Διαγραφή",
      content: (
        <div className={`${styles.formContainer} ${styles.tabContent}`}>
          <FaUsersSlash className={styles.cosmetic} />
          <div>
            Αν συνεχίσετε
            <b>
              ο χρήστης {`${user.firstName} ${user.lastName}`} θα διαγραφεί οριστικά
            </b>
            . Οι ενεργές αιτήσεις με ανάθεση στον χρήστη θα είναι ξανά διαθέσιμες για
            διαχείριση.
          </div>
          <ButtonWithLoading
            className={styles.delete}
            ref={buttonRef}
            onClick={deleteUser}
          >
            Οριστική Διαγραφή
          </ButtonWithLoading>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={styles.actions}>
        <button
          className={`${styles.action}`}
          onClick={() => setPopupIsVisible(true)}
        >
          <MdSettings className={styles.actionIcon} />
          Διαχείριση
        </button>
      </div>
      {popupIsVisible && (
        <Modal
          header="Διαχείριση Χρήστη"
          content={
            <div className={styles.tabContainer}>
              <Tabs tabs={tabs} />
            </div>
          }
          footer={" "}
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </>
  );
};

const CreateCarrierButton = ({ onSuccess }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const formRef = useRef();
  const axios = useClient();
  const addToast = useToasts();

  const createCarrier = () => {
    const carrierName = formRef.current.getData();

    return axios
      .post(`/carrier`, carrierName)
      .then(res => {
        addToast({
          type: "success",
          message: "Ο φορέας δημιουργήθηκε επιτυχώς.",
          duration: 3000,
        });

        setPopupIsVisible(false);

        onSuccess?.(res.data);
      })
      .catch((err) => {
        console.log(err.toJSON());
      });
  };

  return (
    <>
      <button className="defaultButton" onClick={() => setPopupIsVisible(true)}>
        <BsPlus size="25px" />
        Δημιουργία Φορέα
      </button>
      {popupIsVisible && (
        <Modal
          header="Δημιουργία Φορέα"
          content={
            <div className={`${styles.formContainer} ${styles.createCarrier}`}>
              <Form description={createCarrierJson} ref={formRef} />
            </div>
          }
          onClose={() => setPopupIsVisible(false)}
          footer={
            <>
              <ButtonWithLoading
                className="defaultButton"
                onClick={createCarrier}
              >
                Ολοκλήρωση
              </ButtonWithLoading>

              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
        />
      )}
    </>
  );
};

const Users_SuperAdmin = () => {
  const [carriers, setCarriers] = useState();
  const axios = useClient();

  useEffect(() => {
    getCarriers();
  }, []);

  const getCarriers = () => {
    axios
      .get(`/carrier`)
      .then((res) => {
        setCarriers(
          res.data.map(carrier => {
            return { id: carrier._id, title: carrier.title };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addCarrier = (carrier) => {
    setCarriers([...carriers, { id: carrier._id, title: carrier.title }]);
  }

  return (
    <Panel headerBar={"Διαχείριση Χρηστών"}>
      <div className={styles.titleContainer}>
        <h2> Όλοι οι φορείς </h2>
        <CreateCarrierButton onSuccess={addCarrier} />
      </div>
      {carriers &&
        carriers.map((carrier, index) => {
          return (
            <Expandable
              title={carrier.title}
              key={index}
              content={
                <UserTable carrier={carrier} />
              }
            />
          );
        })}
    </Panel>
  );
}

const Users_CarrierAdmin = ({ carrierId }) => {
  const [carrier, setCarrier] = useState();
  const axios = useClient();

  useEffect(() => {
    getCarrier();
  }, []);

  const getCarrier = () => {
    axios.get(`/carrier/${carrierId}`)
      .then(res => {
        setCarrier({
          id: res.data._id,
          title: res.data.title
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Panel headerBar={"Διαχείριση Χρηστών"}>
      {
        carrier &&
        <>
          <div className={styles.titleContainer}>
            <h2> Χρήστες {carrier.title} </h2>
          </div>
          <UserTable carrier={carrier} />
        </>
      }
    </Panel>
  );
}

export const Users = () => {
  const { user } = useUser();

  if (!user || !user.isAdmin) return null;

  if (user.carrier)
    return <Users_CarrierAdmin carrierId={user.carrier} />;
  else
    return <Users_SuperAdmin />;
};