import React, { useEffect, useState } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { useClient } from "../../../components/ClientWrapper";
import styles from "../../../styles/Business/BusinessRegistryApplications.module.css";
import RegistryTable from "../../../components/RegistryPageUtils/RegistryTable";
import ProgramDropdown from "../../../components/ProgramDropdown";
import { Panel } from "../../../components/Panel";
import LoaderWrapper from "../../../components/LoaderWrapper";
import { file2csv, generateApplicantsCsv } from "../../../lib/csv";
import businessApplicationJson from "../../../assets/data/business-application.json";

const STATUS = {
  success: "Αποδεκτή",
  failure: "Απορριπτέα",
  pending: "Εκκρεμεί",
};

const columns = [
  {
    id: "id",
    name: "id",
    hidden: true,
  },
  {
    id: "kayas",
    name: "ΚΑΥΑΣ",
  },
  {
    id: "managerEmail",
    name: "managerEmail",
    hidden: true,
  },
  {
    id: "businessname",
    name: "Επωνυμία",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "afm",
    name: "Α.Φ.Μ",
  },
  {
    id: "status",
    name: "Έλεγχος",
    filterOptions: Object.keys(STATUS).map((key) => ({
      label: STATUS[key],
      value: STATUS[key],
    })),
  },
  {
    id: "manager",
    name: "Διαχειριστής",
  },
  {
    id: "open",
    width: 30,
    sort: false,
  },
];

function BusinessRegistryAllApplications() {
  const [applicants, setApplicants] = useState();
  const [applicantsCsv, setApplicantsCsv] = useState({ data: [] });
  const axios = useClient();

  function getApplications(program = undefined) {
    if (!program) return;

    setApplicants(null);
    axios
      .get(`/registry/business/getApplications?isFinalized=${true}&programId=${program._id}`)
      .then((res) => {
        const filteredApps = res.data.map((app) => { app = { ...app.businessInfo, ...app }; return app; })

        const extractResults = () => {
          // put business info destructuring first othewise it overwrites the _id field
          return filteredApps.map(
            ({
              _id,
              kayas,
              basicInfo: { name, taxId },
              representative: { email },
              isEvaluated,
              manager,
              evaluation,
            }) => [
                _id,
                kayas,
                manager?.email,
                name,
                email,
                taxId,
                isEvaluated
                  ? evaluation.accepted
                    ? STATUS.success
                    : STATUS.failure
                  : STATUS.pending,
                manager ? `${manager.firstName} ${manager.lastName}` : undefined,
              ]
          );
        }
        setApplicantsCsv(generateApplicantsCsv(filteredApps, businessApplicationJson, file2csv('business')));
        setApplicants(extractResults());
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onNoPrograms = () => setApplicants([])

  return (
    <Panel headerBar="Έλεγχος Αιτήσεων">
      <div className={styles.appsContainer}>
        <ProgramDropdown onChange={getApplications} onNoPrograms={onNoPrograms} isCompleted={false} />
        <LoaderWrapper size={"75"} thickness={"7.5px"} loading={!applicants}>
          {
            applicants &&
            <RegistryTable
              applicantsCsv={{
                ...applicantsCsv,
                filename: 'Αιτήσεις Επιχειρήσεων'
              }}
              applicationGroup="business"
              columns={columns}
              applicants={applicants}
            />
          }
        </LoaderWrapper>
      </div>
    </Panel>
  );
}

export default BusinessRegistryAllApplications;