import { useState, useRef, useCallback } from "react";
import { AcceptRejectModal } from "../AcceptRejectModal";
import ButtonWithLoading from "../ButtonWithLoading";
import { useClient } from "../ClientWrapper";
import { FileUploadModal } from "../FileUploadModal";
import { useToasts } from "../ToastMessageWrapper";

export const FinalizeButton = ({
  formRef,
  filePaths,
  uploadUrl,
  deleteUrl,
  saveUrl,
  finalizeUrl,
  onSuccess,
}) => {
  const addToast = useToasts();
  const buttonRef = useRef();
  const axios = useClient();
  const application = useRef(null);
  const [warningModalIsVisible, setWarningModalIsVisible] = useState(false);
  const [fileModalIsVisible, setFileModalIsVisible] = useState(false);

  const onFileUploadSuccess = async () => {
    setFileModalIsVisible(false);

    try {
      await axios.put(saveUrl, application.current);
    }
    catch (err) {
      buttonRef.current.setLoading(false);
      return addToast({
        type: "failure",
        message: "Κάτι πήγε στραβά με την αποθήκευση της αίτησης σας. Παρακαλώ ξαναπροσπαθήστε.",
        duration: 5000,
      });
    }

    const formData = formRef.current.getData();
    if (formData.hasOwnProperty('assistanshipPositionsTmp') && !formData.assistanshipPositionsTmp.length) {
      buttonRef.current.setLoading(false);
      return addToast({
        type: "failure",
        message: "Η αίτηση σας δεν έχει οριστικοποιηθεί καθώς δεν έχετε συμπληρώσει θέσεις πρακτικής",
        duration: 5000,
      });
    }

    formRef.current.clearSanityCheck();

    if (formRef.current.sanityCheckForm().length) {
      buttonRef.current.setLoading(false);
      return addToast({
        type: "failure",
        message: "Η αίτηση σας δεν έχει οριστικοποιηθεί καθώς δεν είναι συμπληρωμένα όλα τα υποχρεωτικά πεδία.",
        duration: 5000,
      });
    }

    try {
      await axios.post(finalizeUrl);
    }
    catch (err) {
      buttonRef.current.setLoading(false);
      return addToast({
        type: "failure",
        message: "Υπήρξε κάποιο πρόβλημα με την οριστικοποίηση της αίτησης σας. Παρακαλώ ξαναπροσπαθήστε.",
        duration: 5000,
      });
    }

    deleteHangingFiles();

    buttonRef.current.setLoading(false);
    addToast({
      type: "success",
      message: "Η αίτηση σας αποθηκεύτηκε και οριστικοποιήθηκε επιτυχώς",
      duration: 5000,
    });

    onSuccess?.(application.current);
  }

  const onFileUploadFailure = (msg) => {
    setFileModalIsVisible(false);

    buttonRef.current.setLoading(false);
    addToast({
      type: "failure",
      message: msg,
      duration: 5000,
    });

  }

  const onFileUploadClose = () => {
    setFileModalIsVisible(false);
  }

  const getApplicationIfValid = () => {
    formRef.current.clearSanityCheck();

    if (!formRef.current.sanityCheckForm(true).length)
      return formRef.current.getData();

    return null;
  }

  const deleteHangingFiles = async () => {
    try { await axios.delete(deleteUrl); }
    catch (err) { }
  }

  const onClick = () => {
    const application_ = getApplicationIfValid();

    if (!application_)
      return addToast({
        type: "failure",
        message: "Η αίτηση σας δεν έχει αποθηκευτεί καθώς υπάρχουν λανθασμένα πεδία",
        duration: 5000,
      });

    application.current = application_;
    buttonRef.current.setLoading(true);
    setFileModalIsVisible(true);
  }

  return (
    <>
      <ButtonWithLoading
        onClick={() => setWarningModalIsVisible(true)}
        ref={buttonRef}
      >
        Αποθήκευση και Οριστικοποίηση
      </ButtonWithLoading>
      {
        fileModalIsVisible &&
        <FileUploadModal
          application={application.current}
          filePaths={filePaths}
          uploadUrl={uploadUrl}
          onClose={onFileUploadClose}
          onUploadSuccess={onFileUploadSuccess}
          onUploadFailure={onFileUploadFailure}
        />
      }
      {
        warningModalIsVisible &&
        <AcceptRejectModal
          title='Οριστικοποίηση Αίτησης'
          message={
            <>
              Είστε σίγουροι ότι θέλετε να οριστικοποιήσετε της αίτησης σας;
              <br /> <br />
              Μετά την οριστικοποίηση η αίτηση σας γνωστοποιείται στον φορέα, ωστόσο ο έλεγχος των αιτήσεων ξεκινάει μετά τη λήξη της προθεσμίας του προγράμματος.
              <br /> <br />
              Παρακαλούμε σημειώστε τον Κωδικό Αριθμό Υποβολής Αίτησης Συμμετοχής (ΚΑΥΑΣ) που θα σας δοθεί γιατί θα τον χρειαστείτε για κάθε μελλοντική αναφορά στην αίτησή σας.
              <br /><br />
              Πριν την ένταξή σας στο πρόγραμμα κατάρτισης θα πραγματοποιηθεί έλεγχος των δικαιολογητικών που έχετε αναρτήσει, από τον πάροχο κατάρτισης και σε περίπτωση αρνητικού ελέγχου, η αίτησή σας θα απορριφθεί.
            </>
          }
          continueButtonText='Ολοκλήρωση'
          submit={onClick}
          onSubmitFailure={() => {
            setWarningModalIsVisible(false);
          }}
          onSubmitSuccess={() => {
            setWarningModalIsVisible(false);
          }}
          onClose={() => { setWarningModalIsVisible(false) }}
        />
      }
    </>
  );
}