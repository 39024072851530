import { useEffect } from 'react';
import { AiFillLock } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { usePageInfo } from '../../components/PageInfoWrapper';
import styles from '../../styles/Error/Error.module.css';

export const Error403 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.error}>
        <AiFillLock className={styles.icon} />
        <h1> Σφάλμα 403 </h1>
      </div>
      <div>
        <h2> Δεν έχετε δικαίωμα πρόσβασης σε αυτή τη σελίδα... </h2>
        <p> Παρακαλούμε επικοινωνήστε με το διαχειριστή ή δοκιμάστε να επαναφορτώσετε την ιστοσελίδα </p>
      </div>
      <button className="defaultButton">
        <Link to='/'> Πίσω στην αρχική </Link>
      </button>
    </div>
  );
}