import React from "react";
import styles from "../styles/Announcement.module.css";
import File from "../components/File"

export default function Announcement({ date, title, desc, children, fname, onFileClick }) {
  return (
    <div className={styles.announcement}>
      <div className={styles.date}> {date} </div>
      <div className={styles.title}> {title} </div>
      <div className={styles.desc}> {desc} </div>

      {
        fname && <File name = {fname} onClick = {onFileClick} />
      }

      {children}
    </div>
  );
}
