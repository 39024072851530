import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Navigate, Route, Routes } from "react-router-dom";
import BusinessRegistryAllArchived from "./BusinessRegistryAllArchived";
import BusinessRegistryArchivedDetails from "./BusinessRegistryArchivedDetails";

function BusinessRegistryArchived() {
    return (
        <Routes>
            <Route path="/all" element={<BusinessRegistryAllArchived />} />
            <Route path="/:appId" element={<BusinessRegistryArchivedDetails />} />
            <Route path="/" element={<Navigate to="all" />} />
            <Route path="/*" element={<Navigate to="/error404" />} />
        </Routes>
    );
}

export default BusinessRegistryArchived;
