import { BsCheckCircleFill } from 'react-icons/bs';

import styles from '../styles/SuccessMessage.module.css';

export const SuccessMessage = ({ message }) => {
    return (
        <div className={styles.container}>
            <BsCheckCircleFill className={styles.icon} />
            <span className={styles.message}>{message}</span>
        </div>
    );
};
