import React, { Component } from "react";

// ---------------------------------------------------------------------------------------

import { Form } from "../Form";

// ---------------------------------------------------------------------------------------

import '../styles/array-input.css';

// ---------------------------------------------------------------------------------------

export class ArrayInput extends Component {

    constructor(props) {

        super(props);

        this.inputRefs = [];
        this.currKey = 0;

        this.initialValue = props.initialValue || props.description.value;
        this.initialCount = this.initialValue?.length || 0;
        this.setDataEvent = null;
        this.pendingSetData = React.createRef(0);

        /* separate description per inner form */
        const items = Array(this.initialCount).fill().map(item => this.newItem());

        this.state = { items };

        this.pushForm = this.pushForm.bind(this);
        this.removeForm = this.removeForm.bind(this);
    }

    newItem() {
        const description = this.props.description;

        const key = this.currKey++;
        return { description, key };
    }

    isHidden() {
        return this.state.isHidden;
    }

    showQuestion() {
        this.setState({ isHidden: false });
    }

    hideQuestion() {
        this.setState({ isHidden: true });
    }

    setData(data, onFinish) {
        this.inputRefs = [];

        this.setState(
            { items: Array(data.length).fill().map(item => this.newItem()) },
            () => {
                // Unfortunately could not find a good solution on this.
                // The problem is that even after using componentDidUpdate
                // the parent form had the array-input's ref === null when
                // onChange is called on the inputs
                // This was noticable only when using Form.setData() to set
                // the value of an array-input that had dependent children
                // (such as region -> regionUnit -> municipality) that were
                // handled inside onChange
                setTimeout(() => {
                    this.pendingSetData.current = this.inputRefs.length;
                    const decrementPendingSetData = (i) => {
                        if(!--this.pendingSetData.current) {
                            onFinish && onFinish();
                        }
                    };

                    if (this.inputRefs.length === 0)
                        onFinish && onFinish();

                    for (let i = 0; i < this.inputRefs.length; ++i) {
                        if (this.inputRefs[i] === null) {
                            decrementPendingSetData(i);
                        }
                        else { //TODO: change that!!!
                            this.inputRefs[i].setData(data[i], decrementPendingSetData);
                        }
                    }
                }, 0);
            }
        );
    }

    getQuestion(i) {
        return this.inputRefs[i] || false;
    }

    getData() {
        const data = [];
        for (const ref of this.inputRefs) {
            if (ref === null) continue; //TODO: change that!!!
            data.push(ref.getData());
        }
        return data;
    }

    clearSanityCheck() {
        for (const ref of this.inputRefs) {
            if (ref === null) continue; //TODO: change that!!!
            ref.clearSanityCheck();
        }
    }

    sanityCheckForm() {
        const s = [];
        for (const ref of this.inputRefs) {
            if (ref === null) continue; //TODO: change that!!!
            s.push(...ref.sanityCheckForm());
        }
        return s;
    }

    clear() {
        for (const ref of this.inputRefs) {
            if (ref === null) continue; //TODO: change that!!!
            ref.clear();
        }
    }

    getLabels() {
        let s = {};
        for (const ref of this.inputRefs) {
            if (ref === null) continue; //TODO: change that!!!
            s = Object.assign(s, ref.getLabels());
        }
        return s;
    }

    pushForm() {
        this.setState({ items: [...this.state.items, this.newItem()] });
    }

    removeForm(item) {
        const index = this.state.items.indexOf(item);

        this.inputRefs.splice(index, 1);

        const items = this.state.items.filter(item_ => item_ !== item);

        this.setState({ items });
    }

    createForm(index) {
        const item = this.state.items[index];

        const getRef = ref => { this.inputRefs[index] = ref; }
        const initialValue = item.key <= this.initialCount - 1 ?
            this.initialValue?.[index] :
            null
            ;

        return (
            <Form
                readOnly={this.props.readOnly}
                onChange={this.props.onChange}
                description={{
                    ...item.description,
                    name: `${this.props.description.name}/${index}`
                }}
                initialValue={initialValue}
                ref={getRef}
            />
        );
    }

    createPanels() {
        return this.state.items.map((item, index) => {
            const { key } = item;

            return (
                <div
                    key={key}
                    className="array-input-element-container"
                >
                    {
                        !this.props.readOnly &&
                        <div
                            className="array-input-x"
                            
                        > 
                            <button 
                                className="remove-array-input-button"
                                onClick={() => this.removeForm(item)}>
                                ✖
                            </button>
                        </div>
                    }
                    {
                        this.createForm(index)
                    }
                </div>
            );
        });
    }

    setIsReadOnly(isReadOnly) {
        for (const ref of this.inputRefs) {
            if (ref === null) continue; //TODO: change that!!!
            ref.setIsReadOnly(isReadOnly);
        }
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        const canAdd = !this.props.readOnly &&
            (
                !this.props.description.maxForms ||
                this.state.items.length < this.props.description.maxForms
            );

        return <>
            {this.props.label}
            <div className="array-input">
                {
                    this.createPanels()
                }
                {
                    canAdd &&
                    <button
                        className="push-form-button btn btn-success"
                        onClick={this.pushForm}
                    >
                        {this.props.description.insertPrompt || '+'}
                    </button>
                }
            </div>
        </>
    }

}