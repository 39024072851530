import Modal from "./Modal";
import { DispatchFileIcon } from "../react-form/src/form-inputs/FileInput";
import Axios from "axios";
import { useClient } from "./ClientWrapper";
import { useEffect, useRef, useState } from "react";
import styles from "../styles/FileUploadModal.module.css";
import {BsCheckCircleFill} from "react-icons/bs";

const FileWithProgress = ({file, progress}) => {
  return (
    <div className = {styles.file}>
      {DispatchFileIcon(file.name)}
      {file.name}
      {
        progress !== 100 &&
        <div className = {styles.progressContainer}>
          <div className = {styles.progress}>
            {progress}%
          </div>
          <div className = {styles.loader}></div>
        </div>
      }
      {
        progress === 100 &&
        <BsCheckCircleFill className={styles.complete}/>
      }
    </div>
  );
}

export const useApplicationFileUploader = (
  application,
  filePaths,
  uploadUrl,
  onUploadSuccess,
  onUploadFailure
) => {
  const [progress, setProgress] = useState([]);
  const [files, setFiles] = useState(null);
  const sources = useRef([]);
  const axios = useClient();
  const fileKeys = useRef([]);
  const uploadsComplete = useRef(0);

  const initUpload = () => {
    const files_ = [];
    const keys = [];

    for (let key1 in filePaths) {
      for (let key2 of filePaths[key1]) {
        const file = application[key1][key2];

        if (file && !file._id) {
          files_.push(file);
          keys.push([key1, key2]);
        }
      }
    }

    fileKeys.current = keys;
    sources.current = [];
    uploadsComplete.current = 0;

    setProgress([]);
    setFiles(files_);
  }

  useEffect(() => {
    if (files)
      upload();
  }, [files])

  const modifyApplication = (i, fileResponse) => {
    const [key1, key2] = fileKeys.current[i];

    application[key1][key2] = fileResponse;
  }

  const validateUploadSpaceRestriction = () => {
    const totalSize = files.reduce((sum, f) => sum + f.size, 0);

    return totalSize < 10000000;
  }

  const upload_ = (i) => {
    const formData = new FormData();
    formData.append('file', files[i]);

    return axios.post(
      uploadUrl,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: sources.current[i].token,
        onUploadProgress: progressEvent => {
          const update = parseInt(
            Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          );

          setProgress(prevProgress => {
            const updatedProgress = [...prevProgress];
            updatedProgress[i] = update;
            return updatedProgress;
          });
        }
      }
    );
  }

  const upload = () => {
    if (!files.length){
      onUploadSuccess?.();
      return;
    }

    const isValidFileSize = validateUploadSpaceRestriction();

    if (!isValidFileSize){
      return onUploadFailure?.(
        "Το συνολικό μέγεθος των αρχείων της αίτησης σας θα πρέπει να είναι μικρότερο από 10 mega bytes"
      );
    }

    for (let i = 0; i < files.length; ++i){
      sources.current[i] = Axios.CancelToken.source();
      
      upload_(i)
        .then(res => {
          sources.current[i] = null;

          modifyApplication(i, res.data);

          uploadsComplete.current++;

          if (uploadsComplete.current === files.length){
            onUploadSuccess?.();
          }
        })
        .catch(err => {
          // if the request did not get voluntarily canceled
          // by the user closing the modal

          if (sources.current[i] !== null){
            sources.current[i] = null;
            cancel();
            onUploadFailure?.(
              "Κάτι πήγε στραβά με το ανέβασμα των αρχείων σας. Παρακαλώ ξαναπροσπαθήστε"
            );
          }

        })
    }
  }

  const cancel = () => {
    for (let i = 0; i < sources.current.length; ++i){
      const source = sources.current[i]; 

      if (source){
        sources.current[i] = null;
        source.cancel();
      }
    }
  }

  return { upload: initUpload, cancel, progress, files };
}

export const FileUploadModal = ({
  application,
  filePaths,
  uploadUrl,
  onClose,
  onUploadSuccess,
  onUploadFailure
}) => {

  const {upload, cancel, progress, files} = useApplicationFileUploader(
    application,
    filePaths,
    uploadUrl,
    onUploadSuccess,
    onUploadFailure
  );

  useEffect(() => {
    upload();

    return () => { cancel(); }
  }, []);

  if (!files) return null;

  return (
    <Modal
      header = "Ανέβασμα αρχείων"
      content = {
        <div className={styles.content}>
          Τα αρχεία σας αποθηκεύονται στο σύστημα, παρακαλώ περιμένετε...
          <div className = {styles.files}>
            {
              files.map( (file,i) => 
                <FileWithProgress key = {i} file = {file} progress = {progress[i]}/>
              )
            }
          </div>
        </div>
      }
      footer = ""
      onClose = {onClose}
    />
  );
}