import React from "react";
import { BsClipboardData, BsFolder2 } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import BusinessRegistryApplications from "./BusinessRegistryApplications";
import { SideNavPage } from "../../../components/SideNavPage";
import { MdPreview } from "react-icons/md";
import BusinessRegistryRankings from "./BusinessRegistryRankings";
import BusinessRegistryArchived from "./BusinessRegistryArchived";


const options = [
  {
    Icon: HiPencilAlt,
    text: 'Aξιολόγηση Αιτήσεων',
    path: 'applications',
    innerRouting: true,
    element: <BusinessRegistryApplications />
  },
  {
    Icon: BsClipboardData,
    text: 'Κατάταξη',
    path: 'ranking',
    innerRouting: true,
    element: <BusinessRegistryRankings />
  },
  {
    Icon: BsFolder2,
    text: 'Ιστορικό',
    path: 'archived',
    innerRouting: true,
    element: <BusinessRegistryArchived />
  }
];

function BusinessRegistryPage() {
  return (
    <SideNavPage prefix='business' options={options} />
  );
}

export default BusinessRegistryPage;
