import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useClient } from "../../../components/ClientWrapper";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Trainee/TraineeRegistryEvaluation.module.css";
import { Form } from "../../../react-form/src/Form";
import { useUser } from "../../../components/UserWrapper";
import evaluationFormDesc_ from "../../../assets/data/EvaluationForm.json";
import { IoIosArrowBack } from "react-icons/io";
import { BusinessApplicationForm } from "../BusinessApplication/BusinessApplicationForm";

function BusinessRegistryAppInfo() {
    const { appId } = useParams();
    const axios = useClient();
    const { user } = useUser();

    const [app, setApplication] = useState(null);

    const [readOnly, setReadOnly] = useState(true);

    const [evaluationFormDesc,] = useState(JSON.parse(JSON.stringify(evaluationFormDesc_)));
    const applicationFormRef = useRef();

    const makeFormDisabled = (formDescription) => {
        if (!formDescription.questions) return (formDescription.isDisabled = true);

        for (let q of formDescription.questions) makeFormDisabled(q);
    };

    const getApplication = () => {
        axios
            .get(`/business/application/${appId}`)
            .then((res) => {
                const manager = res.data.application?.manager;

                if (manager && manager.email === user.email) {
                    setReadOnly(false);
                } else {
                    setReadOnly(true);
                    makeFormDisabled(evaluationFormDesc);
                }

                res.data.application.programInfo = { program: res.data.application?.program._id };

                if (res.data.application)
                    res.data.application = {
                        ...res.data.application.businessInfo,
                        ...res.data.application
                    };

                setApplication(res.data.application || {});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getApplication();
    }, []);

    return (
        <Panel headerBar="Προβολή Αίτησης">
            {
                app &&
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.backButton}>
                            <Link className="link" to="../all">
                                <IoIosArrowBack></IoIosArrowBack>
                                Πίσω
                            </Link>
                            <h2> Αίτηση της επιχείρησης {app.businessInfo.basicInfo.name} </h2>
                        </div>
                        <BusinessApplicationForm
                            application={app}
                            formRef={applicationFormRef}
                            readOnly={true}
                        />
                    </div>
                </div>
            }
        </Panel>
    );
}

export default BusinessRegistryAppInfo