const AutoRetrievedFields = {};

AutoRetrievedFields['blackboard'] = { };

AutoRetrievedFields['get_date'] = () => {
    return new Date().toISOString().slice(0, 19).replace('T', ' ');
};

AutoRetrievedFields['get_duration'] = () => {
    return AutoRetrievedFields.blackboard['duration'] || 0;
};

AutoRetrievedFields['get_phoneNumber'] = () => {
    return AutoRetrievedFields.blackboard['phoneNumber'] || 6912345678;
};

AutoRetrievedFields.set = (tag, value) => {
    AutoRetrievedFields.blackboard[tag] = value;
};

module.exports = AutoRetrievedFields;