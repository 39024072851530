import Portal from "./pages/Portal";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Auth/LoginPage";
import "./styles/App.css";
import "./assets/fonts/Roboto/Roboto.css";
import BusinessPage from "./pages/Business/BusinessApplication/BusinessPage";
import BusinessRegistryPage from "./pages/Business/BusinessRegistry/BusinessRegistryPage";
import TraineeRegistryPage from "./pages/Trainee/TraineeRegistry/TraineeRegistryPage";
import TraineePage from "./pages/Trainee/TraineeApplication/TraineePage";
import RegisterPage from "./pages/Auth/RegisterPage";
import MainPage from "./pages/MainPage";
import "./styles/Form.css";
import { registerFormRestrictions } from "./lib/Validation";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";
import { ResetPassword } from "./pages/Auth/ResetPassword";
import AdminPage from "./pages/Admin/AdminPage";
import { PERMISSIONS, ProtectedRoute } from "./pages/Auth/ProtectedRoute";
import { Error404 } from "./pages/Error/Error404";
import { DateInput, DateTransformations } from "./react-form/src/form-inputs/DateInput";
import { DownloadFilePage } from "./pages/DownloadFilePage";
import { VerifyEmail } from "./pages/Auth/VerifyEmail";

registerFormRestrictions();
DateInput.setDateTransformations(DateTransformations.ISO);

function App() {
  return (
    <div className="App">
      <MainPage>
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {ProtectedRoute({ path: "/trainee/*", element: <TraineePage /> })}

          {
            ProtectedRoute({
              path: "/registry/trainee/*",
              element: <TraineeRegistryPage />,
              permissions: PERMISSIONS.Employee
            })
          }

          {
            ProtectedRoute({
              path: "/admin/*",
              element: <AdminPage />,
              permissions: PERMISSIONS.Admin
            })
          }

          {ProtectedRoute({ path: "/business/*", element: <BusinessPage /> })}

          {
            ProtectedRoute({
              path: "/registry/business/*",
              element: <BusinessRegistryPage />,
              permissions: PERMISSIONS.Employee
            })
          }

          {
            ProtectedRoute({
              path: `/business/application/file/download/:appId/:fileId`,
              element: <DownloadFilePage />,
              permissions: PERMISSIONS.Employee
            })
          }

          {
            ProtectedRoute({
              path: `/trainee/application/file/download/:appId/:fileId`,
              element: <DownloadFilePage />,
              permissions: PERMISSIONS.Employee
            })
          }

          {
            ProtectedRoute({
              path: "/verify-email",
              element: <VerifyEmail />,
            })
          }

          <Route path="/" element={<Portal />} />
          <Route path="/*" element={<Error404 />} />
        </Routes>
      </MainPage>
    </div>
  );
}

export default App;
