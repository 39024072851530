import React from "react";
import styles from "../styles/LoaderWrapper.module.css";

class LoaderWrapper extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: !!this.props.loading,
    };
  }

  setLoading = (loading) => {
    this.setState( {loading: !!loading} )
  };

  static getDerivedStateFromProps(props, state) {
    return { ...state, loading: !!props.loading }
  }

  computeStyle = () => {
    const style = {};

    if (this.props.size !== undefined){
      let num = parseFloat(this.props.size);

      if (!Number.isNaN(num)){
        style.width = style.height = num + 'px';
        style.borderWidth = num / 5;
      }else
        style.width = style.height = this.props.size;
    }
    
    if (this.props.thickness !== undefined){
      style.borderWidth = this.props.thickness;
    }

    return style;
  }

  render(){
    if (this.state.loading){
      return (
        <div className={styles.container}>
          <div className={styles.loader} style={this.computeStyle()}></div>
        </div>
      );
    }
    
    return this.props.children;
  }
}

export default LoaderWrapper;
