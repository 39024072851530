import ErrorPrompt from './ErrorPrompt';
import { Input } from './Input';
import Slider from 'rc-slider';

// ---------------------------------------------------------------------------------------

export class SliderInput extends Input {
    wrapperStyle = { width: this.props.description.width || '100%' };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state.value = this.state.value || this.props.min || 0;
    }

    onChange(value, onFinish) {
        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    readOnlyRender() {
        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap'>
                    <Slider
                        min={this.props.description.min || 0}
                        max={this.props.description.max || 100}
                        marks={
                            this.props.description.marks === true
                                ? this.computeAutoMarks()
                                : this.props.description.marks
                        }
                        step={this.props.description.step || 1}
                        value={this.state.value}
                        onChange={this.onChange}
                        disabled={true}
                    />
                </div>
            </>
        );
    }

    computeAutoMarks() {
        const min = this.props.description.min || 0;
        const max = this.props.description.max || 100;

        const marks = {};

        for (let i = min; i <= max - min + 1; ++i) marks[i] = i;

        return marks;
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div style={this.wrapperStyle}>
                    <Slider
                        min={this.props.description.min || 0}
                        max={this.props.description.max || 100}
                        marks={
                            this.props.description.marks === true
                                ? this.computeAutoMarks()
                                : this.props.description.marks
                        }
                        step={this.props.description.step || 1}
                        value={this.state.value}
                        onChange={this.onChange}
                        disabled={this.props.description.isDisabled}
                    />
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}

// ---------------------------------------------------------------------------------------
