import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom'

import { Grid, _ } from "gridjs-react";
import Select from "react-select";
import styles from "../styles/GridWithFilters.module.css";

import { FaSort } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { DownloadCsvButton } from "./DownloadCsvButton";
import { PluginPosition } from "gridjs";

const selectionCustomStyles = {
  control: (provided, state) => ({
    backgroundColor: "#ffffff",
    display: "flex",
    width: "100%",
    margin: 0,
    border: 0,
    borderTop: "1px solid #e5e7eb",
    boxSizing: "border-box",
    outline: state.isFocused ? "2px solid #0D6EFD" : 0,
    outlineOffset: "-2px",
    cursor: "pointer",
    minHeight: "50px",
  }),
  menu: (provided) => ({
    ...provided,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#757575",
    fontWeight: "500",
    fontSize: "1rem",
    paddingLeft: "3rem",
    boxSizing: "border-box",
    position: "absolute",
    margin: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "1rem",
    fontWeight: "normal",
    paddingLeft: "3rem",
    margin: "0",
    boxSizing: "border-box",
    position: "absolute",
    maxWidth: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    justifyContent: "flex-start",
    boxSizing: "border-box",
    position: "relative",
    padding: 0,
  }),
  input: (provided) => ({
    ...provided,
    boxSizing: "border-box",
    paddingLeft: "3rem",
    position: "absolute",
  }),
  option: (provided) => ({
    ...provided,
    justifyContent: "flex-start",
    display: "flex",
  }),
}

const tableCustomStyles = {
  header: styles.header,
  thead: styles["container-title"],
  td: styles.td,
  th: styles.th,
  tr: styles.tr,
  sort: styles.sortButton,
  table: styles.table,

  footer: styles.footer,
  pagination: styles.pagination,
  paginationButtonNext: styles.next,
  paginationButtonPrev: styles.prev,
  paginationButton: styles.paginationButton,
  paginationButtonCurrent: styles.paginationButtonCurrent,
  paginationSummary: styles.paginationSummary,

  search: styles.search,

  notfound: styles.notFound,
}

class HeaderFieldSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilterVisible: !!this.props.hasFilters
    };

    this.selectRef = React.createRef();

    this.customStyles = selectionCustomStyles;
  }

  handleOnClick = (e) => e.stopPropagation();

  setIsFilterVisible = (v) => this.setState({ isFilterVisible: !!v });

  focus = () => {
    this.selectRef.current?.focus();
  };

  componentDidMount() {
    if (this.state.isFilterVisible && this.props.isFocused)
      this.focus();
  }

  render() {
    return (
      <div className={styles.headerFieldContainer}>
        <div className={styles.headerField}>
          <div> {this.props.name} </div>
          <FaSort size="24px" color="#AFAFB0" />
        </div>
        {this.state.isFilterVisible && (
          <div className={styles.filterSelectionContainer} onClick={this.handleOnClick}>
            <Select
              styles={this.customStyles}
              options={this.props.filterOptions}
              isClearable
              isSearchable={false}
              ref={this.selectRef}
              onChange={this.props.onChangeFilter}
              value={this.props.filterInit}
              placeholder={this.props.placeholder !== undefined ? this.props.placeholder : "Αναζήτηση..."}
            />
            <BsSearch className={styles.filterInputSearch} />
          </div>
        )}
      </div>
    );
  }

}

class HeaderFieldInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: !!this.props.hasFilters
    };

    this.inputRef = React.createRef();
  }

  handleOnClick = (e) => e.stopPropagation();

  setIsFilterVisible = (v) => this.setState({ isFilterVisible: !!v });

  onChange = (e) => {
    if (this.props.onChangeFilter)
      this.props.onChangeFilter(e.target.value, this.inputRef.current.selectionStart);
  };

  focus = (focusPoint) => {
    const input = this.inputRef.current;
    input.focus();
    input.setSelectionRange(focusPoint, focusPoint);
  };

  componentDidMount() {
    if (this.state.isFilterVisible && this.props.isFocused)
      this.focus(this.props.focusPoint);
  }

  render() {
    return (
      <div className={styles.headerFieldContainer}>
        <div className={styles.headerField}>
          <div> {this.props.name} </div>
          <FaSort size="24px" color="#AFAFB0" />
        </div>
        {this.state.isFilterVisible && (
          <div onClick={this.handleOnClick} className={styles.filterInputContainer}>
            <input
              ref={this.inputRef}
              className={styles.filterInput}
              placeholder="Αναζήτηση..."
              onChange={this.onChange}
              value={this.props.filterInit}
            />
            <BsSearch className={styles.filterInputSearch} />
          </div>
        )}
      </div>
    )
  }

}

export default function GridWithFilters(props) {
  const hasFilters = useRef(!!props.filtersAreVisible);
  const filterRefs = useRef([]);
  const [filters, setFilters] = useState([]);
  const [focusedFilter, setFocusedFilter] = useState(null);

  const dataInit = useRef(props.dataInit || JSON.parse(JSON.stringify(props.data)));
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);

    if (!props.dataInit)
      dataInit.current = JSON.parse(JSON.stringify(props.data))
    else
      dataInit.current = props.dataInit;

    filterRefs.current = [];
    hasFilters.current = false;
    setFilters([]);
    setFocusedFilter(null);
  }, [props.data, props.dataInit])

  useEffect(() => {
    const containers = document.getElementsByClassName('gridjs-search');

    for (let container of containers) {
      if (container)
        ReactDOM.render(
          <BsSearch className={styles.searchIcon} />,
          container
        );
    }
  });

  const applyFilters = () => {
    return dataInit.current?.filter(row =>
      filters.every(
        (filter, i) => {
          return (
            filter === undefined ||
            filter === null ||
            filter === '' ||
            typeof filter === "object" && filter.value === row[i] ||
            typeof filter === "string" && row[i]?.includes(filter)
          );
        }
      )
    )
  }

  useEffect(() => {
    setData(applyFilters());
  }, [filters]);

  const onChangeFilter = (i, value, focusPoint) => {
    setFocusedFilter({ filter: i, cursorPos: focusPoint });

    setFilters(filters => {
      const newFilters = [...filters];

      newFilters[i] = value;

      return newFilters;
    });
  };

  const toggleFilters = () => {
    setHasFilters(!hasFilters.current);
    setFocusedFilter(null);
  };

  const setHasFilters = (v) => {
    hasFilters.current = v;

    for (let filterRef of filterRefs.current)
      filterRef?.setIsFilterVisible?.(v);
  }

  const createHeaderColumn = ({ id, name, type, i, filterOptions, formatter, placeholder, ...rest }) => {
    const Header = filterOptions ? HeaderFieldSelection : HeaderFieldInput;

    const jsx = (
      <Header
        ref={ref => filterRefs.current[i] = ref}
        name={name}
        hasFilters={hasFilters.current}
        filterOptions={filterOptions}
        filterInit={filters[i]}
        onChangeFilter={
          (value, focusPoint) => onChangeFilter(i, value, focusPoint)
        }
        isFocused={focusedFilter?.filter === i}
        focusPoint={focusedFilter?.cursorPos}
        placeholder={placeholder}
      />
    );

    return { id, name: _(jsx), formatter, ...rest };
  };

  const createLastColumn = (props) => {
    return {
      id: '__lastRow__',
      ...props,
      name: _(
        <div
          className={styles.filterButtonContainer}
          onClick={toggleFilters}
        >
          <FaFilter className={styles.filterButton}></FaFilter>
        </div>
      ),
    }
  };

  let plugins = props.plugins || [];

  if (props.csv)
    plugins.push({
      id: 'DownloadAsCsv',
      component: () => _(<DownloadCsvButton {...props.csv} />),
      position: PluginPosition.Header,
      order: 1
    });

  return (
    <Grid
      {...props}
      className={
        props.className ?
          { ...tableCustomStyles, ...props.className }
          :
          tableCustomStyles
      }
      data={data}
      columns={
        props.columns.map(
          (args, i) =>
            i !== props.columns.length - 1 ?
              createHeaderColumn({ ...args, i }) :
              createLastColumn({ ...args })
        )
      }
      plugins={plugins}
    />
  );
}