import { useRef, useState } from "react";
import ButtonWithLoading from "./ButtonWithLoading";
import Modal from "./Modal";
import styles from "../styles/AcceptRejectModal.module.css";

export const AcceptRejectModal = ({
  title,
  message,
  submit: submit_,
  onSubmitSuccess,
  onSubmitFailure,
  onClose,
  continueButtonText = 'Ολοκλήρωση',
  cancelButtonText = 'Ακύρωση',
}) => {

  const [modalIsVisible, setModalIsVisible] = useState(true);
  const continueButtonRef = useRef(null);
  const loadingRef = useRef(false);

  const submit = async () => {
    loadingRef.current = true;
    continueButtonRef?.current?.setLoading(true);

    try {
      const res = await submit_?.();
      onSubmitSuccess?.(res);
    }
    catch (err) {
      onSubmitFailure?.(err);
    }
    finally {
      loadingRef.current = false;
      continueButtonRef?.current?.setLoading(false);
      hide();
    }
  }

  const hide = () => {
    if (continueButtonRef.current && !loadingRef.current){
      setModalIsVisible(false);
      onClose?.();
    }
  }

  if (!modalIsVisible) return null;

  return (
    <Modal
      header={title}
      content={
        <div className={styles.modalContent}> {message} </div>
      }
      footer={
        <>
          <ButtonWithLoading
            onClick={submit}
            ref={continueButtonRef}
          >
            {continueButtonText}
          </ButtonWithLoading>

          <ButtonWithLoading className="cancelButton" onClick={hide}>
            {cancelButtonText}
          </ButtonWithLoading>
        </>
      }
      onClose={hide}
    />
  );
}