import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

export class TimeInput extends Input {

    constructor(props) {

        super(props);

        this.state = {
            value: props.initialValue || props.description.value || '',
            problem: null
        };

        this.onChange = this.onChange.bind(this);

    }

    onChange(e, onFinish) {
        let value = null;
        if(e.target.value !== '') value = e.target.value;
        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }
    
    readOnlyRender() {
        return <>
            {this.props.label}
            <div className="readOnly-value-wrap">{this.state.value || '-'}</div>
        </>
    }

    render() {

        if(this.state.isHidden) {
            return null;
        }

        if(this.props.readOnly) {
            return this.readOnlyRender();
        }
        
        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <div>
                <input title={this.props.description.title || this.props.description.name} className={`form-item-input`} disabled={this.props.description.isDisabled} value={this.state.value} name={this.props.description.name} onChange={this.onChange} type="time"/>
            </div>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
        
    }

};

// ---------------------------------------------------------------------------------------