import { useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { Panel } from "../../components/Panel";
import styles from "../../styles/Admin/Programs.module.css";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useClient } from "../../components/ClientWrapper";
import { useToasts } from "../../components/ToastMessageWrapper";
import { ProgramForm } from "./ProgramForm";

export const CreateProgram = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const addToast = useToasts();
  const axios = useClient();

  const [formIsLoading, setFormIsLoading] = useState(true);

  const create = (e) => {
    formRef.current.clearSanityCheck();

    const errors = formRef.current.sanityCheckForm();

    if (errors && errors.length) {
      addToast({
        type: "failure",
        message: "Η Δημιουργία Υλοποίησης Προγράμματος απέτυχε",
        duration: 3000,
      });
      return false;
    }

    const program = formRef.current.getData();

    return axios
      .post(`/program`, program)
      .then((res) => {
        addToast({
          type: "success",
          message: "Η υλοποίηση του προγράμματος δημιουργήθηκε επιτυχώς.",
          duration: 3000,
        });
        navigate("../");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Panel
      headerBar={"Διαχείριση Προγράμματος"}
      footerBar={
        !formIsLoading &&
        <ButtonWithLoading onClick={create}>
          Δημιουργία
        </ButtonWithLoading>
      }
    >
      {
        <>
          <div className={`${styles.titleContainer} ${styles.createProgram}`}>
            <Link className="link" to="..">
              <IoIosArrowBack className=""></IoIosArrowBack>
              Πίσω
            </Link>
            <h2> Δημιουργία Υλοποίησης Προγράμματος </h2>
          </div>
          <div className={`${styles.formContainer} ${styles.createProgram}`}>
            <ProgramForm
              ref={formRef}
              onLoad={() => setFormIsLoading(false)}
            />
          </div>
        </>
      }
    </Panel>
  );
};
