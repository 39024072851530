import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

const style = {};

style['input'] = {
    marginRight: '6px',
    cursor: 'pointer'
}

// ---------------------------------------------------------------------------------------

export class CheckboxInput extends Input {

    constructor(props) {

        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = { value: !!this.state.value }

    }

    onChange(e, onFinish) {
        const value = !!((e.target.hasOwnProperty('checked')) ? e.target.checked : e.target.value);
        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    readOnlyRender() {
        return <>
            {this.props.label}
            <div>
                <div className={`readOnly-value-wrap readOnly-value-wrap-${(this.state.value)? 'checked': 'unchecked'}`}></div>
            </div>
        </>
    }

    render() {
        
        if(this.state.isHidden) {
            return null;
        }
        
        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <input 
                checked={this.state.value}
                disabled={this.props.description.isDisabled}
                className={`form-item-input-check`}
                style={style['input']}
                id={`${this.props.description.name}_${this.props.index}`}
                onChange={this.onChange}
                type="checkbox"
            />
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
    }

};

// ---------------------------------------------------------------------------------------