import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { URI, useClient } from "../components/ClientWrapper";
import { download_WithFormRequest } from "../lib/DownloadFile";

export const DownloadFilePage = () => {
    const { fileId, appId } = useParams();
    const axios = useClient();

    const href = window.location.href;
    const subsystem =
        href.includes('business') ?
            'business' :
        href.includes('trainee') ?
            'trainee' :
        null
    ;

    useEffect(() => {
        if (subsystem && appId && fileId){
            download_WithFormRequest(
                `${URI}/${subsystem}/application/file/download`,
                {
                    fileId: fileId,
                    bearerToken: axios.defaults.headers.common["Authorization"],
                    appId: appId
                }
            )
        }
    }, []);
    
    return null;
}