import { createContext, useContext, useEffect, useState } from 'react';

export const PageSizeContext = createContext();

export const usePageSize = () => useContext(PageSizeContext);

export const PageSizeWrapper = ({ children }) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <PageSizeContext.Provider value={width}>{children}</PageSizeContext.Provider>;
};
