import React, { useState, useRef, useEffect } from 'react'
import styles from '../../../styles/Business/BusinessEditInfoPage.module.css'
import { Panel } from '../../../components/Panel'
import { SaveApplicationButton } from '../../SaveApplicationButton'
import { BusinessInfoForm } from './BusinessInfoForm'
import { useClient } from '../../../components/ClientWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

function BusinessEditInfoPage() {
    const [businessInfo, setBusinessInfo] = useState(null);
    const [firstVisit, setFirstVisit] = useState(null);
    const infoFormRef = useRef();
    const axios = useClient();
    const navigate = useNavigate();

    useEffect(() => {
        getInfo();
    }, [])

    const getInfo = async () => {
        axios.get('/business/application/info').then((response) => {
            setFirstVisit(!response.data);

            setBusinessInfo(response.data ? response.data : {});
        }).catch((error) => {
            console.log(error);
        });
    }

    const constructSaveUrl = () => {
        let url = `/business/application/info`
        return firstVisit ? `${url}/create` : `${url}/update`
    }

    const goToApplications = () => { navigate('../all-applications'); }

    return (
        <Panel headerBar="Αίτηση Επιχείρησης"
            footerBar={
                businessInfo &&
                <SaveApplicationButton
                    formRef={infoFormRef}
                    onSuccess={goToApplications}
                    saveUrl={constructSaveUrl()}
                    sanityCheckIgnoreRequired={false}
                />
            }
        >
            {
                firstVisit !== null &&
                <div className={styles.container}>
                    <div className={styles.title}>
                        {
                            firstVisit === false &&
                            <Link className="link" to="..">
                                <IoIosArrowBack />
                                Πίσω
                            </Link>
                        }
                        <h2> Στοιχεία Επιχείρησης</h2>
                    </div>
                    {
                        firstVisit === true &&
                        <div className={styles.description}>
                            Προτού μπορείτε να υποβάλλετε αιτήσεις σε υλοποιήσεις του προγράμματος πρακτικής άσκησης, συμπληρώστε και αποθηκεύστε τις παρακάτω πληροφορίες για την επιχείρηση σας. Μετά την συμπλήρωση των βασικών στοιχείων μπορείτε να δημιουργήσετε νέα αίτηση απο τον πίνακα αιτήσεων.
                        </div>
                    }
                    {businessInfo && <BusinessInfoForm readOnly={false} formRef={infoFormRef} info={businessInfo} />}
                </div>
            }
        </Panel>
    )
}

export default BusinessEditInfoPage