import React, { useState } from "react";
import { BsX } from "react-icons/bs";
import LoaderWrapper from "../LoaderWrapper";
import styles from "../../styles/Manager.module.css";

export default function Manager({
  client,
  applicationGroup,
  applicationId,
  userName,
  userEmail,
  loggedUserName,
  loggedUserEmail,
  onUpdate,
  onError,
}) {
  const [userName_, setUserName] = useState(userName);
  const [userEmail_, setUserEmail] = useState(userEmail);
  const [loading, setIsLoading] = useState(false);

  function manageApplication() {
    setIsLoading(true);

    client
      .put(`/registry/${applicationGroup}/manager`, { applicationId })
      .then((res) => {
        setUserName(loggedUserName);
        setUserEmail(loggedUserEmail);
        onUpdate(loggedUserName, loggedUserEmail);
      })
      .catch((err) => {
        onError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function removeManagement() {
    setIsLoading(true);

    client
      .post(`/registry/${applicationGroup}/deleteManager`, { applicationId })
      .then((res) => {
        setUserName(undefined);
        setUserEmail(undefined);
        onUpdate(undefined, undefined);
      })
      .catch((err) => {
        console.log(err);
        onError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={styles.managerContainer}>
      <LoaderWrapper loading={loading}>
        {
          (userName_ === undefined || userName_ === null) &&
          <div className="link" onClick={manageApplication}>
            Ανάληψη
          </div>
        }
        {userEmail_ !== loggedUserEmail ? (
          <div className={styles.managerName}>{userName_}</div>
        ) : (
          <div className={styles.managerNameOwnContainer}>
            <div className={styles.managerNameOwn}>
              <div className={styles.managerName}> Εγώ </div>
              <BsX
                className={styles.managerNameX}
                size="24"
                color="#96A6B5"
                onClick={removeManagement}
              />
            </div>
          </div>
        )}
      </LoaderWrapper>
    </div>
  );
}
