import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

export class ColorInput extends Input {

    constructor(props) {

        super(props);
        
        this.onChange = this.onChange.bind(this);

    }

    readOnlyRender() {
        return <>
            {this.props.label}
            TODO
        </>
    }

    onChange(e, onFinish) {
        let value = null;
        if(e.target.value !== '') value = e.target.value; 
        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    render() {

        if(this.state.isHidden) {
            return null;
        }

        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <div style={this.props.style}>
                <input
                    className={`form-item-input`}
                    aria-label={this.props.description.title || this.props.description.name}
                    disabled={this.props.description.isDisabled}
                    name={this.props.description.name}
                    value={this.state.value || ''}
                    onChange={this.onChange}
                    type={'color'}/>
            </div>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
    }

};

// ---------------------------------------------------------------------------------------