import React, { createContext, useContext, useRef, useState } from "react";
import { BsCheckCircleFill, BsXCircleFill, BsFillExclamationTriangleFill, BsInfoCircleFill, BsX } from "react-icons/bs";
import styles from '../styles/ToastMessages.module.css'

const icons = {
  success: BsCheckCircleFill,
  failure: BsXCircleFill,
  warning: BsFillExclamationTriangleFill,
  info: BsInfoCircleFill,
};

export const ToastMessage = ({
  type,
  message,
  deleteMe,
  className
}) => {
  const Icon = icons[type] || icons['info'];
  const type_ = icons[type] ? type : 'info';

  return (
    <div className={`${styles.toastMessage} ${styles[type_]} ${className || ''}`} onClick={deleteMe}>
      <div className={styles.leftPart}>
        <Icon className={styles.icon} />
        {message}
      </div>
      {deleteMe && <BsX className={styles.close} />}
    </div>
  );
}

class ToastMessages extends React.Component {

  constructor(props) {
    super(props);
    this.state = { toasts: [] };
  }

  addToastMessage = ({ type, message, duration }) => {
    const toast = { type, message, duration };

    setTimeout(() => this.deleteToastMessage(toast), duration);

    this.setState(state => ({
      toasts: [...state.toasts, toast]
    }));
  }

  deleteToastMessage = toast => {
    this.setState(state => ({
      toasts: state.toasts.filter(t => t !== toast)
    }));
  }

  render() {
    return (
      <div
        className={
          this.props.className ?
            `${styles.toastMessages} ${this.props.className}` :
            styles.toastMessages
        }
      >
        {
          this.state.toasts.map(
            (toast, i) =>
              <ToastMessage
                key={i}
                type={toast.type}
                message={toast.message}
                deleteMe={() => this.deleteToastMessage(toast)}
              />
          )
        }
      </div>
    );
  }

}

export const ToastContext = createContext();

export const useToasts = () => useContext(ToastContext);


export function ToastMessagesWrapper({ className, children }) {
  const toastsRef = useRef(null);

  const addToastMessage = (toast) => toastsRef.current?.addToastMessage(toast);

  return (
    <ToastContext.Provider value={addToastMessage}>
      {children}
      <ToastMessages ref = {toastsRef} className = {className}/>
    </ToastContext.Provider>
  );
}