import React, { useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import { useClient } from '../../../components/ClientWrapper';
import GridWithFilters from '../../../components/GridWithFilters';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { Panel } from '../../../components/Panel'
import { _ } from "gridjs-react";
import ProgramDropdown from '../../../components/ProgramDropdown';
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import styles from '../../../styles/Trainee/TraineeRegistryRankings.module.css'
import { IoOpenOutline } from "react-icons/io5";
import { useToasts } from '../../../components/ToastMessageWrapper';
import { AcceptRejectModal } from '../../../components/AcceptRejectModal';
import { Expandable } from '../../../components/Expandable';
import { BsX } from 'react-icons/bs';
import { useRef } from 'react';
import traineeApplicationJson from '../../../assets/data/trainee-application.json';
import { file2csv, generateApplicantsCsv } from '../../../lib/csv';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { localeDateStringWithTime } from '../../../util/util';

//TODO complete case when program has no rankings

const columns = [
  {
    id: "id",
    name: "id",
    hidden: true,
  },
  {
    id: "ranking",
    name: "#",
  },
  {
    id: "kayas",
    name: "ΚΑΥΑΣ",
  },
  {
    id: "name",
    name: "Όνομα",
  },
  {
    id: "afm",
    name: "Α.Φ.Μ",
  },
  {
    id: "finalizeDate",
    name: "Οριστικοποίηση",
  },
  {
    id: "open",
    width: 30,
    sort: false,
  },
];

const language = {
  search: {
    placeholder: "Αναζήτηση",
  },
  pagination: {
    previous: "Προηγούμενο",
    next: "Επόμενο",
    showing: "Προβάλλονται οι Αιτήσεις",
    to: "-",
    of: "από",
    results: () => "",
  },
  noRecordsFound: "Δεν βρέθηκαν αιτήσεις",
};

function TraineeRegistryAllRankings() {
  const [applicants, setApplicants] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [matchingModalIsVisible, setMatchingModalIsVisible] = useState(false);
  const [deletionModalIsVisible, setDeletionModalIsVisible] = useState(false);
  const [unrankApplicationModalIsVisible, setUnrankApplicationModalIsVisible] = useState(false);
  const addToast = useToasts();
  const axios = useClient();
  const navigate = useNavigate();

  const appGroupForDeletion = useRef(null);
  const appForUnranking = useRef(null);

  const openIndex = columns.findIndex(col => col.id === "open");

  columns[openIndex].formatter = (cell, row) => {
    const { rankingDate } = cell;

    return _(
      <div className={styles.actionsContainer}>
        {
          !!rankingDate &&
          <>
            <RiDeleteBack2Fill
              className={styles.deleteButton}
              onClick={() => {
                appForUnranking.current = row.cells[0].data;
                setUnrankApplicationModalIsVisible(true);
              }}
            />
            <div className={styles.separator}></div>
          </>
        }

        <IoOpenOutline
          className={styles.openButton}
          onClick={() => navigate(`../${row.cells[0].data}`)}
        />
      </div>
    );
  };

  function beginRanking() {
    axios
      .put(`/registry/trainee/rank?programId=${selectedProgram._id}`)
      .then((res) => {
        getApplications(selectedProgram);
      })
  }

  function getApplications(program) {
    if (!program) return;

    setSelectedProgram(program);
    setApplicants(null);

    axios
      .get(`/registry/trainee/getApplications?isEvaluated=${true}&isAccepted=${true}&programId=${program._id}`)
      .then((res) => {
        const appGroups = new Map();

        appGroups.set(null, []);

        res.data.forEach(app => {
          if (!appGroups.get(app.rankingDate))
            appGroups.set(app.rankingDate, []);

          appGroups.get(app.rankingDate).push(app);
        });

        const extractResults = (applicantGroup) => {
          return applicantGroup.map(
            ({
              _id,
              kayas,
              personalInfo: { firstName, lastName, taxId },
              finalizeDate,
              rankingDate
            }, i) => [
                _id,
                `${i + 1}`,
                kayas,
                `${firstName} ${lastName}`,
                taxId,
                localeDateStringWithTime(finalizeDate),
                {
                  // needed for the action formatter
                  rankingDate
                }
              ]
          );
        }

        const applicants = [];

        appGroups.forEach(
          (appGroup, rankingDate) => {
            applicants.push({
              date: rankingDate,
              data: extractResults(appGroup),
              csv: generateApplicantsCsv(appGroup, traineeApplicationJson, file2csv('trainee'))
            })
          }
        );

        applicants.sort((a, b) => {
          a = a.date;
          b = b.date;
          return a === null ? -1 : b === null ? 1 : (a >= b) ? -1 : 1;
        });

        setApplicants(applicants);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onSubmitSuccess = useCallback(res => {
    addToast({
      type: "success",
      message: "Η Κατάταξη Ολοκληρώθηκε Επιτυχώς",
      duration: 5000,
    });
  }, [])

  const onSubmitFailure = useCallback(err => {
    addToast({
      type: "failure",
      message: err,
      duration: 5000,
    });
  }, []);

  const deleteAppGroup = () => {
    const appGroupDate = applicants[appGroupForDeletion.current].date;

    axios.put(`/registry/trainee/unrank?rankingDate=${appGroupDate}&programId=${selectedProgram._id}`)
      .then(res => {
        getApplications(selectedProgram);
      })
      .catch(console.log);
  }

  const unrankApplication = () => {
    axios.put(
      `/registry/trainee/unrank?appId=${appForUnranking.current}`
    )
      .then(res => {
        getApplications(selectedProgram);
      })
      .catch(console.log);
  }

  const onNoPrograms = () => setApplicants([])

  return (
    <Panel
      headerBar="Προσωρινή Κατάταξη και Σύζευξη Ωφελούμενων"
      footerBar={
        applicants && applicants.length > 0 &&
        <ButtonWithLoading onClick={() => setMatchingModalIsVisible(true)}>
          Εφαρμογή
        </ButtonWithLoading>
      }
    >
      <div className={styles.appsContainer}>
        <ProgramDropdown onChange={getApplications} onNoPrograms={onNoPrograms} isCompleted={false} />
        <LoaderWrapper size={"75"} thickness={"7.5px"} loading={!applicants}>
          <div className={styles.container}>
            {
              applicants && applicants.length > 0 && applicants.map((appGroup, i) =>
                <Expandable key={appGroup.date}
                  isExpanded={appGroup.date === null}
                  title={
                    appGroup.date ?
                      <div className={styles.appGroupTitle}>
                        Ομάδα Αιτήσεων {(new Date(appGroup.date)).toLocaleString("el-GR")}
                        <BsX className={styles.xButton} onClick={e => {
                          e.stopPropagation();
                          appGroupForDeletion.current = i;
                          setDeletionModalIsVisible(true);
                        }} />
                      </div>
                      :
                      <div> Τρέχουσα Ομάδα Αιτήσεων </div>
                  }
                  content={
                    <div className={styles.grid}>
                      <GridWithFilters
                        dataInit={appGroup.data} //to keep track with gridjs private vars...
                        data={appGroup.data}
                        csv={{
                          ...appGroup.csv,
                          filename: 'Αιτήσεις Ωφελούμενων'
                        }}
                        columns={columns}
                        search={true}
                        sort={true}
                        pagination={{ limit: 13 }}
                        language={language}
                      />
                    </div>
                  }
                />
              )
            }
            {
              applicants && applicants.length === 0 &&
              <div className={styles.noApplications}> Δεν βρέθηκαν αιτήσεις </div>
            }
          </div>
        </LoaderWrapper>
        {
          matchingModalIsVisible &&
          <AcceptRejectModal
            title='Εφαρμογή Προσωρινής Κατάταξης και Σύζευξης'
            message='Η προσωρινή κατάταξη και σύζευξη των ωφελούμενων θα εφαρμοστεί στην τρέχουσα ομάδα αιτήσεων και οι αιτήσεις θα ομαδοποιηθούν σύμφωνα με την τωρινή ημερομηνία. Είστε σίγουροι ότι θέλετε να συνεχίσετε;'
            continueButtonText='Εφαρμογή'
            submit={beginRanking}
            onSubmitFailure={onSubmitFailure}
            onSubmitSuccess={onSubmitSuccess}
            onClose={() => setMatchingModalIsVisible(false)}
          />
        }
        {
          deletionModalIsVisible &&
          <AcceptRejectModal
            title='Κατάργηση Ομάδας Αιτήσεων'
            message='Η επιλεγμένη ομάδα αιτήσεων θα διαγραφεί, όμως οι αιτήσεις θα γίνουν ξανά διαθέσιμες μέσω της τρέχουσας ομάδας αιτήσεων. Είστε σίγουροι ότι θέλετε να συνεχίσετε;'
            submit={deleteAppGroup}
            onSubmitFailure={() => { }}
            onSubmitSuccess={() => { }}
            onClose={() => setDeletionModalIsVisible(false)}
          />
        }
        {
          unrankApplicationModalIsVisible &&
          <AcceptRejectModal
            title='Επανένταξη της αίτησης στην τρέχουσα ομάδα αιτήσεων'
            message='Η επιλεγμένη αίτηση θα μετακινηθεί στην τρέχουσα ομάδα αιτήσεων. Είστε σίγουροι ότι θέλετε να συνεχίσετε;'
            submit={unrankApplication}
            onSubmitFailure={() => { }}
            onSubmitSuccess={() => { }}
            onClose={() => setUnrankApplicationModalIsVisible(false)}
          />
        }
      </div>
    </Panel>
  );
}

export default TraineeRegistryAllRankings