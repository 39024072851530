import { useEffect } from 'react';
import { MdWebAssetOff } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { usePageInfo } from '../../components/PageInfoWrapper';
import styles from '../../styles/Error/Error.module.css';

export const Error404 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.error}>
        <MdWebAssetOff className={styles.icon} />
        <h1> Σφάλμα 404 </h1>
      </div>
      <div>
        <h2> Ουπς! H σελίδα που αναζητήσατε δεν βρέθηκε... </h2>
        <p> Παρακαλούμε επικοινωνήστε με το διαχειριστή ή δοκιμάστε να επαναφορτώσετε την ιστοσελίδα </p>
      </div>
      <button className="defaultButton">
        <Link to='/'> Πίσω στην αρχική </Link>
      </button>
    </div>
  );
}