import { useEffect, forwardRef, useState } from "react";
import { Form } from "../../react-form/src/Form";
import createProgramJson from "../../assets/data/admin/createProgram.json";
import { useClient } from "../../components/ClientWrapper";
import { useUser } from "../../components/UserWrapper";
import LoaderWrapper from "../../components/LoaderWrapper";

export const ProgramForm = forwardRef(({readOnly, program, onLoad}, ref) => {
  const [formDescription, setFormDescription] = useState(null);
  const axios = useClient();
  const {user} = useUser();

  const initializeDescription_SuperAdmin = () => {
    axios.get("/carrier")
      .then((res) => {
        const updatedFormDescription = JSON.parse(JSON.stringify(createProgramJson));

        const carrierDropDown = updatedFormDescription.questions
          .find((q) => q.name === "information")
          .questions.find((q) => q.name === "carrier");

        carrierDropDown.choices = res.data.map((carrier) => {
          return {
            title: carrier.title,
            value: carrier._id,
          };
        });

        if (program?.isPublished) makeFieldsDisabled(updatedFormDescription);

        setFormDescription(updatedFormDescription);

        onLoad?.();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const makeFieldsDisabled= (description) => {
    if (!description.questions){
      if (
        description.name !== 'traineeStartDate' &&
        description.name !== 'traineeEndDate' &&
        description.name !== 'businessStartDate' &&
        description.name !== 'businessEndDate' &&
        description.name !== 'applicationLimit'
      ){
        description.isDisabled = true;
      }

      return;
    }

    for (const question of description.questions)
      makeFieldsDisabled(question)
  }

  const initializeDescription_CarrierAdmin = () => {
    axios.get(`/carrier/${user.carrier}`)
      .then((res) => {
        const updatedFormDescription = JSON.parse(JSON.stringify(createProgramJson));

        const carrierDropDown = updatedFormDescription.questions
          .find((q) => q.name === "information")
          .questions.find((q) => q.name === "carrier");

        carrierDropDown.choices = [{ title: res.data.title, value: res.data._id }];
        carrierDropDown.value = res.data._id;
        carrierDropDown.isDisabled = true;

        if (program?.isPublished) makeFieldsDisabled(updatedFormDescription);

        setFormDescription(updatedFormDescription);

        onLoad?.();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (user.carrier) initializeDescription_CarrierAdmin();
    else initializeDescription_SuperAdmin();
  }, [user, program?.isPublished]);

  return (
    <LoaderWrapper loading = {!formDescription} size = {'50'} thickness = {'5px'}>
      <Form
        key={program?.isPublished}
        readOnly = {readOnly}
        description={formDescription}
        ref={ref}
        initialValue={program}
      />
    </LoaderWrapper>
  );
});