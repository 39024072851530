import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { URI, useClient } from "./ClientWrapper";

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export function UserWrapper({ children }) {
  const [user, setUser] = useState(null);
  const [working, setWorking] = useState(true);
  const axios = useClient();
  const timeoutId = useRef();

  const setRefreshTimeout = () => {
    if (user) timeoutId.current = setTimeout(refreshToken, 300 * 1000 - 1000);
  };

  useEffect(setRefreshTimeout, [user]);

  const refreshToken = () => {
    axios
      .post(`${URI}/user/refreshToken`)
      .then((res) => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
        setUser(res.data.user);
      })
      .catch(() => {
        //todo
      })
      .finally(() => {
        setWorking(false);
      });
  };

  useEffect(() => {
    setUser(null);
    setWorking(true);
    refreshToken();

    return () => {
      if (timeoutId.current !== undefined) clearTimeout(timeoutId.current);
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {working ? null : children}
    </UserContext.Provider>
  );
}
