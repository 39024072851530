import React, { useCallback, useState } from "react";
import Modal from "./Modal";
import { useBlocker } from "../lib/useBlocker";

export function ModalBlocker({title, message, when = true}){
  const [visible, setVisible] = useState(false);
  const [tx, setTx] = useState(null);
  
  const unblock = () => tx.retry();
  const onClose = () => setVisible(false);

  const blocker = useCallback(
    (tx) => {
      setVisible(true);
      setTx(tx);
      return false;
    },
    [title, message, when]
  );

  useBlocker(blocker, !!when);

  if (!visible)
    return null;

  return (
    <Modal
      header={title}
      content={
        <div style = {{
          padding: '20px 0px'
        }}>
          {message}
        </div>
      }
      footer={
        <>
          <button className = "defaultButton" onClick = {unblock}> Συνέχεια </button>
          <button className = "cancelButton" onClick = {onClose}> Ακύρωση </button>
        </>
      }
      style={{
        top: '10px'
      }}
      onClose={onClose}
    >
    </Modal>
  );
}