import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Navigate, Route, Routes } from "react-router-dom";
import TraineeRegistryAllRankings from "./TraineeRegistryAllRankings";
import TraineeRegistryMatches from "./TraineeRegistryMatches";

function TraineeRegistryRankings() {
    return (
        <Routes>
            <Route path="/all" element={<TraineeRegistryAllRankings />} />
            <Route path="/:appId" element={<TraineeRegistryMatches />} />
            <Route path="/" element={<Navigate to="all" />} />
            <Route path="/*" element={<Navigate to="/error404" />} />
        </Routes>
    );
}

export default TraineeRegistryRankings;
