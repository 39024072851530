import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useClient } from "../../components/ClientWrapper";
import { usePageInfo } from "../../components/PageInfoWrapper";
import { useUser } from "../../components/UserWrapper";
import styles from "../../styles/Login.module.css";
import { IoLogoGoogle } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "react-google-login";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { Form } from "../../react-form/src/Form";
import description from "../../assets/data/login.json";
import { usePageSize } from "../../components/withPageSize";

function GoogleLoginButton() {
  const [working, setWorking] = useState(false);
  const axios = useClient();
  const { user, setUser } = useUser();

  const width = usePageSize();

  const onSuccess = (googleResponse) => {
    console.log(googleResponse);

    const token = googleResponse.tokenId;

    const {
      familyName: lastName,
      givenName: firstName,
      email,
    } = googleResponse.profileObj;

    const user = { firstName, lastName, email };

    axios
      .post("/user/login/google", user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
        setUser(res.data.user);
      })
      .catch((err) => {
        //todo
        console.log(err);
      });
  };

  const onFailure = () => {};

  const onRequest = () => {};

  const { signIn, loaded } = useGoogleLogin({
    onSuccess,
    onAutoLoadFinished: (arg) => {
      setWorking(true);
    },
    clientId:
      "854689213507-omtsd5lmojuobvbfgqbar6dgksq4k3e1.apps.googleusercontent.com",
    cookiePolicy: "single_host_origin",
    // loginHint,
    onFailure,
    onRequest,
    // prompt
  });

  if (!working) return null;

  return (
    <div className={styles.loginWith} onClick={signIn}>
      <div className={styles.icon}>
        <IoLogoGoogle size="28" color="#DD422D"></IoLogoGoogle>
      </div>
      { width >= 400 && <div> Σύνδεση με Google </div> }
    </div>
  );
}

function LoginPage() {
  const recaptchaToken = useRef(null);
  const recaptchaRef = useRef(null);
  const buttonRef = useRef(null);
  const formRef = useRef(null);
  const errorRef = useRef(null);

  const { user, setUser } = useUser();
  const axios = useClient();

  const width = usePageSize();

  const recaptchaOnChange = (value) => (recaptchaToken.current = value);

  useEffect(() => {
    Form.registerRestrictionCheck({
      tag: "validateLogin_Login",
      func: () => {
        return !errorRef.current;
      },
      explanation:
        "Τα στοιχεία σύνδεσης δεν αντιστοιχούν σε υπάρχοντα λογαριασμό στο σύστημα",
    });
  }, []);

  const login = (e) => {
    if (recaptchaToken.current) {
      formRef.current.clearSanityCheck();
      buttonRef.current.setLoading(true);

      const { email, password } = formRef.current.getData();

      axios
        .post("/user/login", {
          email,
          password,
          recaptchaToken: recaptchaToken.current,
        })
        .then((res) => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.token}`;
          errorRef.current = false;
          setUser(res.data.user);
        })
        .catch((err) => {
          console.log(err.response.data.error);
          recaptchaRef.current.reset();
          recaptchaToken.current = null;
          errorRef.current = true;
        })
        .finally(() => {
          buttonRef.current?.setLoading(false);
          formRef.current?.sanityCheckForm();
        });
    }
  };

  if (user) {
    return <Navigate to="/"></Navigate>;
  }

  return (
    <div className={styles.wholePage}>
      <div className={styles.container}>
        <div className={styles.title}> Σύνδεση </div>

        {
          process.env.NODE_ENV === 'development' &&
          <>
            <GoogleLoginButton />
            <div className={styles.or}>
              <div className={styles.orLine}></div>
              <div> ή </div>
              <div className={styles.orLine}></div>
            </div>
          </>
        }

        <Form ref={formRef} description={description} />

        <Link
          className={`link ${styles.forgotPassword}`}
          to={"/forgot-password"}
        >
          Ξέχασα τον κωδικό μου
        </Link>

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
          onChange={recaptchaOnChange}
          className={styles.recaptcha}
          size={width <= 400 ? 'compact' : 'normal'}
        ></ReCAPTCHA>

        <ButtonWithLoading ref={buttonRef} onClick={login}>
          Σύνδεση
        </ButtonWithLoading>

        <div className={styles.noAccountContainer}>
          <div className={styles.noAccount}> Δεν έχετε λογαριασμό; </div>
          <Link className="link" to="/register">
            Εγγραφή
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
