import ErrorPrompt from './ErrorPrompt';
import { Input } from './Input';

// ---------------------------------------------------------------------------------------

import { ReactComponent as ShowPassword } from '../../assets/images/eye.svg';
import { ReactComponent as HidePassword } from '../../assets/images/eye-slash.svg';

// ---------------------------------------------------------------------------------------

export class TextInput extends Input {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state.passwordIsVisible = false;
    }

    readOnlyRender() {
        return (
            <>
                {this.props.label}
                {this.props.description.isPassword ? (
                    <div className='readOnly-value-wrap'>***</div>
                ) : (
                    <div className='readOnly-value-wrap'>{this.state.value || '-'}</div>
                )}
            </>
        );
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            passwordIsVisible: !prevState.passwordIsVisible
        }));
    };

    onChange(e, onFinish) {
        let value = null;
        if (e.target.value !== '') value = e.target.value;
        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div className='form-item-input-text-container' style={this.props.style}>
                    <input
                        className={`form-item-input${
                            this.props.description.isPassword ? ' form-item-input-password' : ''
                        }`}
                        aria-label={this.props.description.title || this.props.description.name}
                        disabled={this.props.description.isDisabled}
                        name={this.props.description.name}
                        value={this.state.value || ''}
                        placeholder={this.placeholder}
                        onChange={this.onChange}
                        type={
                            this.props.description.isPassword && !this.state.passwordIsVisible
                                ? 'password'
                                : 'text'
                        }
                    />
                    {this.props.description.isPassword &&
                        (!this.state.passwordIsVisible ? (
                            <ShowPassword
                                className='form-show-password'
                                onClick={this.togglePasswordVisibility}
                            />
                        ) : (
                            <HidePassword
                                className='form-show-password'
                                onClick={this.togglePasswordVisibility}
                            />
                        ))}
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}

// ---------------------------------------------------------------------------------------
