import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import styles from "../../src/styles/Expandable.module.css";

export const Expandable = ({title, content, isExpanded: isExpanded_}) => {
  const [isExpanded, setIsExpanded] = useState(!!isExpanded_);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <div className={styles.expandable} onClick={toggleExpanded}>
        {
          isExpanded ?
            <IoIosArrowDown className={styles.arrow}/>:
            <IoIosArrowForward className={styles.arrow}/>
        }
        <div className={styles.title}> {title} </div>
      </div>
      {
        isExpanded &&
        <div className={styles.content}> {content} </div>
      }
    </div>
  );
}