import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useClient } from "../../../components/ClientWrapper";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Trainee/TraineeRegistryEvaluation.module.css";
import { Form } from "../../../react-form/src/Form";
import { useUser } from "../../../components/UserWrapper";
import evaluationFormDesc_ from "../../../assets/data/EvaluationForm.json";
import { IoIosArrowBack } from "react-icons/io";
import { TraineeApplicationForm } from "../TraineeApplication/TraineeApplicationForm";
import { RegistryEvaluationButton } from "../../../components/RegistryPageUtils/RegistryEvaluationButton";

function TraineeRegistryEvaluation() {
  const { appId } = useParams();
  const axios = useClient();
  const { user } = useUser();

  const [app, setApplication] = useState(null);

  const [readOnly, setReadOnly] = useState(true);

  const [evaluationFormDesc,] = useState(JSON.parse(JSON.stringify(evaluationFormDesc_)));
  const evaluationFormRef = useRef();
  const applicationFormRef = useRef();

  const makeFormDisabled = (formDescription) => {
    if (!formDescription.questions) return (formDescription.isDisabled = true);

    for (let q of formDescription.questions) makeFormDisabled(q);
  };

  const getApplication = () => {
    axios
      .get(`/trainee/application/${appId}`)
      .then((res) => {
        const manager = res.data.application?.manager;

        if (manager && manager.email === user.email) {
          setReadOnly(false);
        } else {
          setReadOnly(true);
          makeFormDisabled(evaluationFormDesc);
        }

        res.data.application.programInfo = { program: res.data.application?.program._id };

        setApplication(res.data.application || {});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getApplication();
  }, []);

  function getAppFormData() {
    const formData = {};

    if (!app.isEvaluated) return formData;

    if (app.evaluation.accepted === true || app.evaluation.accepted === false)
      formData.result = app.evaluation.accepted === true ? "success" : "failure";

    app.evaluation.comment && (formData.comment = app.evaluation.comment);

    return formData;
  }

  return (
    <Panel headerBar="Έλεγχος Αιτήσεων">
      {
        app &&
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.backButton}>
              <Link className="link" to="../all">
                <IoIosArrowBack></IoIosArrowBack>
                Πίσω
              </Link>
              <h2> Αίτηση του χρήστη {app.personalInfo.firstName} {app.personalInfo.lastName}</h2>
            </div>
            <TraineeApplicationForm
              application={app}
              formRef={applicationFormRef}
              readOnly={true}
            />
          </div>
          <div className={styles.right}>
            <Form
              ref={evaluationFormRef}
              description={evaluationFormDesc}
              initialValue={getAppFormData()}
            />
            {
              !readOnly &&
              <RegistryEvaluationButton
                formRef={evaluationFormRef}
                appId={appId}
                evaluateUrl={'/registry/trainee/evaluate'}
              />
            }
          </div>
        </div>
      }
    </Panel>
  );
}

export default TraineeRegistryEvaluation;
