import React, { useEffect, useState } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { BsInfoCircleFill } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import { BusinessApplications } from "./BusinessApplication";
import { BusinessInfo } from "./BusinessInfo";
import { SideNavPage } from "../../../components/SideNavPage";
import { useClient } from "../../../components/ClientWrapper";
import LoaderWrapper from "../../../components/LoaderWrapper";

const options = [
  {
    Icon: BsInfoCircleFill,
    text: "Πληροφορίες",
    path: "info",
    element: <BusinessInfo />,
  },
  {
    Icon: HiPencilAlt,
    text: "Οι Αιτήσεις μου",
    path: "application",
    element: <BusinessApplications />,
    innerRouting: true,
  },
];

function BusinessPage() {
  const [application, setApplication] = useState(null);
  const axios = useClient();

  const getApplication = () => {
    axios
      .get(`/business/application/`)
      .then((res) => {
        setApplication(res.data.application || {});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getApplication();
  }, []);

  return (
    <LoaderWrapper loading={!application} size={'75'} thickness={'7.5px'}>
      {
        application &&
        <SideNavPage prefix='business' options={options} />
      }
    </LoaderWrapper>
  );
}

export default BusinessPage;
