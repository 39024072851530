import { URI, useClient } from "../../components/ClientWrapper";
import { download, download_WithFormRequest } from "../../lib/DownloadFile";
import { useCallback, useEffect, useState } from "react";
import { FormWithDirty } from "../FormWithDirty";

export const ApplicationForm = ({
  application,
  description: description_,
  dependancies,
  formRef,
  readOnly,
  onDirtyChange,
  DownloadFileUrl
}) => {

  const downloadFile = (file) => {
    if (file._id) {
      download_WithFormRequest(
        `${URI}${DownloadFileUrl}`,
        {
          fileId: file._id,
          bearerToken: axios.defaults.headers.common["Authorization"],
          appId: application._id
        }
      );
    } else download(file.name, file);
  };

  const initializeDescription = () => {
    const description = JSON.parse(JSON.stringify(description_));

    if (readOnly) makeFormRequired(description);

    setUpFileCallbacks(description);
    return description;
  }

  const makeFormRequired = (description) => {
    if (!description.questions) {
      const type = description.type;

      if (type !== "checkbox" && type !== "label")
        description.isRequired = true;
    }
    else
      for (let q of description.questions) makeFormRequired(q);
  };

  const setUpFileCallbacks = (description) => {
    if (!description.questions) {
      const type = description.type;

      if (type === "file") description.callbacks = { onClick: downloadFile }
    }
    else
      for (let q of description.questions) setUpFileCallbacks(q);
  }

  const axios = useClient();
  const [description, setDescription] = useState(initializeDescription());

  const getRef = useCallback(ref => {
    const firstTime = !formRef.current && ref;

    formRef.current = ref;

    if (firstTime)
      formRef.current.checkpoint(application);
  }, []);

  const onChangeDropdown = (path, value, dependent, options) => {
    const pathArray = path.split('/');

    pathArray.shift();
    pathArray.pop();

    let input = formRef.current;

    for (let i = 0; i < pathArray.length; i++) {
      input = input?.getQuestion?.(pathArray[i]);
    }

    input = input?.getQuestion?.(dependent);

    if (!input) return;

    let options_ = [];

    if (value !== null && value !== undefined)
      options_ = options(value).map(o => { return { title: o, value: o } });

    input.setOptions(options_);
    input.setData(null);
  }

  const handleAutofillDropdowns = (path, value) => {
    if (dependancies)
      for (let dependancy of dependancies) {
        if (dependancy.path.test(path))
          onChangeDropdown(
            path,
            value,
            dependancy.dependent,
            dependancy.options
          );
      }
  };

  const onChange = useCallback((path, value) => {
    handleAutofillDropdowns(path, value);
  }, []);

  if (!application || !description) return null;

  return (
    <div style={{ padding: '20px 0px' }}>
      <FormWithDirty
        ref={getRef}
        readOnly={!!readOnly}
        description={description}
        onChange={onChange}
        onDirtyChange={onDirtyChange}
      />
    </div>
  );

}