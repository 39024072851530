import React, { useEffect, useState } from "react";
import styles from "../styles/SideNavPage.module.css";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { ToastMessagesWrapper } from "./ToastMessageWrapper";
import { usePageInfo } from "./PageInfoWrapper";

export const SideNavPage = ({ options, prefix }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setNavOptions } = usePageInfo();

  const sidebarOptionsInit = () => {
    const optionsInit = {};
    for (let i of options) {
      optionsInit[i.path] = i;
      optionsInit[i.path].onClick = () => navigate(i.path);
    }
    return optionsInit;
  }

  const [sidebarOptions, setSidebarOptions] = useState(sidebarOptionsInit());

  useEffect(() => {
    const routes = location.pathname.split('/');

    const optionIndex = prefix !== undefined ? routes.indexOf(prefix) + 1 : routes.length - 1;
    const optionName = routes[optionIndex];

    if (optionName) {
      const updatedOptions = sidebarOptionsInit();

      updatedOptions[optionName] = {
        ...updatedOptions[optionName],
        selected: true
      };

      setSidebarOptions(updatedOptions);
    }
  }, [location]);

  useEffect(() => {
    setNavOptions(options);
    return () => setNavOptions([]);
  }, [])

  return (
    <div className={styles.wholeContent}>
      <Sidebar options={Object.values(sidebarOptions)} />
      <div className={styles.rhs}>
        <ToastMessagesWrapper className={styles.toastMessages}>

          <Routes>
            {
              options.map(
                (option, i) =>
                  <Route
                    key={i}
                    path={option.path + (option.innerRouting ? '/*' : '')}
                    element={option.element}
                  />
              )
            }
            <Route path="*" element={<Navigate to={'/error404'} />} />
          </Routes>

        </ToastMessagesWrapper>
      </div>
    </div>
  );
}