import { BsCheckLg, BsFlagFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import styles from "../styles/Steps.module.css";

export const Step = ({ i, state, isFinal, text }) => {
  const content = (state === 'success') ?
                    <BsCheckLg className={styles.check} /> :
                  (state === 'failure') ?
                    <IoClose className={styles.fail} /> :
                  isFinal ?
                    <BsFlagFill className={styles.flag} /> :
                  <div className={styles.stage}> {i} </div>
  ;

  return (
    <>
      <div className={`${styles.circle} ${styles[state]}`}>
        {content}
        <div className={styles.text}> {text} </div>
      </div>
      {!isFinal && <div className={styles.line}> </div>}
    </>
  );
};

export const Steps = ({steps, currStep, currStepState}) => {
  return (
    <div className={styles.progress}>
      {
        steps.map((step, i) => (
          <Step
            key={i}
            i={i + 1}
            state={
              (i < currStep) ?
                "success" :
              (i === currStep) ?
                currStepState :
              "incomplete"
            }
            text={step}
            isFinal={i === steps.length - 1}
          />
        ))
      }
    </div>
  )
};