import { createRef } from "react";

// ---------------------------------------------------------------------------------------

import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

export class FreeTextInput extends Input {

    constructor(props) {

        super(props);

        this.state.autoHeight = 0;
        this.divTextContainer = createRef();
        this.autoHeightOffset = 2;

        this.onChange = this.onChange.bind(this);
    }

    resize() {
        if (
            this.state.autoHeight !==
            this.divTextContainer.current.clientHeight + this.autoHeightOffset
        ){
            this.setState({
                autoHeight: this.divTextContainer.current.clientHeight + this.autoHeightOffset
            });
        }
    }

    componentDidMount() {
        if (this.props.description.autoHeight){
            this.resize();
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.description.autoHeight){
            this.resize();
        }
    }

    onChange(e, onFinish) {
        this.valueUpdate(this.state.value, e.target.value);
        this.setState({ value: e.target.value }, onFinish);
    }

    readOnlyRender() {
        return <>
            {this.props.label}
            <div className="readOnly-value-wrap" readOnly> {this.state.value || '-'} </div>
        </>
    }

    render() {

        if(this.state.isHidden) {
            return null;
        }
        
        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <div className="form-group" style={{
                ...this.props.style,
                position: 'relative',
            }}>
                <textarea
                    aria-label={this.props.description.title || this.props.description.name}
                    className={`form-item-input-text-area form-item-input form-item-input-text`}
                    disabled={this.props.description.isDisabled}
                    name={this.props.description.name}
                    value={this.state.value || ''}
                    placeholder={this.placeholder}
                    onChange={this.onChange}
                    style = {
                        this.props.description.autoHeight ?
                        {
                            height: this.state.autoHeight,
                            overflow: 'hidden',
                        }
                        :
                        undefined
                    }
                />
                {
                    this.props.description.autoHeight &&
                    <div
                        ref = {this.divTextContainer}
                        className={`form-item-input-text-area form-item-input form-item-input-text`}
                        style = {{
                            whiteSpace: 'pre-wrap',
                            visibility: 'hidden',
                            position: 'absolute'
                        }}
                    >
                        {
                            this.state.value ? (this.state.value + '\n') : ''
                        }
                    </div>
                }
            </div>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
    }

};

// ---------------------------------------------------------------------------------------