import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

export class FloatInput extends Input {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    setData(data, onFinish) {
        if (typeof data === 'number') data = data.toString();

        this.onChange({
            target: {
                value: data
            }
        }, onFinish);
    }

    getData() {
        const numberValue = parseFloat(this.state.value);
        
        if (isNaN(numberValue)) return null;
        else if(this.state.value === '') return null;

        return numberValue;
    }

    onChange(e) {
        const value = e.target.value;
        
        this.valueUpdate(this.state.value, value);
        this.setState({ value: value });
    }

    readOnlyRender() {
        return <>
            {this.props.label}
            <div className="readOnly-value-wrap">{this.state.value || '-'}</div>
        </>
    }

    render() {
        
        if(this.state.isHidden) {
            return null;
        }

        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <div style={this.props.style}>
                <input
                    aria-label={this.props.description.title || this.props.description.name}
                    className={`form-item-input`}
                    disabled={this.props.description.isDisabled}
                    name={this.props.description.name}
                    placeholder={this.placeholder}
                    step={0.01}
                    type="number"
                    onChange={this.onChange}
                    value={this.state.value || ''}
                />
            </div>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
    }

};

// ---------------------------------------------------------------------------------------