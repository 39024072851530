import { useState, useRef, useCallback } from "react";
import { AcceptRejectModal } from "../AcceptRejectModal";
import { useClient } from "../ClientWrapper";
import { useToasts } from "../ToastMessageWrapper";
import styles from "../../styles/DeleteApplicationButton.module.css"
import { FaTrash } from "react-icons/fa";

export const DeletionButton = ({
  deletionUrl,
  redirectUrl,
}) => {
  const addToast = useToasts();
  const buttonRef = useRef();
  const axios = useClient();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const onDelete = async e => {
    try {
      var res = await axios.delete(deletionUrl);
    }
    catch (err) {
      throw "Υπήρξε κάποιο πρόβλημα με τη διαγραφή της αίτησης σας. Παρακαλώ ξαναπροσπαθήστε.";
    }
    return res;
  }

  const onSubmitSuccess = useCallback(res => {
    addToast({
      type: "success",
      message: "Η αίτηση σας διαγράφηκε.",
      duration: 5000,
    });

    if (redirectUrl) window.location.href = redirectUrl;
  }, [])

  const onSubmitFailure = useCallback(err => {
    addToast({
      type: "failure",
      message: err,
      duration: 5000,
    });
  }, []);

  const onClose = () => {
    setModalIsVisible(false);
  }

  return (
    <>
      <button
        className={`defaultButton ${styles.delete}`}
        onClick={() => setModalIsVisible(true)}
        ref={buttonRef}
      >
        <FaTrash />
        Διαγραφή
      </button>
      {
        modalIsVisible &&
        <AcceptRejectModal
          title='Διαγραφή Αίτησης'
          message={
            <>
              Είστε σίγουροι ότι θέλετε να διαγράψετε την αίτηση σας;
              <br /> <br />
              Η διαγραφή δεν είναι αναιρέσιμη, ωστόσο θα μπορείτε να δημιουργήσετε νέες αίτησεις.
            </>
          }
          continueButtonText='Διαγραφή Αίτησης'
          submit={onDelete}
          onSubmitFailure={onSubmitFailure}
          onSubmitSuccess={onSubmitSuccess}
          onClose={onClose}
        />
      }
    </>
  );
}