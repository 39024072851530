import { Form } from "../../src/react-form/src/Form";

const compare = (value1, value2) => {
  if (value1 && value2 && typeof value1 === "object" && typeof value2 === "object") {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return (
        value1.length === value2.length &&
        value1.every((v1, i) => compare(v1, value2[i]))
      );
    }
    else {
      const keys1 = Object.keys(value1), keys2 = Object.keys(value2);

      return (
        keys1.length === keys2.length &&
        keys1.every(key => compare(value1[key], value2[key]))
      );
    }
  }

  return value1 === value2;
};

export class FormWithDirty extends Form {

  constructor(props) {
    super(props);

    this.state.checkpointData = props.data ? JSON.parse(JSON.stringify(props.data)) : null;
    this.state.dirty = props.data ? [] : null;
  }

  /* the user should call this for initializing the form's data */
  checkpoint = (data, cb) => {
    this.setState(
      {
        checkpointData: JSON.parse(JSON.stringify(data)),
        dirty: []
      },
      () => {
        this.setData(data, cb);
      }
    );
  }

  handleDirty = (path, value) => {
    const pathArray = path.split("/");

    let value_ = this.state.checkpointData;

    for (let p of pathArray) {
      value_ = value_[p];

      // if checkpointData is not yet initialized then the fields
      // are undefined. In this case treat them as empty (null)
      if (value_ === undefined) {
        value_ = null;
        break;
      }
    }

    this.setState(prevState => {
      const dirty = prevState.dirty;

      const isDirty = dirty.includes(path);
      const areEqual = compare(value, value_);

      if (!areEqual && !isDirty) {
        return { dirty: [...dirty, path] };
      } else if (areEqual && isDirty) {
        return { dirty: dirty.filter((path_) => path_ !== path) };
      } else return { dirty };
    });
  };

  onChange = (path, value) => {
    if (this.state.checkpointData)
      this.handleDirty(path, value);

    super.onChange(path, value);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.checkpointData && prevState.checkpointData){
      const prevDirty = !!prevState.dirty.length;
      const dirty = !!this.state.dirty.length;
  
      if (prevDirty !== dirty) {
        this.props.onDirtyChange?.(dirty)
      };
    }

    super.componentDidUpdate(prevProps, prevState);
  }

};