import React from "react";

// ---------------------------------------------------------------------------------------

import AutoRetrievedFields from './AutoRetrievedFields';

// ---------------------------------------------------------------------------------------

import { CheckboxInput } from "./form-inputs/CheckboxInput";
import { DateInput } from "./form-inputs/DateInput";
import { TimeInput } from "./form-inputs/TimeInput";
import { DropdownInput } from "./form-inputs/DropdownInput";
import { FreeTextInput } from "./form-inputs/FreeTextInput";
import { MultipleChoiceInput } from "./form-inputs/MultipleChoiceInput";
import { KeywordInput } from "./form-inputs/KeywordInput";
import { NumberInput } from "./form-inputs/NumberInput";
import { RadioInput } from "./form-inputs/RadioInput";
import { TextInput } from "./form-inputs/TextInput";
import { ColorInput } from "./form-inputs/ColorInput";
import { FormLabel } from "./form-inputs/FormLabel";
import { ArrayInput } from "./form-inputs/ArrayInput";
import { FileInput } from "./form-inputs/FileInput";
import { FloatInput } from "./form-inputs/FloatInput";
import { SliderInput } from "./form-inputs/SliderInput"

// ---------------------------------------------------------------------------------------

// import './styles/form.css';

// ---------------------------------------------------------------------------------------

export class Form extends React.Component {

    static AutoRetrievedFields = AutoRetrievedFields;
    static restrictionChecks = {};
    static textToInputMap = new Map();
    static autoRetrievedFieldGetters = null;

    constructor(props) {
        super(props);

        this.state = {
            readOnly: props.readOnly || false
        }

        this.formID = Math.floor(Math.random() * 100000000);
        this.pendingSetData = React.createRef(0);
        this.inputRefs = {};
        this.labelRefs = {};

        if (props.description.showTitle)
            this.labelRefs[props.description.title] = this;

        this.getQuestion = this.getQuestion.bind(this);
        this.getData = this.getData.bind(this);
        this.setData = this.setData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.scrollIntoView = this.scrollIntoView.bind(this);

        this.formDomReference = this.props.formDomReference || null;

    }

    componentDidUpdate(prevProps) {
        if (prevProps.readOnly !== this.props.readOnly) {
            this.setIsReadOnly(this.props.readOnly);
        }
    }

    setIsReadOnly(val) {
        this.setState({ readOnly: val });
        for (const input in this.inputRefs) {
            if (this.inputRefs[input].valueUpdate)
                this.inputRefs[input].setIsReadOnly?.(val);
        }
    }

    componentDidMount() {
        for (const input in this.inputRefs) {
            if (this.inputRefs[input].valueUpdate)
                this.inputRefs[input].manageVisibilityOfBindedQuestions();
        }
    }

    static setAutoRetrievedFieldGetters(autoRetrievedFieldGetters) {
        Form.autoRetrievedFieldGetters = autoRetrievedFieldGetters;
    }

    getAutoRetrievedFields() {
        const autoRetrievedFields = {};
        const autoRetrievedFieldsInfo = this.props.description.autoRetrievedFields || [];
        for (const f of autoRetrievedFieldsInfo) {
            const func = Form.autoRetrievedFieldGetters[f.getter];
            if (!func) {
                console.log(`There is no valid function '${f.getter}' for an auto fill data`);
                return;
            }
            autoRetrievedFields[f.name] = func();
        }
        return autoRetrievedFields;
    }

    static registerInput(tag, input) {
        Form.textToInputMap.set(tag, input);
    }

    static registerRestrictionCheck({ tag, func, explanation }) {
        Form.restrictionChecks[tag] = {
            tag,
            func,
            explanation: explanation || tag
        };
    }

    isHidden() {
        return this.state.isHidden;
    }

    showQuestion() {
        this.setState({ isHidden: false });
    }

    hideQuestion() {
        this.setState({ isHidden: true });
    }

    setData(data, onFinish) {
        console.assert(!this.pendingSetData.current, this.pendingSetData.current, this.props.description.name);
        this.pendingSetData.current = Object.keys(this.inputRefs).length;

        const decrementPendingSetData = () => {
            if (!--this.pendingSetData.current) {
                onFinish && onFinish();
            }
        };

        if (this.pendingSetData.current === 0) {
            return onFinish && onFinish();
        }

        for (const [inputName, input] of Object.entries(this.inputRefs)) {
            if (data[inputName] === undefined || !input) {
                decrementPendingSetData();
            } else {
                input.setData(data[inputName], decrementPendingSetData);
            }
        }
    }

    getData() {
        const data = {};
        for (const [inputName, input] of Object.entries(this.inputRefs)) {
            if (input.isHidden()) continue;
            data[inputName] = input.getData();
        }
        return {
            ...data,
            ...this.getAutoRetrievedFields()
        };
    }

    getQuestion(questionName) {
        return this.inputRefs[questionName] || false;
    }

    clear() {
        for (const input in this.inputRefs) {
            if (this.inputRefs[input].clear)
                this.inputRefs[input].clear();
        }
    }

    clearSanityCheck() {
        for (const question of this.props.description.questions) {
            if (question.type === 'label') continue;

            const qObj = this.inputRefs[question.name];
            if (qObj.isHidden && qObj.isHidden()) continue;

            if (question.type === 'form-array') {
                qObj.clearSanityCheck();
                continue;
            }

            if (question.type === 'form') {
                qObj.clearSanityCheck();
                continue;
            }
            qObj.setProblem(null);
        }
    }

    sanityCheckForm(skipRequiredFields = false, scroll = false) {
        let failed = [];
        for (const question of this.props.description.questions) {
            if (question.type === 'label') continue;

            const qObj = this.inputRefs[question.name];
            if (qObj.isHidden && qObj.isHidden()) continue;

            if (question.type === 'form-array') {
                failed.push(...qObj.sanityCheckForm(skipRequiredFields));
            }

            if (question.type === 'form') {
                failed.push(...qObj.sanityCheckForm(skipRequiredFields));
            }

            const value = qObj.getData();

            if (!skipRequiredFields && question.isRequired && value === null) {
                qObj.setProblem(`Το πεδίο '${question.title || question.name}' είναι υποχρεωτικό`);
                failed.push({ input: qObj, name: question.name, value: value, reason: 'Required Field!' });
                continue;
            }

            if (value === null) {
                if (!question.isRequired || skipRequiredFields)
                    continue;
            }

            if (question.restrictions) {
                for (const [restrictionName, restrictionValue] of Object.entries(question.restrictions)) {
                    const restrictionCheck = Form.restrictionChecks[restrictionName];

                    console.assert(restrictionCheck);

                    if (!restrictionCheck.func(value, restrictionValue)) {
                        const expl = restrictionCheck.explanation
                            .replace('$fieldname', `'${question.title || question.name}'`)
                            .replace('$res', restrictionValue)
                            .replace('$val', value);
                        this.inputRefs[question.name].setProblem(expl);

                        failed.push({ input: qObj, name: question.name, value: value, reason: `Restriction failed ${restrictionName}` });
                    }
                }
            }
        }

        if (failed.length && scroll) {
            if (typeof scroll === 'function') {
                scroll(failed[0].input.labelRef?.current);
            }
            else failed[0].input.scrollIntoView();
        }

        return failed;
    }

    createInput(description, index, label) {
        const input = Form.textToInputMap.get(description.type);
        if (!input) {
            return <div style={{ color: "red" }}> ERROR: '{description.type}' not found </div>;
        }

        const initialValue = this.props.initialValue?.[description.name];

        const inputElement = React.createElement(input, {
            description, index, label, initialValue, getQuestion: this.getQuestion, onChange: this.onChange, readOnly: this.state.readOnly, ref: ref => {
                if (description.type !== 'label') {
                    this.inputRefs[description.name] = ref;
                } else {
                    this.labelRefs[description.name] = ref;
                }
            }
        });

        return inputElement;

    }

    getLabels() {
        let s = {};
        for (const question of this.props.description.questions) {
            const qObj = this.inputRefs[question.name];

            if (question.type !== 'form-array' && question.type !== 'form') {
                continue;
            }
            s = Object.assign(s, qObj.getLabels());
        }
        return { ...this.labelRefs, ...s };
    }

    formToRender() {
        const inputItems = this.props.description.questions.map((q, i) => {
            const uid = Math.floor(Math.random() * 10000000);
            const showTitle = (q.type !== 'form' && q.type !== 'form-array');
            //TODO: rewrite the code below !
            let space = '100%';
            const label = showTitle && <label className={`${q.type}-title`} htmlFor={`${q.name}_${uid}`} title={q.tooltip || ''}>{q.title || q.name}{q.isRequired && ' *'}{q.tooltip ? ' (?)' : ''}</label>;
            if (q.space) space = q.space;
            if (this.state.readOnly) space = '50%';
            return <div key={`${q}_${i}`} className={`${q.type}-input form-general-input${this.state.readOnly ? '-readOnly' : ''}`} style={{ flex: `calc(${space} - 20px)` }}>
                {this.createInput(q, uid, label)}
            </div>
        });

        return inputItems;
    }

    onChange(path, val) {
        this.props.onChange && this.props.onChange(this.props.description.name + '/' + path, val);
    }

    scrollIntoView() {
        document.getElementById(`form-${this.formID}`).scrollIntoView();
    }

    render() {

        if (this.state.isHidden) {
            return null;
        }

        this.inputRefs = {};

        const readOnlyFormClassName = this.state.readOnly ? 'form-readOnly' : 'form';

        return <div ref={this.formDomReference} className={`${readOnlyFormClassName} form-${this.props.description.name}`} id={`form-${this.formID}`} onKeyPress={this.props.onEnter || null}>
            {this.props.description.showTitle && <label className="subform-title">{this.props.description.title}</label>}
            {this.formToRender()}
        </div>

    }
};

// ---------------------------------------------------------------------------------------

Form.registerInput('form', Form);
Form.registerInput('checkbox', CheckboxInput);
Form.registerInput('date', DateInput);
Form.registerInput('time', TimeInput);
Form.registerInput('dropdown', DropdownInput);
Form.registerInput('label', FormLabel);
Form.registerInput('freetext', FreeTextInput);
Form.registerInput('multiple-choice', MultipleChoiceInput);
Form.registerInput('keyword', KeywordInput);
Form.registerInput('number', NumberInput);
Form.registerInput('float', FloatInput);
Form.registerInput('radio', RadioInput);
Form.registerInput('text', TextInput);
Form.registerInput('color', ColorInput);
Form.registerInput('form-array', ArrayInput);
Form.registerInput('file', FileInput);
Form.registerInput('slider', SliderInput);

// ---------------------------------------------------------------------------------------

Form.setAutoRetrievedFieldGetters(AutoRetrievedFields);