import React, { useEffect, useState } from "react";
import styles from "../styles/TopBar.module.css";
import logo from "../assets/images/logoINE.png";
import { Profile } from "./Profile";
import { usePageInfo } from "./PageInfoWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { usePageSize } from "./withPageSize";
import { GiHamburgerMenu } from 'react-icons/gi';

function TopBar(props) {
  const { getPageInfo, getNavOptions } = usePageInfo();
  const navigate = useNavigate();
  const width = usePageSize();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (menuIsOpen) setMenuIsOpen(false);
  }, [location.key]);

  const goToHome = () => {
    navigate('/');
  }

  const toggleMenu = () => {
    setMenuIsOpen(open => !open);
  };

  const select = (option) => {
    option.onClick && option.onClick();
  };
  const options = getNavOptions().map(
    (option, i) => {
      const { Icon, text } = option;

      return (
        <div
          key={i}
          className={
            option.selected ?
              `${styles.option} ${styles.selected}` :
              `${styles.option}`
          }
          onClick={() => select(option)}
        >
          <Icon className={styles.icon} />
          <div className={styles.text}> {text} </div>
        </div>
      );
    }
  )

  return (
    <div className={styles["top-bar"]}>
      <GiHamburgerMenu className = {width <= 1000 && !!options.length ? styles.handle : `${styles.hidden} ${styles.handle}`} onClick={toggleMenu} />
      {width <= 1000 && menuIsOpen && <div className={styles.options}>{options}</div>}
      <div onClick={goToHome} className={styles["logo"]}>
        <img src={logo} alt="INE logo" />
      </div>
      <div className={styles["title"]}>{getPageInfo()}</div>

      <Profile />
    </div>
  );
}

export default TopBar;
