import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Navigate, Route, Routes } from "react-router-dom";
import BusinessRegistryAllRankings from "./BusinessRegistryAllRankings";
import BusinessRegistryAppInfo from "./BusinessRegistryAppInfo";

function BusinessRegistryRankings() {
    return (
        <Routes>
            <Route path="/all" element={<BusinessRegistryAllRankings />} />
            <Route path="/:appId" element={<BusinessRegistryAppInfo />} />
            <Route path="/" element={<Navigate to="all" />} />
            <Route path="/*" element={<Navigate to="/error404" />} />
        </Routes>
    );
}

export default BusinessRegistryRankings;
