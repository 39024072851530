import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ClientWrapper } from "../components/ClientWrapper";
import Footer from "../components/Footer";
import { PageInfoWrapper } from "../components/PageInfoWrapper";
import { ToastMessagesWrapper } from "../components/ToastMessageWrapper";
import TopBar from "../components/TopBar";
import { UserWrapper } from "../components/UserWrapper";
import { PageSizeWrapper } from "../components/withPageSize";

export default function MainPage({ children }) {
  return (
    <PageInfoWrapper>
      <ClientWrapper>
        <UserWrapper>
          <ToastMessagesWrapper>
            <PageSizeWrapper>
              <Router>
                <Routes>
                  <Route
                    path="/*"
                    element={
                      <>
                        <div className="whole-page">
                          <TopBar />
                          <div className="content-wrap"> {children} </div>
                        </div>
                        <Footer />
                      </>
                    }
                  />
                </Routes>
              </Router>
            </PageSizeWrapper>
          </ToastMessagesWrapper>
        </UserWrapper>
      </ClientWrapper>
    </PageInfoWrapper>
  );
}
