import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

import DatePicker from "react-datepicker";
import el from 'date-fns/locale/el';
import "react-datepicker/dist/react-datepicker.css";

// ---------------------------------------------------------------------------------------

import React from "react";

// ---------------------------------------------------------------------------------------

const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
        <div className="readOnly-value-wrap" onClick={onClick} ref={ref}>
            {value}
        </div>
    );
});

export const DateTransformations = {
    ISO: {
        dateToString: date => date.toISOString(),
        stringToDate: date => new Date(date)
    },
    SQL_TIMESTAMP: {
        dateToString: date => date.toISOString().slice(0, 19).replace('T', ' '),
        stringToDate: date => {
            //sql retrieves ISO format dates but does not expect ISO format dates...
            if (date[date.length - 1] !== 'Z')
                return new Date(date.replace(' ', 'T') + '.000Z');
            else
                return new Date(date);
        }
    }
}

export class DateInput extends Input {

    static dateTransformations = DateTransformations.SQL_TIMESTAMP;

    static setDateTransformations = (dateTransformations) => {
        DateInput.dateTransformations = dateTransformations;
    }

    constructor(props) {
        super(props);

        if (this.state.value){
            this.state.value = DateInput.dateTransformations.dateToString(
                DateInput.dateTransformations.stringToDate(this.state.value)
            );
        }

        this.onChange = this.onChange.bind(this);
    }

    setData(data, onFinish) {
        this.onChange(data === null? null: new Date(data), onFinish);
    }

    onChange(date, onFinish) {
        if (date && date instanceof Date)
            date = DateInput.dateTransformations.dateToString(date);

        this.valueUpdate(this.state.value, date);
        this.setState({ value: date }, onFinish);
    }

    readOnlyRender() {
        return (
            <>
                {this.props.label}
                {
                    this.state.value ?
                        <div className="readOnly-value-wrap">
                            {this.createDateInput()}
                        </div>
                        :
                        <div className="readOnly-value-wrap"> - </div>
                }
            </>
        );
    }

    createDateInput() {
        const customInput = this.props.readOnly ? <CustomInput /> : undefined;

        return (
            <DatePicker
                readOnly={this.props.readOnly || this.props.description.isDisabled}
                customInput={customInput}

                selected={
                    this.state.value ?
                        DateInput.dateTransformations.stringToDate(this.state.value)
                    :
                        null
                }
                onChange={(date) => this.onChange(date)}
                placeholderText={this.props.description.placeholder || 'Επιλογή...'}

                withPortal={!!this.props.description.withPortal}
                showTimeSelect={!!this.props.description.withTime}
                showYearDropdown={!!this.props.description.withYearDropdown}
                
                dateFormat={
                    this.props.description.withTime ?
                        "eeee dd MMMM yyyy, h:mm aa" :
                        "eeee dd MMMM yyyy"
                }
                timeIntervals={this.props.description.timeIntervals || 30}
                timeCaption="Ώρα"

                className={
                    this.props.description.isDisabled ?
                        `form-item-input disabled`
                    :
                        `form-item-input`
                }
                popperClassName="form-date-input-popper"
                dayClassName={() => "form-date-input-day"}
                timeClassName={() => "form-date-time"}
                calendarClassName="form-date-input-calendar"
                locale={el}
            />
        )
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        return (
            <>
                <div ref={this.labelRef} className={`form-item-input-title${this.state.problem ? '-error' : ''}`}>{this.props.label}</div>
                {
                    <div>
                        { this.createDateInput() }
                    </div>
                }
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }

};

// ---------------------------------------------------------------------------------------