import { useUser } from "./UserWrapper";

export const ProtectRoute = (
  {
    children,
    permissions,
    LoginPage,
    Error403Page
  }
) => {
  const {user} = useUser();

  if (!user) return LoginPage;

  if (permissions && !user[permissions]) return Error403Page;
  
  return children;
}