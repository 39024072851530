import traineeApplicationJson from "../../../assets/data/trainee-application.json";
import { ApplicationForm } from "../../../components/ApplicationPageUtils/ApplicationForm"
import { useEffect, useState } from "react";
import { Form } from "../../../react-form/src/Form";
import { usePageSize } from "../../../components/withPageSize";

const gradingInfo = {
  iekGrade: ["iekGrade", "iekGradeFile"],
  salaryCriteria: ["hasSalaryCriteria", "salaryCriteria", "salaryCriteriaFile"],
  unemployementCard: ["hasUnemployementCard", "unemployementCardFile"]
};

export const TraineeApplicationForm = ({
  application,
  formRef,
  readOnly,
  onDirtyChange
}) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (application) {
      const description = JSON.parse(JSON.stringify(traineeApplicationJson));

      // grading criterias
      const gradingCriterias = Object.keys(gradingInfo);
      const program = application["program"];

      if (gradingCriterias.every(criteria => !program.gradingInfo[criteria])) {
        description.questions = description.questions.filter(q => q.name !== "gradingInfo");
      }
      else {
        const descriptionGradingInfo = description.questions.find(q => q.name === "gradingInfo");

        descriptionGradingInfo.questions = descriptionGradingInfo.questions.filter(question =>
          gradingCriterias.some(criteria =>
            gradingInfo[criteria].includes(question.name) &&
            program.gradingInfo[criteria] === true
          )
        );
      }

      // theoreticalIekCompletionDate
      if (program.information.includeTraineeTheoreticalIekCompletionDate !== true) {
        const descriptionIekInfo = description.questions.find(q => q.name === "iekInfo");

        descriptionIekInfo.questions = descriptionIekInfo.questions.filter(
          question => question.name !== "theoreticalIekCompletionDate"
        );
      }
      else {
        const maxCompletionDate = new Date(program.information.theoreticalIekCompletionDate);
        const maxCompletionDateStr = maxCompletionDate.toLocaleDateString(
          "el-GR", { dateStyle: "long" }
        );

        Form.registerRestrictionCheck({
          tag: "validateTheoreticalIekCompletionDate",
          func: (v) => {
            return v && (new Date(v)) <= maxCompletionDate;
          },
          explanation:
            `Η μέγιστη επιτρεπτή ημερομηνία ολοκλήρωσης θεωρητικής κατάρτισης είναι η ${maxCompletionDateStr}`
        })
      }

      setDescription(description);
    }
  }, [application]);
    
  if (!description) return null;

  return (
    <ApplicationForm
      formRef={formRef}
      description={description}
      onDirtyChange={onDirtyChange}
      application={application}
      readOnly={readOnly}
      dependancies={[]}
      DownloadFileUrl={'/trainee/application/file/download'}
    />
  );

}