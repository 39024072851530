import React, { useEffect, useState } from "react";
import styles from "../styles/Panel.module.css";
import LoaderWrapper from "./LoaderWrapper";

export const Panel = ({ headerBar, children, footerBar, contentIsScrollable }) => {


  return (
    <div className={styles.panel}>
      {
        headerBar &&
        <div className={styles.headerBar}>
          {headerBar}
        </div>
      }

      <div
        className={styles.content}
        style={
          contentIsScrollable ? { overflow: 'auto' } : {}
        }
      >
        {children}
      </div>

      <div className={styles.loaderContainer}>
        <LoaderWrapper loading={true} size={'75'} thickness={'7.5px'}></LoaderWrapper>
      </div>

      {
        footerBar &&
        <div className={styles.footerBar}>
          {footerBar}
        </div>
      }
    </div>
  );
}