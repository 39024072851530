export const download = (name, blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.style.display = 'none';
  a.href = url;
  a.download = name;

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a); 
}

export const download_WithFormRequest = (url, body) => {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('target', '_blank');
  form.setAttribute('action', url);

  for (let key in body){
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.value = body[key];

    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  form.remove();
}