import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { usePageInfo } from "../../components/PageInfoWrapper";
import { AllPrograms } from "./AllPrograms";
import { CreateProgram } from "./CreateProgram";
import { ViewProgram } from "./ViewProgram";

export const ProgramManagement = () => {

  return (
    <Routes>
      <Route path="/all-programs" element={<AllPrograms />} />
      <Route path="/create-program" element={<CreateProgram />} />
      <Route path="/view-program/:id" element={<ViewProgram />} />
      <Route path="/" element={<Navigate to="all-programs" />} />
      <Route path="/*" element={<Navigate to="/error404" />} />
    </Routes>
  );
};
