import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useClient } from '../../../components/ClientWrapper';
import LoaderWrapper from '../../../components/LoaderWrapper';
import { usePageInfo } from '../../../components/PageInfoWrapper';
import { Panel } from '../../../components/Panel'
import { _ } from "gridjs-react";
import ProgramDropdown from '../../../components/ProgramDropdown';
import styles from '../../../styles/Business/BusinessRegistryAllArchived.module.css'
import { IoOpenOutline } from "react-icons/io5";
import GridWithFilters from '../../../components/GridWithFilters';
import { MdGppGood, MdGppBad, MdPending } from "react-icons/md";
import { file2csv, generateApplicantsCsv } from '../../../lib/csv';
import businessApplicationJson from '../../../assets/data/business-application.json';
import { localeDateStringWithTime } from '../../../util/util';

const STATUS = {
    success: "Αποδεκτή",
    failure: "Απορριπτέα",
    pending: "Εκκρεμεί",
};

const columns = [
    {
        id: "id",
        name: "id",
        hidden: true,
    }, {
        id: "kayas",
        name: "ΚΑΥΑΣ",
    },
    {
        id: "businessname",
        name: "Επωνυμία",
    },
    {
        id: "email",
        name: "Email",
    },
    {
        id: "afm",
        name: "Α.Φ.Μ",
    },
    {
        id: "status",
        name: "Έλεγχος",
        filterOptions: Object.keys(STATUS).map((key) => ({
            label: STATUS[key],
            value: STATUS[key],
        })),
    },
    {
        id: "finalizeDate",
        name: "Οριστικοποίηση",
    },
    {
        id: "open",
        width: 30,
        sort: false,
    },
];

const language = {
    search: {
        placeholder: "Αναζήτηση",
    },
    pagination: {
        previous: "Προηγούμενο",
        next: "Επόμενο",
        showing: "Προβάλλονται οι Αιτήσεις",
        to: "-",
        of: "από",
        results: () => "",
    },
    noRecordsFound: "Δεν βρέθηκαν αιτήσεις",
};

function Status({ status }) {
    const Icon =
        status === STATUS.success ? (
            <MdGppGood className={styles.success} />
        ) : status === STATUS.failure ? (
            <MdGppBad className={styles.failure} />
        ) : (
            <MdPending className={styles.pending} />
        );
    return (
        <div className={styles.statusContainer}>
            {Icon}
            {status}
        </div>
    );
}

function BusinessRegistryAllArchived() {
    const [applicants, setApplicants] = useState();
    const [applicantsCsv, setApplicantsCsv] = useState({ data: [] });
    const axios = useClient();
    const navigate = useNavigate();

    const openIndex = columns.findIndex(col => col.id === "open");
    const statusIndex = columns.findIndex(col => col.id === "status");
    columns[openIndex].formatter = (cell, row) => {
        return _(
            <div className={styles.openButtonContainer}>
                <IoOpenOutline
                    className={styles.openButton}
                    onClick={() => navigate(`../${row.cells[0].data}`)}
                />
            </div>
        );
    };

    columns[statusIndex].formatter = (cell, row) => {
        return _(<Status status={cell} />);
    };

    function getApplications(program = undefined) {
        if (!program) return;
        setApplicants(null);

        axios
            .get(`/registry/business/getApplications?isFinalized=${true}&programId=${program._id}`)
            .then((res) => {
                const filteredApps = res.data.map((app) => { app = { ...app.businessInfo, ...app }; return app; })

                const extractResults = () => {
                    return filteredApps.map(
                        ({
                            _id,
                            kayas,
                            basicInfo: { name, taxId },
                            representative: { email },
                            isEvaluated,
                            evaluation,
                            finalizeDate
                        }) => [
                                _id,
                                kayas,
                                name,
                                email,
                                taxId,
                                isEvaluated
                                    ? evaluation.accepted
                                        ? STATUS.success
                                        : STATUS.failure
                                    : STATUS.pending,
                                localeDateStringWithTime(finalizeDate)
                            ]
                    );
                }
                setApplicantsCsv(generateApplicantsCsv(filteredApps, businessApplicationJson, file2csv('business')));
                setApplicants(extractResults());
            })
            .catch((err) => {
                console.log(err.response);
            });
    }

    const onNoPrograms = () => setApplicants([])

    return (
        <Panel headerBar="Αρχειοθετημένες Αιτήσεις">
            <div className={styles.appsContainer}>
                <ProgramDropdown onNoPrograms={onNoPrograms} onChange={getApplications} isCompleted={true} />
                <LoaderWrapper size={"75"} thickness={"7.5px"} loading={!applicants}>
                    {
                        applicants &&
                        <div className={styles.grid}>
                            <GridWithFilters
                                dataInit={applicants} //to keep track with gridjs private vars...
                                data={applicants}
                                columns={columns}
                                search={true}
                                sort={true}
                                pagination={{ limit: 13 }}
                                language={language}
                                csv={{
                                    ...applicantsCsv,
                                    filename: 'Αιτήσεις Επιχειρήσεων'
                                }}
                            />
                        </div>
                    }
                </LoaderWrapper>
            </div>
        </Panel>
    );
}

export default BusinessRegistryAllArchived