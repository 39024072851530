import { useClient } from "./ClientWrapper";
import React, { useEffect, useState } from "react";
import styles from "../styles/PositionInfo.module.css";
import { VscTriangleRight } from "react-icons/vsc";
function PositionInfo({ id, position: position_ }) {
    const axios = useClient();
    const [position, setPosition] = useState(position_);

    const getPosition = () => {
        axios
            .get(`/registry/trainee/position/${id}`)
            .then((res) => {
                console.log(res.data);
                setPosition(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (id && !position_){
            getPosition();
        }
    }, []);

    const positionDescription = () => {
        if (parseInt(position?.askedCount) === 1)
            return (<>Προσφερόμενη Θέση</>);
        return (<>Προσφερόμενες Θέσεις</>);
    }

    if (!position) return null;

    const address = position.useDefaultAddress ?
                        position.businessApplication.businessInfo.address
                    :
                        position.address
                    ;


    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <div>{position.businessApplication.businessInfo.basicInfo.name}</div>
                <div> {position.askedCount} {positionDescription()}</div>
            </div>
            <div className={styles.specialities}>
                {position.iekSpecialities.map((spec, i) =>
                    <div className={styles.speciality} key={i}>
                        <VscTriangleRight />{spec}
                    </div>
                )}
            </div>
            <div className={styles.address}>
                <div className={styles.content}>
                    {"Περιφέρεια "} {address.area}
                </div>
                <div className={styles.content}>
                    {address.municipality}
                </div>
                <div className={styles.content}>
                    {address.city}
                </div>
                <div className={styles.content}>
                    {`${address.street} `}
                    {address.streetNumber}
                </div>
            </div>
        </div>
    );
}

export default PositionInfo;
