import React, { useEffect, useRef, useState } from "react";
import styles from "../../../styles/Trainee/TraineeStatus.module.css";
import { Panel } from "../../../components/Panel";
import { useClient } from "../../../components/ClientWrapper";
import { Steps } from "../../../components/Steps";
import { Link } from "react-router-dom";
import { BsCheckCircleFill, BsDashCircleFill, BsInfoCircleFill } from "react-icons/bs";

const stepsInitial = [
  "Δημιουργία Αίτησης",
  "Έλεγχος Αίτησης"
];

const StatusMessage = ({ date, type, children }) => {
  const Icon = (type === 'success') ?
    <BsCheckCircleFill className={styles.check} /> :
    (type === 'failure') ?
      <BsDashCircleFill className={styles.dash} /> :
      <BsInfoCircleFill className={styles.info} />
    ;

  const formatDate = (date) => {
    return (new Date(date)).toLocaleDateString();
  }

  const formatTime = (date) => {
    return (new Date(date)).toLocaleTimeString();
  }

  return (
    <div className={styles.statusContainer}>
      <div className={styles.left}>
        {Icon}
        {formatDate(date)}
        <div className={styles.time}>{formatTime(date)}</div>
      </div>

      <div className={styles.right}>
        {children}
      </div>
    </div>
  );

};

const Message_Editing_Current = () => {
  return (
    <div>
      <div>
        Συντάσετε ακόμα την αίτηση σας. Για αποστολή της αίτησης στον φορέα και
        αξιολόγηση της, προχωρήστε σε οριστικοποίηση από την&nbsp;
        <Link className="link" style={{ display: 'inline-flex' }} to='/trainee/application'>
          καρτέλα της αίτησης σας.
        </Link>
      </div>
    </div>
  )
};
const Message_Editing_Success = ({ application }) => {
  return <div > Η αίτηση σας έχει οριστικοποιηθεί με αριθμό (ΚΑΥΑΣ) <div className={styles.bolded}>{application?.kayas}</div></div>
}

const Message_Evaluation = ({ success, comment }) => {
  const message = success ?
    'Η αίτηση σας έγινε αποδεκτή.' :
    'Η αίτηση σας απορρίφθηκε κατά τη διαδικασία αξιολόγησης.'
    ;

  return (
    <div style={{ width: '100%' }}>
      <div> {message} </div>
      {comment &&
        <div className={styles.commentContainer}>
          <div className={styles.commentTitle}>
            <div> Σχόλιο Αξιολόγησης </div>
          </div>
          <div className={styles.commentContent}> {comment} </div>
        </div>
      }
    </div>
  );
}
const Message_Evaluation_Current = () => {
  return (
    <div>
      <div> Παρακαλούμε σημειώστε τον παρακάτω Κωδικό Αριθμό Υποβολής Αίτησης Συμμετοχής (ΚΑΥΑΣ) γιατί θα τον χρειαστείτε για κάθε μελλοντική αναφορά στην αίτησή σας.
        Πριν την ένταξή σας στο πρόγραμμα κατάρτισης θα πραγματοποιηθεί έλεγχος των δικαιολογητικών που έχετε αναρτήσει, από τον πάροχο κατάρτισης και σε περίπτωση αρνητικού ελέγχου, η αίτησή σας θα απορριφθεί.</div>
    </div>
  );
}
const Message_Evaluation_Success = ({ application }) => {
  return application && <Message_Evaluation success={true} comment={application.evaluation.comment} />
}
const Message_Evaluation_Failure = ({ application }) => {
  return application && <Message_Evaluation success={false} comment={application.evaluation.comment} />
}

export const TraineeStatus = () => {
  const [currStage, setCurrStage] = useState(null);
  const axios = useClient();
  const [application, setApplication] = useState(null);

  const messages = useRef([
    {
      success: Message_Editing_Success,
      current: Message_Editing_Current
    },
    {
      success: Message_Evaluation_Success,
      current: Message_Evaluation_Current,
      failure: Message_Evaluation_Failure
    },
  ]);

  const progressUntilStage = (curr, currState, comment) => {
    setCurrStage({ i: curr, state: currState, comment });
  };

  useEffect(() => {
    getApplication();
  }, []);

  const getApplication = () => {
    axios
      .get(`/trainee/application/`)
      .then((res) => {
        const application = res.data.application || {};

        if (application.isFinalized)
          messages.current[0].date = application.finalizeDate;

        if (application.isEvaluated)
          messages.current[1].date = application.evaluation.date;

        if (application.isEvaluated)
          (application.evaluation.accepted) ?
            progressUntilStage(1, 'success') :
            progressUntilStage(1, 'failure')
            ;
        else
          progressUntilStage(
            application.isFinalized ? 1 : 0, "current"
          );

        setApplication(application);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CurrMessage = currStage &&
    currStage.state === 'current' &&
    messages.current[currStage.i][currStage.state];

  return (
    <Panel headerBar="Κατάσταση Αίτησης">
      {
        currStage !== null &&
        <div className={styles.wholeContent}>
          <div className={styles.container}>
            <Steps
              steps={stepsInitial}
              currStep={currStage.i}
              currStepState={currStage.state}
            />

            {
              CurrMessage &&
              <div className={styles.currentMessage}>
                <CurrMessage />
              </div>
            }

            <div className={styles.stati}>
              {
                messages.current.map((message, i) => {
                  let messageType;

                  if (i < currStage.i)
                    messageType = 'success';
                  else if (i === currStage.i && currStage.state !== 'current')
                    messageType = currStage.state;
                  else
                    return null;

                  const Message = message[messageType];

                  return (
                    <StatusMessage key={i} date={message.date} type={messageType}>
                      <Message application={application} />
                    </StatusMessage>
                  );

                }).reverse()
              }
            </div>
          </div>
        </div>
      }
    </Panel>
  );
};
