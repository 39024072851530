import { useState, useRef, useCallback } from "react";
import { AcceptRejectModal } from "../AcceptRejectModal";
import ButtonWithLoading from "../ButtonWithLoading";
import { useClient } from "../ClientWrapper";
import { useToasts } from "../ToastMessageWrapper";

export const RegistryEvaluationButton = ({
  formRef,
  appId,
  evaluateUrl
}) => {
  const addToast = useToasts();
  const buttonRef = useRef();
  const axios = useClient();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const submit = async e => {
    formRef.current.clearSanityCheck();

    const isFormFilled = !formRef.current.sanityCheckForm().length;

    if (isFormFilled) {
      const formData = formRef.current.getData();

      const body = {
        accepted: formData.result === "success",
        comment: formData.comment,
        applicationId: appId,
      };

      try {
        var res = await axios.put(evaluateUrl, body);
      }
      catch (err) {
        throw "Υπήρξε κάποιο πρόβλημα με την καταχώρηση της αξιολόγησης σας. Πιθανόν δεν είστε ο διαχειριστής αυτής της αίτησης.";
      }
    }
    else throw "Η αξιολόγηση σας δεν οριστικοποιήθηκε καθώς δεν είναι συμπληρωμένα όλα τα πεδία."

    return res;
  }

  const onSubmitSuccess = useCallback(res => {
    addToast({
      type: "success",
      message: "Η αξιολόγηση καταχωρήθηκε.",
      duration: 3000,
    });
  }, [])

  const onSubmitFailure = useCallback(err => {
    addToast({
      type: "failure",
      message: err,
      duration: 5000,
    });
  }, []);

  const onClose = () => {
    setModalIsVisible(false);
  }

  return (
    <>
      <ButtonWithLoading
        onClick={() => setModalIsVisible(true)}
        ref={buttonRef}
      >
        Αποστολή Αξιολόγησης
      </ButtonWithLoading>
      {
        modalIsVisible &&
        <AcceptRejectModal
          title='Επιβεβαίωση Αξιολόγησης'
          message='Αν συνεχίσετε το αποτέλεσμα θα είναι ορατό στον χρήστη που υπέβαλε την αίτηση,
          ωστόσο μέχρι την λήξη της προθεσμίας αιτήσεων μπορείτε να το
          τροποποιήσετε.'
          continueButtonText='Επιβεβαίωση Αξιολόγησης'
          submit={submit}
          onSubmitFailure={onSubmitFailure}
          onSubmitSuccess={onSubmitSuccess}
          onClose={onClose}
        />
      }
    </>
  );
}