import styles from '../../styles/Program.module.css';
import { useEffect, useState } from 'react';

const Program = ({ program }) => {
  return (
    <div className={styles.info}>
      {
        program.information.areas.length > 1 ?
          <div className={styles.title}>
            Αίτηση συμμετοχής για υλοποίηση πρακτικής άσκησης στις περιφέρειες {program.information.areas.join(', ')} - {program.information.carrier.title}
          </div> :
          <div className={styles.title}>
            Αίτηση συμμετοχής για υλοποίηση πρακτικής άσκησης στην περιφέρεια {program.information.areas[0]} - {program.information.carrier.title}
          </div>
      }
    </div>
  )
}

export const ProgramSelection = ({ programs, onProgramSelection }) => {
  const [selected, setSelected] = useState(null);

  const onSelect = i => {
    setSelected(i);
    onProgramSelection?.(programs[i]);
  }

  useEffect(() => {
    onSelect(0);
  }, []);

  if (!programs) return null;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        Επιλέξτε την περιφέρεια που επιθυμείτε να υλοποιήσετε πρακτική άσκηση.
        <br />Στη συνέχεια θα σας ζητηθούν περαιτέρω στοιχεία και τα απαραίτητα δικαιολογητικά για την υποβολή της συμμετοχής σας στο πρόγραμμα.
      </div>
      <div className={styles.programs}>
        {
          programs.map((program, i) =>
            <div key={i} className={styles.program} onClick={() => onSelect(i)}>
              <div
                className={`${styles.checkbox} ${i === selected ? styles.checked : ''}`}
              />
              <Program program={program} />
            </div>
          )
        }
      </div>
    </div>
  );
};
