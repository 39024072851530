import React, { Component } from "react";

export class Input extends Component {

    constructor(props) {
        super(props);

        this.labelRef = React.createRef();

        this.placeholder = this.props.description.placeholder || '';

        const value =   props.initialValue !== undefined ?
                            props.initialValue:
                        props.description.value !== undefined ?
                            props.description.value:
                        null
        ;

        this.state = { value, problem: null };
    }

    valueUpdate(oldValue, val = this.getData()) {
        this.props.onChange(this.props.description.name, val);
        
        const bindQuestions = this.props.description.bindQuestions;
        if(!bindQuestions) return;

        const questionsToHide = bindQuestions[oldValue] || [];
        const questionsToShow = bindQuestions[val] || [];
        
        this.applyToQuestions(questionsToHide, 'hideQuestion');
        this.applyToQuestions(questionsToShow, 'showQuestion');
        
    }
    
    applyToQuestions(questionNames, methodName, args = {}) {
        for(const questionName of questionNames) {
            const question = this.props.getQuestion(questionName);
            question && question[methodName](args);
        }
    }

    manageVisibilityOfBindedQuestions() {
        const bindQuestions = this.props.description.bindQuestions || [];
        for(const [ , questions ] of Object.entries(bindQuestions)) {
            this.applyToQuestions(questions, 'hideQuestion', { clearValue: false });
        }
        this.valueUpdate();
    }

    clear() {
        this.setState({ value: null });
    }

    readOnlyRender() {
        return null;
    }

    isHidden() {
        return this.state.isHidden;
    }

    showQuestion() {
        this.setState({ isHidden: false });
    }

    hideQuestion({ clearValue = true }) {
        const newVal = (clearValue)? null: this.state.value;
        !newVal && this.valueUpdate(this.state.value, null);
        this.setState({ 
            value: newVal,
            isHidden: true 
        });
    }
    
    setProblem(problem) {
        this.setState({ problem });
    }

    setData(data, onFinish) {
        this.onChange({
            target: {
                value: data
            }
        }, onFinish);
    }

    getData() {
        if(this.state.value === '') return null;
        return this.state.value;
    }

    scrollIntoView() {
        this.labelRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

}