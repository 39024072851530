import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useClient } from "../../../components/ClientWrapper";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Business/BusinessRegistryArchivedDetails.module.css";
import { IoIosArrowBack } from "react-icons/io";
import { BusinessApplicationForm } from "../BusinessApplication/BusinessApplicationForm";

function BusinessRegistryArchivedDetails() {
    const { appId } = useParams();
    const axios = useClient();
    const [app, setApplication] = useState(null);

    const applicationFormRef = useRef();

    const getApplication = () => {
        axios
            .get(`/business/application/${appId}`)
            .then((res) => {

                res.data.application.programInfo = { program: res.data.application?.program._id };

                setApplication(res.data.application || {});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getApplication();
    }, []);

    return (
        <Panel headerBar="Αρχειοθετημένες Αιτήσεις">
            {
                app &&
                <div>
                    <div className={styles.backButton}>
                        <Link className="link" to="../all">
                            <IoIosArrowBack></IoIosArrowBack>
                            Πίσω
                        </Link>
                        <h2> Αίτηση της επιχείρησης {app.basicInfo.name} </h2>
                    </div>
                    <BusinessApplicationForm
                        application={app}
                        formRef={applicationFormRef}
                        readOnly={true}
                    />
                </div>
            }
        </Panel>
    );
}

export default BusinessRegistryArchivedDetails;
