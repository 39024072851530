import React from "react";
import styles from "../styles/Footer.module.css";

import logoIne from "../assets/images/logoINE.png";
import logoIme from "../assets/images/logoIme.png";
import logoKanep from "../assets/images/logoKanep.png";
import logoKaele from "../assets/images/logoKaele.png";
import logoEOPPEP from "../assets/images/logoEOPPEP.png";

import projectLogo from "../assets/images/projectLogo.jpg";

import { useState } from "react";

import Modal from "./Modal";

const ContactInfoButton = ({ name, content }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);

  return (
    <>
      <button className={`${styles.contactInfoButton} link`} onClick={() => setModalIsVisible(true)}>
        {name}
      </button>
      {
        modalIsVisible &&
        <Modal
          header='Πληροφορίες Επικοινωνίας'
          content={content}
          onClose={() => setModalIsVisible(false)}
        />
      }
    </>
  );
}

const ContactModalSegment = ({ title, address, members }) => {
  return (
    <div>
      <div> <b> {title} </b> </div>
      <br />
      {
        address &&
        (
          <>
            <div> Δ/νση: {address.name} </div>
            <div> Τ.Κ. {address.tk} </div>
            <br />
          </>
        )
      }
      {
        members.map((member, i) =>
          <div key={i}>
            <div> {member.name} </div>
            <div> Τηλ: {member.phone} </div>
            <div> E-mail: <a href={`mailto: ${member.email}`}> {member.email} </a> </div>
            <br />
          </div>
        )
      }
    </div>
  );
}

const contactInfoMap = {
  "ΙΝΕ / ΓΣΕΕ": [
    {
      name: "Λιγότερο Αναπτυγμένες Περιφέρειες (ΛΑΠ) – Ανατολική Μακεδονία / Θράκη, Δυτική Ελλάδα",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΙΝΕ / ΓΣΕΕ Ξάνθη'
            address={{
              name: 'Γεωργίου Κονδύλη & Ακρίτα',
              tk: '67100'
            }}
            members={[
              {
                name: 'Μαρία Κώστογλου',
                phone: '25410 64448/84385',
                email: 'emacedoniathrace@reg.inegsee.gr'
              }
            ]}
          />
          <ContactModalSegment
            title='ΙΝΕ / ΓΣΕΕ Πάτρα'
            address={{
              name: 'Κολοκοτρώνη 20, 6ος όροφος',
              tk: '26221'
            }}
            members={[
              {
                name: 'Κατερίνα Μπάρλου',
                phone: '2610 226347/624755',
                email: 'westgreece@reg.inegsee.gr'
              }
            ]}
          />
        </div>
      )
    },
    {
      name: "Περιφέρειες σε Μετάβαση (ΜΕΤ) – Δυτική Μακεδονία, Βόρειο Αιγαίο",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΙΝΕ / ΓΣΕΕ Κοζάνη'
            address={{
              name: 'Καμβουνίων 24',
              tk: '50100'
            }}
            members={[
              {
                name: 'Χριστίνα Κυριακίδη',
                phone: '24610 49780',
                email: 'westmacedonia@reg.inegsee.gr'
              }
            ]}
          />
          <ContactModalSegment
            title='ΙΝΕ / ΓΣΕΕ Χίος'
            address={{
              name: 'Μαρτύρων 6',
              tk: '82100'
            }}
            members={[
              {
                name: 'Σταματία Πολίτη',
                phone: '22710 23550',
                email: 'northaegean@reg.inegsee.gr'
              }
            ]}
          />
        </div>
      )
    },
    {
      name: "Περισσότερο Αναπτυγμένες Περιφέρειες (ΠΑΠ) – Νότιο Αιγαίo",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΙΝΕ / ΓΣΕΕ Ρόδος'
            address={{
              name: 'Γ. Μαύρου 7',
              tk: '85100'
            }}
            members={[
              {
                name: 'Γιώργος Γιαννακάκης',
                phone: '22410 70408',
                email: 'southaegean@reg.inegsee.gr'
              }
            ]}
          />
        </div>
      )
    },
  ],
  "ΙΜΕ / ΓΣΕΒΕΕ": [
    {
      name: "Περιφέρειες σε Μετάβαση (ΜΕΤ) – Ιόνια Νησιά",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΙΜΕ / ΓΣΕΒΕΕ Ηπείρου'
            members={[
              {
                name: 'Αλεξάνδρα Πέτση',
                phone: '26510 44727',
                email: 'petsi@imegsevee.gr'
              }
            ]}
          />
        </div>
      )
    },
    {
      name: "Περιφέρειες σε Μετάβαση (ΜΕΤ) – Στερεά Ελλάδα",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΙΜΕ / ΓΣΕΒΕΕ Θεσσαλίας'
            members={[
              {
                name: 'Παρασκευή Καγκελάρη',
                phone: '2410579876-7',
                email: 'kagkelari@imegsevee.gr'
              }
            ]}
          />
        </div>
      )
    },
    {
      name: "Λιγότερο Αναπτυγμένες Περιφέρειες (ΛΑΠ) – Ήπειρος, Θεσσαλία",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΙΜΕ / ΓΣΕΒΕΕ Θεσσαλίας'
            members={[
              {
                name: 'Ζαχαρούλα Μπουγάτσα',
                phone: '2410579876-7',
                email: 'bougatsa@imegsevee.gr'
              }
            ]}
          />
        </div>
      )
    }
  ],
  "ΚΑΝΕΠ / ΓΣΕΕ": [
    {
      name: "Περισσότερο Αναπτυγμένες Περιφέρειες (ΠΑΠ) – Αττική",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΚΑΝΕΠ / ΓΣΕΕ Αθήνα'
            address={{
              name: '3ης Σεπτεμβρίου 36',
              tk: '10432'
            }}
            members={[

              {
                name: 'Γεράσιμος Κόκκινος',
                phone: '2105218751',
                email: 'gerkokkinos@kanep-gsee.gr'
              }, {
                name: 'Παναγιώτα Καραθανάση',
                phone: '2105218713',
                email: 'ykarathanasi@kanep-gsee.gr'
              },
            ]}
          />
        </div>
      )
    }
  ],
  "ΚΑΕΛΕ ΕΣΕΕ": [
    {
      name: "Λιγότερο Αναπτυγμένες Περιφέρειες (ΛΑΠ) – Κεντρική Μακεδονία",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΚΑΕΛΕ/ΕΣΕΕ Θεσσαλονίκης'
            address={{
              name: 'Ναυμαχίας Λήμνου & Ολυμπίου Διαμαντή 16',
              tk: '54625'
            }}
            members={[
              {
                name: 'Μαίρη Δελησταύρου',
                phone: '2310 566282',
                email: 'thessaloniki@kaele.gr'
              }
            ]}
          />
          <ContactModalSegment
            title='ΚΑΕΛΕ/ΕΣΕΕ Αλεξάνδρειας'
            address={{
              name: 'Ν. Πλαστήρα 81',
              tk: '59300'
            }}
            members={[
              {
                name: 'Σάκης Γιαννακόπουλος',
                phone: '2333028010',
                email: 'alexandria@kaele.gr'
              }
            ]}
          />
        </div>
      )
    },
    {
      name: "Περιφέρειες σε Μετάβαση (ΜΕΤ) – Πελοπόννησος, Κρήτη",
      content: (
        <div className={styles.modalContent}>
          <ContactModalSegment
            title='ΚΑΕΛΕ ΕΣΕΕ Πάτρα'
            address={{
              name: 'Κανακάρη 46 - 52',
              tk: '26221'
            }}
            members={[
              {
                name: 'Χριστίνα Νιάχου',
                phone: '2610621757',
                email: 'patra@kaele.gr'
              }
            ]}
          />
          <ContactModalSegment
            title='ΚΑΕΛΕ ΕΣΕΕ Κρήτη'
            address={{
              name: 'Μιχελιδάκη και Βουρβάχων 11',
              tk: '71202 Τ'
            }}
            members={[
              {
                name: 'Αλίκη Σακελάρη',
                phone: '2810220450',
                email: 'cretantrade_oesk@otenet.gr'
              }
            ]}
          />
        </div>
      )
    },
  ]
}

const ContactInfoButtons = ({ carrier }) => {
  return (
    <div className={styles.contactLinks}>
      {
        contactInfoMap[carrier].map((info, i) => {
          return (
            <ContactInfoButton key={i} name={info.name} content={info.content} />
          );
        })
      }
    </div>
  );
}

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerΙtem}>
        <div className={styles.carriers}>
          <div className={styles.carriersTitle}> Φορείς σύμπραξης </div>
          <a className={styles.link} href="https://www.inegsee.gr/">
            <img className={styles.logo} src={logoIne} />
          </a>
          <a className={styles.link} href="https://imegsevee.gr/">
            <img className={styles.logo} src={logoIme} />
          </a>
          <a className={styles.link} href="https://www.kanep-gsee.gr/">
            <img className={styles.logo} src={logoKanep} />
          </a>
          <a className={styles.link} href="https://kaele.gr/">
            <img className={styles.logo} src={logoKaele} />
          </a>
          <a className={`${styles.link} ${styles.logoEoppep}`} href="https://www.eoppep.gr/">
            <img className={styles.logo} src={logoEOPPEP} />
          </a>
          <div className={styles.carriersTitle}> Έργο </div>
          <img className={styles.projectLogo} src={projectLogo} />
        </div>
      </div>
      <div className={styles.footerΙtem}>
        <div className={styles.contactCarriers}>
          <div className={styles.carriersTitle}> Επικοινωνία </div>
          <div className={styles.contactItem}>
            <div className={styles.contactCarrier}>
              <img className={styles.logo} src={logoIne} />
            </div>
            <ContactInfoButtons carrier="ΙΝΕ / ΓΣΕΕ" />
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactCarrier}>
              <img className={styles.logo} src={logoIme} />
            </div>
            <ContactInfoButtons carrier="ΙΜΕ / ΓΣΕΒΕΕ" />
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactCarrier}>
              <img className={styles.logo} src={logoKanep} />
            </div>
            <ContactInfoButtons carrier="ΚΑΝΕΠ / ΓΣΕΕ" />
          </div>
          <div className={styles.contactItem}>
            <div className={styles.contactCarrier}>
              <img className={styles.logo} src={logoKaele} />
            </div>
            <ContactInfoButtons carrier="ΚΑΕΛΕ ΕΣΕΕ" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
