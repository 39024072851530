import axios from "axios";

import { createContext, useContext } from "react";
const production = process.env.NODE_ENV === 'production';

let URI = "http://localhost:4000";
if (production)
  URI = `${window.location.protocol}//${window.location.host}/api`;

let FRONTEND_URI = "http://localhost:3000";
if (production)
  FRONTEND_URI = `${window.location.protocol}//${window.location.host}`; // todo: change this to the deployed frontend uri


export { URI, FRONTEND_URI };
export const ClientContext = createContext();
export const useClient = () => useContext(ClientContext);

export const ClientWrapper = ({ children }) => {
  const client = axios.create({ withCredentials: true, baseURL: URI });

  return (
    <ClientContext.Provider value={client}>
      {children}
    </ClientContext.Provider>
  );
};