import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Panel } from "../../components/Panel";
import { Form } from "../../react-form/src/Form";
import styles from "../../styles/Admin/ViewProgram.module.css";
import { useClient } from "../../components/ClientWrapper";
import { useToasts } from "../../components/ToastMessageWrapper";
import { IoIosArrowBack } from "react-icons/io";
import { BsPencilFill, BsPlus } from "react-icons/bs";
import createAnnouncementJson from "../../assets/data/admin/createAnnouncement.json";
import { FaTrash } from "react-icons/fa";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import Modal from "../../components/Modal";
import { MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import { download } from "../../lib/DownloadFile";
import { ProgramForm } from "./ProgramForm";
import { BsFlagFill } from "react-icons/bs";

const PublishProgramButton = ({ action }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const buttonRef = useRef();
  const addToast = useToasts();

  const actionWrapper = () => {
    buttonRef.current.setLoading(true);

    action()
      .then(() => {
        addToast({
          type: "success",
          message: "Η υλοποίηση του προγράμματος δημοσιεύθηκε επιτυχώς.",
          duration: 3000,
        });
      })
      .catch(() => { });
  };
  return (
    <>
      <button className="defaultButton" onClick={() => setPopupIsVisible(true)}>
        <MdOutlinePublic />
        Δημοσίευση
      </button>

      {popupIsVisible && (
        <Modal
          header="Δημοσίευση Υλοποίησης Προγράμματος"
          content={
            <div className={styles.modalContainer}>
              Είστε σίγουροιότι θέλετε να δημοσιευθεί η υλοποίηση του προγράμματος;
              <br /> H υλοποίηση του προγράμματος θα γίνει άμεσα ορατή στην κεντρική ιστοσελίδα.
            </div>
          }
          footer={
            <>
              <ButtonWithLoading ref={buttonRef} onClick={actionWrapper}>
                Δημοσίευση
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </>
  );
};

const UnpublishProgramButton = ({ action }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const addToast = useToasts();

  const actionWrapper = () => {
    return action()
      .then(() => {
        addToast({
          type: "success",
          message: "Η δημοσίευση ακυρώθηκε επιτυχώς.",
          duration: 3000,
        });
      })
      .catch(() => {
        addToast({
          type: "failure",
          message: "Υπήρξε κάποιο πρόβλημα με την ακύρωση της δημοσίευσης. Αν υπάρχουν αιτήσεις στην υλοποίηση του προγράμματος δεν μπορείτε να καταργήσετε τη δημοσίευση του.",
          duration: 3000,
        });
      });
  };
  return (
    <>
      <button
        className={`defaultButton ${styles.unpublish}`}
        onClick={() => setPopupIsVisible(true)}
      >
        <MdOutlinePublicOff />
        Κατάργηση Δημοσίευσης
      </button>
      {popupIsVisible && (
        <Modal
          header="Κατάργηση Δημοσίευσης Προγράμματος"
          content={
            <div className={styles.modalContainer}>
              Είστε σίγουροιότι θέλετε να καταργηθεί η δημοσίευση της υλοποίησης του προγράμματος;{" "}
              <br />H υλοποίηση του προγράμματος δεν θα είναι πλέον ορατή δημοσίως.
            </div>
          }
          footer={
            <>
              <ButtonWithLoading onClick={actionWrapper}>
                Κατάργηση Δημοσίευσης
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </>
  );
};

const DeleteProgramButton = ({ action }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const addToast = useToasts();
  const navigate = useNavigate();

  const actionWrapper = () => {
    return action()
      .then(() => {
        addToast({
          type: "success",
          message: "Η διαγραφή ολοκληρώθηκε επιτυχώς",
          duration: 3000,
        });

        navigate('..');
      })
      .catch(() => {
        addToast({
          type: "failure",
          message: "Υπήρξε κάποιο πρόβλημα με τη διαγραφή του προγράμματος. Παρακαλώ ξαναπροσπαθήστε.",
          duration: 3000,
        });
      });
  };

  return (
    <>
      <button
        className={`defaultButton ${styles.delete}`}
        onClick={() => setPopupIsVisible(true)}
      >
        <FaTrash />
        Διαγραφή
      </button>
      {popupIsVisible && (
        <Modal
          header="Διαγραφή Υλοποίησης Προγράμματος"
          content={
            <div className={styles.modalContainer}>
              Αν συνεχίσετε η υλοποίηση του προγράμματος θα διαγραφεί οριστικά. Είστε σίγουροι ότι θέλετε να συνεχίσετε;
            </div>
          }
          footer={
            <>
              <ButtonWithLoading
                className={`defaultButton ${styles.delete}`}
                onClick={actionWrapper}
              >
                Διαγραφή
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </>
  );
};

const FinalizeProgramButton = ({ action }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  return (
    <>
      <button
        className={`defaultButton ${styles.finalize}`}
        onClick={() => setPopupIsVisible(true)}
      >
        <BsFlagFill />
        Ολοκλήρωση και αρχειοθέτηση
      </button>
      {popupIsVisible && (
        <Modal
          header="Ολοκλήρωση και αρχειοθέτηση υλοποίησης προγράμματος"
          content={
            <div className={styles.modalContainer}>
              Με την ολοκλήρωση αυτής της ενέργειας, οι ωφελούμενοι και οι επιχειρήσεις δεν θα μπορούν να υποβάλλουν νέες αιτήσεις στο πρόγραμμα και εσείς
              δεν θα μπορείτε πλέον να αξιολογείτε και να κατατάσσετε αιτήσεις, ούτε να το επεξεργάζεστε με οποιοδήποτε τρόπο.
              <br /><br />Είστε σίγουροι ότι θέλετε να συνεχίσετε;
            </div>
          }
          footer={
            <>
              <ButtonWithLoading
                onClick={action}
              >
                Ολοκλήρωση και αρχειοθέτηση
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </>
  );
};

const Announcement = ({ announcement, onUpdate, onDelete }) => {
  const formRef = useRef(null);
  const [readOnly, setReadOnly] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const downloadFile = (file) => {
    if (file._id) {
      const pid = announcement.program,
        aid = announcement._id,
        fid = file._id;

      axios
        .get(`/program/${pid}/announcement/${aid}/file/${fid}`, {
          responseType: "blob",
        })
        .then((res) => {
          download(file.name, new Blob([res.data]));
        })
        .catch((err) => {
          addToast({
            type: "failure",
            message:
              "Υπήρξε κάποιο πρόβλημα με το κατέβασμα του αρχείου σας. Παρακαλώ ξαναπροσπαθήστε.",
            duration: 5000,
          });
          console.log(err);
        });
    } else download(file.name, file);
  };

  const createDescription = () => {
    const description = JSON.parse(JSON.stringify(createAnnouncementJson));

    const fileQuestion = description.questions.find((q) => q.name === "file");

    fileQuestion.callbacks = { onClick: downloadFile };

    return description;
  };

  const description = useRef(createDescription());

  const buttonRef = useRef(null);
  const modalButtonRef = useRef(null);

  const axios = useClient();
  const addToast = useToasts();

  const cancelEditing = () => {
    formRef.current.setData(announcement);
    setReadOnly(true);
  };

  const updateMe = () => {
    const announcement_ = formRef.current.getData();
    const file = announcement_.file;

    buttonRef.current.setLoading(true);

    let headers, payload;

    if (file) {
      if (file._id) {
        payload = {
          programId: announcement.program,
          announcementId: announcement._id,
          title: announcement_.title,
          description: announcement_.description,
          file: file._id,
        };
        headers = {};
      } else {
        payload = new FormData();
        payload.append("programId", announcement.program);
        payload.append("announcementId", announcement._id);
        payload.append("title", announcement_.title);
        payload.append("description", announcement_.description);
        payload.append("file", file);
        headers = { "Content-Type": "multipart/form-data" };
      }
    }

    axios
      .put("/program/announcement", payload, { headers })
      .then((res) => {
        setReadOnly(true);

        addToast({
          type: "success",
          message: "Οι αλλαγές σας αποθηκεύτηκαν.",
          duration: 3000,
        });

        onUpdate?.(res.data);
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message:
            "Υπήρξε κάποιο πρόβλημα με τις αλλαγές σας. Παρακαλώ ξαναπροσπαθήστε.",
          duration: 5000,
        });
      })
      .finally(() => {
        buttonRef.current?.setLoading(false);
      });
  };

  const deleteMe = () => {
    const payload = {
      programId: announcement.program,
      announcementId: announcement._id,
    };

    modalButtonRef.current.setLoading(true);

    axios
      .delete("/program/announcement", { data: payload })
      .then((res) => {
        addToast({
          type: "success",
          message: "Η ανακοίνωση έχει διαγραφεί επιτυχώς.",
          duration: 3000,
        });

        onDelete?.(res.data);
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message:
            "Υπήρξε κάποιο πρόβλημα με τη διαγραφή. Παρακαλώ ξαναπροσπαθήστε.",
          duration: 5000,
        });

        setModalIsVisible(false);
      })
      .finally(() => {
        modalButtonRef.current?.setLoading(false);
      });
  };

  return (
    <div
      className={`${styles.announcementContainer} ${!readOnly ? styles.editable : ""
        }`}
    >
      <div className={styles.announcementActionsTop}>
        {readOnly && <BsPencilFill onClick={() => setReadOnly(false)} />}
        <FaTrash onClick={() => setModalIsVisible(true)} />
      </div>
      <Form
        ref={formRef}
        readOnly={readOnly}
        description={description.current}
        initialValue={announcement}
      />
      {!readOnly && (
        <div className={styles.announcementActionsBottom}>
          <ButtonWithLoading ref={buttonRef} onClick={updateMe}>
            Αποθήκευση
          </ButtonWithLoading>
          <button className="cancelButton" onClick={() => cancelEditing()}>
            Ακύρωση
          </button>
        </div>
      )}
      {modalIsVisible && (
        <Modal
          header="Διαγραφή Ανακοίνωσης"
          content={
            <div className={styles.modalContainer}>
              Η ανακοίνωση θα διαγραφεί οριστικά από το σύστημα και δεν θα είναι
              ορατή στον ιστότοπο.
              <br />
              Είστε σίγουροιότι θέλετε να συνεχίσετε;
            </div>
          }
          footer={
            <>
              <ButtonWithLoading
                className={`defaultButton ${styles.delete}`}
                onClick={deleteMe}
                ref={modalButtonRef}
              >
                Διαγραφή
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setModalIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setModalIsVisible(false)}
        />
      )}
    </div>
  );
};

export const ViewProgram = () => {
  const [program, setProgram] = useState(null);
  const [editing, setEditing] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(true);

  const programFormRef = useRef();

  const addToast = useToasts();

  const axios = useClient();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`program/${id}`)
      .then((res) => {
        setProgram(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateProgram = () => {
    programFormRef.current.clearSanityCheck();

    const errors = programFormRef.current.sanityCheckForm();

    if (errors && errors.length) {
      addToast({
        type: "failure",
        message: "Τα στοιχεία που πληκτρολογήσατε δεν είναι ορθά.",
        duration: 3000,
      });
      return false;
    }

    const payload = programFormRef.current.getData();
    payload.id = program._id;

    return axios
      .put("program", payload)
      .then((res) => {
        addToast({
          type: "success",
          message: "Οι αλλαγές σας αποθηκεύτηκαν.",
          duration: 3000,
        });
        setEditing(false);
        setProgram(res.data);
        programFormRef.current.setData(res.data);
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message: "Υπήρξε κάποιο πρόβλημα με την αποθήκευση των αλλαγών σας. Παρακαλώ ξαναπροσπαθήστε.",
          duration: 3000,
        });
        console.log(err);
      })
  };

  const publishProgram = () => {
    const payload = {
      ...program,
      isPublished: !program.isPublished,
      id: program._id,
    };
    const promise = new Promise((resolve, reject) => {
      axios
        .put(`/program`, payload)
        .then((res) => {
          resolve();
          setProgram(payload);
        })
        .catch((err) => {
          console.log(err.toJSON());
          reject();
        });
    });

    return promise;
  };

  const finalizeProgram = () => {
    const payload = {
      ...program,
      isCompleted: true,
      id: program._id,
    };

    return axios
      .put(`/program`, payload)
      .then((res) => {
        setProgram(payload);
        addToast({
          type: "success",
          message: "Η υλοποίηση του προγράμματος ολοκληρώθηκε και αρχειοθετήθηκε επιτυχώς",
          duration: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const deleteProgram = () => {
    return axios.delete(`/program`, { data: { id: program._id } });
  }

  const cancelEditing = () => {
    programFormRef.current.setData(program);
    setEditing(false);
  };

  return (
    <Panel headerBar={"Διαχείριση Προγράμματος"}>
      {program && (
        <div>
          <div className={`${styles.titleContainer} ${styles.viewProgram}`}>
            <Link className="link" to="..">
              <IoIosArrowBack className=""></IoIosArrowBack>
              Πίσω
            </Link>
            <h2> {program.information.title} </h2>
          </div>

          {
            !program.isCompleted &&
            <div className={styles.actions}>
              {
                program.isPublished ?
                  <UnpublishProgramButton action={publishProgram} /> :
                  <PublishProgramButton action={publishProgram} />
              }
              {
                program.isPublished &&
                <FinalizeProgramButton action={finalizeProgram} />
              }
              {
                !program.isPublished &&
                <DeleteProgramButton action={deleteProgram} />
              }
            </div>
          }

          <div
            className={`${styles.segmentContainer} ${styles.programForm} ${editing ? styles.editable : ""
              }`}
          >
            <ProgramForm
              ref={programFormRef}
              readOnly={!editing}
              program={program}
              onLoad={() => setFormIsLoading(false)}
            />

            {!program.isCompleted && !formIsLoading && !editing && (
              <button
                className={`defaultButton ${styles.edit}`}
                onClick={() => setEditing(true)}
              >
                <BsPencilFill />
                Επεξεργασία
              </button>
            )}

            {!formIsLoading && editing && (
              <div className={styles.segmentActions}>
                <ButtonWithLoading onClick={updateProgram}>
                  Αποθήκευση
                </ButtonWithLoading>
                <button onClick={cancelEditing} className="cancelButton">
                  Ακύρωση
                </button>
              </div>
            )}
          </div>

          <div className={styles.segmentContainer}>
            <Form
              description={{
                title: "Ανακοινώσεις",
                showTitle: true,
                name: "Create Program",
                type: "form",
                autoRetrievedFields: [],
                questions: [],
              }}
            />
            {program.announcements.map((announcement, i) => {
              return (
                <Announcement
                  key={announcement._id}
                  announcement={announcement}
                  onUpdate={(announcement_) => {
                    const announcements_ = [...program.announcements];

                    announcements_[i] = announcement_;

                    setProgram({ ...program, announcements: announcements_ });
                  }}
                  onDelete={() => {
                    program.announcements.splice(i, 1);
                    setProgram({ ...program });
                  }}
                />
              );
            })}
            <CreateAnnouncementButton
              programId={program._id}
              onSuccess={(announcement) => {
                setProgram({
                  ...program,
                  announcements: [...program.announcements, announcement],
                });
              }}
            />
          </div>
        </div>
      )}
    </Panel>
  );
};

const CreateAnnouncementButton = ({ programId, onSuccess }) => {
  const [popupIsVisible, setPopupIsVisible] = useState(false);
  const description = useRef(JSON.parse(JSON.stringify(createAnnouncementJson)));
  const formRef = useRef();
  const axios = useClient();
  const addToast = useToasts();

  const createAnnouncement = () => {
    formRef.current.clearSanityCheck();

    const errors = formRef.current.sanityCheckForm();

    if (errors && errors.length) return false;

    const announcement = formRef.current.getData();

    const payload = new FormData();
    payload.append("programId", programId);
    payload.append("title", announcement.title);
    payload.append("description", announcement.description);
    payload.append("file", announcement.file);

    return axios
      .post("/program/announcement", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setPopupIsVisible(false);

        addToast({
          type: "success",
          message: "Η ανακοίνωση δημοσιεύθηκε επιτυχώς.",
          duration: 3000,
        });

        onSuccess(res.data);
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message:
            "Υπήρξε κάποιο πρόβλημα με τη δημιουργία της ανακοίνωσης. Παρακαλώ ξαναπροσπαθήστε.",
          duration: 5000,
        });
      })
  };

  return (
    <>
      <button className="defaultButton" onClick={() => setPopupIsVisible(true)}>
        <BsPlus />
        Δημιουργία Ανακοίνωσης
      </button>
      {popupIsVisible && (
        <Modal
          header="Δημιουργία Ανακοίνωσης"
          content={
            <div className={styles.modalAnnouncement}>
              <Form description={description.current} ref={formRef} />
            </div>
          }
          footer={
            <>
              <ButtonWithLoading
                onClick={createAnnouncement}
              >
                Δημιουργία
              </ButtonWithLoading>
              <button
                className="cancelButton"
                onClick={() => setPopupIsVisible(false)}
              >
                Ακύρωση
              </button>
            </>
          }
          onClose={() => setPopupIsVisible(false)}
        />
      )}
    </>
  );
};
