import React, { useCallback, useEffect, useRef, useState } from 'react'
import programsDropdownJson from "../assets/data/programsDropdown.json";
import { Form } from '../react-form/src/Form';
import { useClient } from './ClientWrapper';
import { useUser } from './UserWrapper';
import styles from '../styles/ProgramDropdown.module.css'

function ProgramDropdown({ onChange, onNoPrograms, isCompleted }) {
    const [formDescription, setFormDescription] = useState(null);

    const axios = useClient();
    const { user } = useUser();
    const formRef = useRef();

    useEffect(() => {
        getPrograms();
    }, []);

    const getProgramUrl = () => {
        let url = "/program?isPublished=true";

        if (isCompleted !== undefined)
            url += `&isCompleted=${isCompleted}`;

        if (user.carrier)
            url += `&carrier=${user.carrier}`;

        return url;
    };

    const getPrograms = () => {
        const url = getProgramUrl();
        axios
            .get(url)
            .then((res) => {
                const updatedFormDescription = JSON.parse(
                    JSON.stringify(programsDropdownJson)
                );
                const programDropdown = updatedFormDescription.questions
                    .find((q) => q.name === "programInfo")
                    .questions.find((q) => q.name === "program");

                programDropdown.choices = res.data.map((program) => {
                    return {
                        title: program.information.title,
                        value: program
                    };
                });

                programDropdown.value = programDropdown.choices[0]?.value;

                setFormDescription(updatedFormDescription);

                if (!res.data || !res.data.length) onNoPrograms?.();
            })
            .catch((err) => { console.log(err) });
    };

    const onProgramChange = useCallback((path, value) => {
        onChange(value);
    }, []);

    return (
        <div className={styles.container}>
            {formDescription && (
                <Form
                    ref={formRef}
                    description={formDescription}
                    onChange={onProgramChange}
                ></Form>
            )}
        </div>
    )
}

export default ProgramDropdown