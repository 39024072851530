import React, { useEffect, useState } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { useClient } from "../../../components/ClientWrapper";
import styles from "../../../styles/Trainee/TraineeRegistryApplications.module.css";
import RegistryTable from "../../../components/RegistryPageUtils/RegistryTable";
import ProgramDropdown from "../../../components/ProgramDropdown";
import { Panel } from "../../../components/Panel";
import LoaderWrapper from "../../../components/LoaderWrapper";
import { file2csv, generateApplicantsCsv } from "../../../lib/csv";
import traineeApplicationJson from "../../../assets/data/trainee-application.json";

const STATUS = {
  success: "Αποδεκτή",
  failure: "Απορριπτέα",
  pending: "Εκκρεμεί",
};

const columns = [
  {
    id: "id",
    name: "id",
    hidden: true,
  },
  {
    id: "managerEmail",
    name: "managerEmail",
    hidden: true,
  },
  {
    id: "kayas",
    name: "ΚΑΥΑΣ",
  },
  {
    id: "name",
    name: "Όνομα",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "afm",
    name: "Α.Φ.Μ",
  },
  {
    id: "status",
    name: "Έλεγχος",
    filterOptions: Object.keys(STATUS).map((key) => ({
      label: STATUS[key],
      value: STATUS[key],
    })),
  },
  {
    id: "manager",
    name: "Διαχειριστής",
  },
  {
    id: "open",
    width: 30,
    sort: false,
  },
];

function TraineeRegistryAllApplications() {
  const [applicants, setApplicants] = useState();
  const [applicantsCsv, setApplicantsCsv] = useState({ data: [] });
  const axios = useClient();

  function getApplications(program = undefined) {
    if (!program) return;
    setApplicants(null);

    axios
      .get(`/registry/trainee/getApplications?isFinalized=${true}&programId=${program._id}`)
      .then((res) => {
        const extractResults = () => {
          return res.data.map(
            ({
              _id,
              kayas,
              personalInfo: { firstName, lastName, email, taxId },
              isEvaluated,
              manager,
              evaluation,
            }) => [
                _id,
                manager?.email,
                kayas,
                `${firstName} ${lastName}`,
                email,
                taxId,
                isEvaluated
                  ? evaluation.accepted
                    ? STATUS.success
                    : STATUS.failure
                  : STATUS.pending,
                manager ? `${manager.firstName} ${manager.lastName}` : undefined,
              ]
          );
        }
        setApplicantsCsv(generateApplicantsCsv(res.data, traineeApplicationJson, file2csv('trainee')));
        setApplicants(extractResults());
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onNoPrograms = () => {
    setApplicants([]);
  }

  return (
    <Panel headerBar="Έλεγχος Αιτήσεων">
      <div className={styles.appsContainer}>
        <ProgramDropdown onNoPrograms={onNoPrograms} onChange={getApplications} isCompleted={false} />
        <LoaderWrapper size={"75"} thickness={"7.5px"} loading={!applicants}>
          {
            applicants &&
            <RegistryTable
              applicantsCsv={{
                ...applicantsCsv,
                filename: 'Αιτήσεις Ωφελούμενων'
              }}
              columns={columns}
              applicants={applicants}
              applicationGroup="trainee"
            />
          }
        </LoaderWrapper>
      </div>
    </Panel>
  );
}

export default TraineeRegistryAllApplications;