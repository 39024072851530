import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useClient } from "../../components/ClientWrapper";
import LoaderWrapper from "../../components/LoaderWrapper";
import styles from "../../styles/ForgotPassword.module.css";
import { useToasts } from "../../components/ToastMessageWrapper";
import { Form } from "../../react-form/src/Form";
import description from "../../assets/data/resetPassword.json";

export const ResetPassword = () => {
  const recaptchaToken = useRef(null);
  const recaptchaRef = useRef(null);
  const buttonRef = useRef(null);
  const passwords = useRef({});
  const navigate = useNavigate();
  const axios = useClient();
  const { uid, token } = useParams();
  const addToast = useToasts();
  const formRef = useRef();

  const recaptchaOnChange = (value) => (recaptchaToken.current = value);

  const [isValidLink, setIsValidLink] = useState(null);

  const setPassword = (v) => {
    passwords.current.password = v;
  };
  const setConfirmationPassword = (v) => {
    passwords.current.confirmation = v;
  };

  useEffect(() => {
    uid && token ? validateLink() : setIsValidLink(false);
  }, []);

  useEffect(() => {
    Form.registerRestrictionCheck({
      tag: "validateConfirmationPassword",
      func: () => {
        return passwords.current.password === passwords.current.confirmation;
      },
      explanation:
        "Οι κωδικοί 'Νέος Κωδικός Πρόσβασης' και 'Επανάληψη Νέου Κωδικού Πρόσβασης' δεν είναι ίδιοι",
    });
  }, []);

  const handleSubmit = (e) => {
    const { password, confirmationPassword } = formRef.current.getData();

    formRef.current.clearSanityCheck();

    setPassword(password);
    setConfirmationPassword(confirmationPassword);

    const errors = formRef.current.sanityCheckForm();

    if (recaptchaToken.current && !errors.length) {
      e.preventDefault();

      resetPassword();
    }
  };

  const validateLink = () => {
    axios
      .post("/user/reset-password", { uid, token })
      .then((res) => {
        setIsValidLink(true);
      })
      .catch((err) => {
        setIsValidLink(false);
      });
  };

  const resetPassword = () => {
    buttonRef.current.setLoading(true);

    axios
      .post("/user/reset-password", {
        uid,
        token,
        password: passwords.current.password,
      })
      .then((res) => {
        window.location.href = "/login";
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message:
            "Η αλλαγή κωδικού πρόσβασης δεν πραγματοποιήθηκε. Παρακαλώ δοκιμάστε να ανανεώσετε τη σελίδα ή να ξαναζητήσετε επαναφορά κωδικού πρόσβασης.",
          duration: 10000,
        });
      })
      .finally(() => {
        buttonRef.current.setLoading(false);
      });
  };

  return (
    <div className={styles.wholePage}>
      <LoaderWrapper size={80} thickness={8} loading={isValidLink === null}>
        {isValidLink && (
          <div className={styles.container}>
            <div className={styles.title}> Επαναφορά Κωδικού Πρόσβασης </div>
            <Form ref={formRef} description={description}></Form>

            <div className={styles.recaptcha}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                onChange={recaptchaOnChange}
              ></ReCAPTCHA>
            </div>

            <ButtonWithLoading onClick={handleSubmit} ref={buttonRef}>
              Αποστολή
            </ButtonWithLoading>
          </div>
        )}
        {!isValidLink && (
          <div className={styles.container}>
            <div className={styles.title}>
              Ο σύνδεσμος επαναφοράς κωδικού πρόσβασης που χρησιμοποιήσατε δεν
              είναι ορθός.
            </div>
            <div className={styles.title}>
              Αυτό μπορεί να συμβαίνει γιατί έχετε ήδη χρησιμοποιήσει το
              σύνδεσμο αυτό ή γιατί ο σύνδεσμος αυτός έχει λήξει.
            </div>
            <button
              className="defaultButton"
              onClick={() => navigate("/login")}
            >
              Πίσω στη Σύνδεση
            </button>
          </div>
        )}
      </LoaderWrapper>
    </div>
  );
};
