import React from "react";

// ---------------------------------------------------------------------------------------

export class FormLabel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            labelID: Math.floor(Math.random() * 100000000)
        }

        this.scrollIntoView = this.scrollIntoView.bind(this);
    }

    scrollIntoView() {
        document.getElementById(`form-item-label-${this.state.labelID}`).scrollIntoView();
    }

    render() {
        return <>
            <div className={`form-item-input-title`} id={`form-item-label-${this.state.labelID}`}>{this.props.label}</div>
            <div className="form-label subformTitle"></div>
        </>
    }

};

// ---------------------------------------------------------------------------------------