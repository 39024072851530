import React, { useEffect, useMemo, useRef, useState } from "react";
import Card from "../components/Card";
import styles from "../styles/Portal.module.css";

import userCardData from "../assets/data/userPortalCards.json";
import employeeCardData from "../assets/data/employeePortalCards.json";

import { Link } from "react-router-dom";
import Announcement from "../components/Announcement";
import { useClient } from "../components/ClientWrapper";
import LoaderWrapper from "../components/LoaderWrapper";
import { download } from "../lib/DownloadFile";
import { useToasts } from "../components/ToastMessageWrapper";

import logo from "../assets/images/logoINE.png";
import { useUser } from "../components/UserWrapper";
import { IoMdWarning } from "react-icons/io";
import ButtonWithLoading from "../components/ButtonWithLoading";
import { usePageSize } from "../components/withPageSize";

import Select from "react-select";

function loadImage(img) {
  const loadDispatcher = {
    "businessApplication.png": () => {
      return require("../assets/images/businessApplication.png");
    },
    "studentsApplication.png": () => {
      return require("../assets/images/studentsApplication.png");
    },
    "businessManagement.png": () => {
      return require("../assets/images/businessManagement.png");
    },
    "traineeManagement.png": () => {
      return require("../assets/images/traineeManagement.png");
    },
    "management.png": () => {
      return require("../assets/images/management.png");
    },
  };
  return loadDispatcher[img]();
}

const AreaFilter = ({ onChange }) => {
  const [selected, setSelected] = useState(0);
  const width = usePageSize();

  const areas = useRef([
    ["Όλες οι περιφέρειες"],
    ["Αττικής", " - ΚΑΝΕΠ/ΓΣΕΕ"],
    ["Θεσσαλίας", " - ΙΜΕ/ΓΣΕΒΕΕ"],
    ["Στερεάς Ελλάδας", " - ΙΜΕ/ΓΣΕΒΕΕ"],
    ["Ηπείρου", "- ΙΜΕ/ΓΣΕΒΕΕ"],
    ["Δυτικής Μακεδονίας", " - ΙΝΕ/ΓΣΕΕ"],
    ["Πελοποννήσου", " - ΚΑΕΛΕ/ΕΣΕΕ"],
    ["Δυτικής Ελλάδας", " - ΙΝΕ/ΓΣΕΕ"],
    ["Ιονίων Νήσων", " - ΙΜΕ/ΓΣΕΒΕΕ"],
    ["Βορείου Αιγαίου", " - ΙΝΕ/ΓΣΕΕ"],
    ["Νοτίου Αιγαίου", " - ΙΝΕ/ΓΣΕΕ"],
    ["Κρήτης", " - ΚΑΕΛΕ/ΕΣΕΕ"],
    ["Ανατολικής Μακεδονίας και Θράκης", " - ΙΝΕ/ΓΣΕΕ"],
    ["Κεντρικής Μακεδονίας", " - ΚΑΕΛΕ/ΕΣΕΕ"],
  ]);

  const update = (i) => {
    if (i !== selected) {
      setSelected(i);
      onChange?.(areas.current[i][0]);
    }
  };

  const options = useMemo(() => {
    return areas.current.map((area, i) => {
      return {
        value: i,
        label: `${area[0]} ${area[1] || ""}`,
      };
    });
  }, []);

  const onChange_ = (option) => {
    update(option.value);
  };

  if (width <= 1000)
    return (
      <Select
        options={options}
        value={options[selected]}
        onChange={onChange_}
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
          }),
        }}
      />
    );

  return (
    <div className={styles.filters}>
      {areas.current.map((area, i) => (
        <div
          className={
            i === selected ? `${styles.area} ${styles.selected}` : styles.area
          }
          onClick={() => update(i)}
          key={i}
        >
          {area[0]}
          {area[1]}
        </div>
      ))}
    </div>
  );
};

const Announcements = () => {
  const [announcements, setAnnouncements] = useState();
  const axios = useClient();
  const addToast = useToasts();

  useEffect(() => {
    getAnnouncements();
  }, []);

  const onAreaFilter = (area) => {
    setAnnouncements(null);

    if (area === "Όλες οι περιφέρειες") getAnnouncements();
    else getAnnouncements(`areas[]=${area}`);
  };

  const getAnnouncements = (filter) => {
    let url = "/program?isPublished=true";

    if (filter) url += `&${filter}`;

    axios
      .get(url)
      .then((res) => {
        const programs = res.data;

        if (!programs) {
          setAnnouncements([]);
          return;
        }

        const announcements_ = programs
          .map((program) => program.announcements || [])
          .flat()
          .sort((a1, a2) =>
            new Date(a1.updatedAt) > new Date(a2.updatedAt) ? -1 : 1
          );

        setAnnouncements(announcements_);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadFile = (pid, aid, fid, fname) => {
    axios
      .get(`/program/${pid}/announcement/${aid}/file/${fid}`, {
        responseType: "blob",
      })
      .then((res) => {
        download(fname, new Blob([res.data]));
      })
      .catch((err) => {
        addToast({
          type: "failure",
          message:
            "Υπήρξε κάποιο πρόβλημα με το κατέβασμα του αρχείου σας. Παρακαλώ ξαναπροσπαθήστε.",
          duration: 5000,
        });
        console.log(err);
      });
  };

  return (
    <div className={styles.announcementsContainer}>
      <div className={styles.title}>Ανακοινώσεις</div>
      <div className={styles.announcementsContent}>
        <AreaFilter onChange={onAreaFilter} />
        <div className={styles.announcements}>
          <LoaderWrapper size={50} thickness={8} loading={!announcements}>
            {!announcements ||
              (announcements.length === 0 && (
                <div className={styles.emptyAnnouncements}>
                  Δεν υπάρχουν διαθέσιμες ανακοινώσεις
                </div>
              ))}
            {announcements &&
              announcements.length !== 0 &&
              announcements.map((announcement, j) => (
                <Announcement
                  key={`${j}`}
                  title={announcement.title}
                  desc={announcement.description}
                  fname={announcement.file?.name}
                  date={new Date(announcement.updatedAt).toLocaleDateString(
                    "el-GR"
                  )}
                  onFileClick={() =>
                    downloadFile(
                      announcement.program,
                      announcement._id,
                      announcement.file._id,
                      announcement.file.name
                    )
                  }
                />
              ))}
          </LoaderWrapper>
        </div>
      </div>
    </div>
  );
};

export default function Portal() {
  const { user } = useUser();

  return (
    <div className={styles.portal}>
      {!user ? (
        <NotLoggedInPortalInfo />
      ) : !user.isVerified ? (
        <NotVerifiedPortalInfo />
      ) : user.isEmployee ? (
        <EmployeePortalInfo />
      ) : (
        <UserPortalInfo />
      )}
      <Announcements />
    </div>
  );
}

const EmployeePortalInfo = () => {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.info}>
        <h1>
          Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης
          επαγγελματικής εμπειρίας (ΙΕΚ)
        </h1>
        <h2>
          Επιλέξτε από τα παρακάτω ανάλογα με τις ενέργειες που θέλετε να
          πραγματοποιήσετε
        </h2>

        <div className={styles.cards}>
          {employeeCardData.map((card, i) => {
            return (
              <Card
                key={i}
                title={card.title}
                desc={card.desc}
                img={loadImage(card.img)}
              >
                <Link className="link" to={card.link}>
                  <button className="defaultButton"> Μετάβαση </button>
                </Link>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const UserPortalInfo = () => {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.info}>
        <h1>
          Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης
          επαγγελματικής εμπειρίας (ΙΕΚ)
        </h1>
        <h2>
          Για να υποβάλλετε την αίτηση σας ή να ελέγξετε την κατάσταση της
          επιλέξτε ένα από τα παρακάτω
        </h2>

        <div className={styles.cards}>
          {userCardData.map((card, i) => {
            return (
              <Card
                key={i}
                title={card.title}
                desc={card.desc}
                img={loadImage(card.img)}
              >
                <Link className="link" to={card.link}>
                  <button className="defaultButton"> Μετάβαση </button>
                </Link>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const NotLoggedInPortalInfo = () => {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.info}>
        <div className={styles.logo}>
          <img src={logo} />
        </div>
        <h1>
          Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης
          επαγγελματικής εμπειρίας (ΙΕΚ)
        </h1>

        <h2>
          Είστε εκπαιδευόμενος ΙΕΚ ή επιχείρηση; Για να υποβάλλετε αίτηση
          συμμετοχής στο πρόγραμμα πρακτικής άσκησης θα πρέπει να εγγραφείτε στο
          σύστημα
        </h2>

        <div className={styles.registerOrLogin}>
          <Link to="/register" className={styles.registerBtn}>
            Εγγραφή
          </Link>
          <div className={styles.orLogin}>
            Έχετε ήδη λογαριασμό;
            <Link to="/login" className={styles.loginBtn}>
              Σύνδεση
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotVerifiedPortalInfo = () => {
  const buttonRef = useRef();
  const axios = useClient();
  const addToast = useToasts();

  const sendVerificationEmail = async () => {
    buttonRef.current.setLoading(true);

    try {
      await axios.post("/user/send-verification-email");

      addToast({
        type: "success",
        message:
          "Σας στάλθηκε εκ νέου email με το σύνδεσμο επιβεβαίωσης της εγγραφής σας",
        duration: 5000,
      });
    } catch (err) {
      addToast({
        type: "failure",
        message:
          "Υπήρξε κάποιο πρόβλημα με την αποστολή του email. Παρακαλώ επαναφορτώστε τη σελίδα και ξαναπροσπαθήστε",
        duration: 5000,
      });
    } finally {
      buttonRef.current.setLoading(false);
    }
  };

  return (
    <div className={styles.infoContainer}>
      <div className={styles.info}>
        <div className={styles.logo}>
          <img src={logo} />
        </div>
        <h1>
          Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης
          επαγγελματικής εμπειρίας (ΙΕΚ)
        </h1>

        <div className={styles.verifyContainer}>
          <IoMdWarning className={styles.warning} />
          <h2>
            Πριν έχετε τη δυνατότητα να υποβάλλετε αίτηση στο πρόγραμμα
            πρακτικής άσκησης, επιβεβαιώστε τη διεύθυνση ηλεκτρονικού
            ταχυδρομείου σας και επαναφορτώστε τη σελίδα
          </h2>
          <span className={styles.resendEmail}>
            Δεν λάβατε email επιβεβαίωσης;
            <ButtonWithLoading ref={buttonRef} onClick={sendVerificationEmail}>
              Αποστολή
            </ButtonWithLoading>
          </span>
        </div>
      </div>
    </div>
  );
};
