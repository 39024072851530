import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Navigate, Route, Routes } from "react-router-dom";
import BusinessRegistryAllApplications from "./BusinessRegistryAllApplications";
import BusinessRegistryEvaluation from "./BusinessRegistryEvaluation";
import { Error404 } from "../../Error/Error404";

function BusinessRegistryApplications() {
    return (
        <Routes>
            <Route path="/all" element={<BusinessRegistryAllApplications />} />
            <Route path="/:appId" element={<BusinessRegistryEvaluation />} />
            <Route path="/" element={<Navigate to="all" />} />
            <Route path="/*" element={<Navigate to="/error404" />} />
        </Routes>
    );
}

export default BusinessRegistryApplications;
