import React, { useEffect, useRef, useState } from "react";
import { useClient } from "../components/ClientWrapper";
import { useToasts } from "../components/ToastMessageWrapper";
import ButtonWithLoading from "../components/ButtonWithLoading";
import { FileUploadModal } from "../components/FileUploadModal";
import styles from "../styles/SaveApplicationButton.module.css";

export const SaveApplicationButton = ({
  formRef,
  filePaths,
  uploadUrl,
  deleteUrl,
  saveUrl,
  onSuccess,
  sanityCheckIgnoreRequired = true,
}) => {
  const buttonRef = useRef();
  const axios = useClient();
  const addToast = useToasts();
  const [application, setApplication] = useState(null);

  const onFileUploadSuccess = async () => {
    try {
      await axios.put(saveUrl, application);
    } catch (err) {
      addToast({
        type: "failure",
        message:
          err?.response?.data?.errorGreek ||
          "Κάτι πήγε στραβά με την αποθήκευση της αίτησης σας. Παρακαλώ ξαναπροσπαθήστε",
        duration: 5000,
      });
      buttonRef.current.setLoading(false);
      setApplication(null);
      return;
    }

    await deleteHangingFiles();

    addToast({
      type: "success",
      message: "Η αίτηση σας αποθηκεύτηκε επιτυχώς",
      duration: 3000,
    });
    buttonRef.current.setLoading(false);
    onSuccess?.(application);
    setApplication(null);
  };

  const onFileUploadFailure = (msg) => {
    addToast({
      type: "failure",
      message: msg,
      duration: 3000,
    });
    buttonRef.current.setLoading(false);
    setApplication(null);
  };

  const onFileUploadClose = () => {
    setApplication(null);
    buttonRef.current.setLoading(false);
  };

  const deleteHangingFiles = async () => {
    try {
      await axios.delete(deleteUrl);
    } catch (err) {}
  };

  const getApplicationIfValid = () => {
    formRef.current.clearSanityCheck();

    if (!formRef.current.sanityCheckForm(sanityCheckIgnoreRequired).length)
      return formRef.current.getData();

    return null;
  };

  const onSave = async (e) => {
    const application = getApplicationIfValid();

    if (!application)
      return addToast({
        type: "failure",
        message:
          "Η αίτηση σας δεν έχει αποθηκευτεί καθώς υπάρχουν λανθασμένα πεδία",
        duration: 5000,
      });

    buttonRef.current.setLoading(true);

    await deleteHangingFiles();

    setApplication(application);
  };

  return (
    <>
      <ButtonWithLoading
        className={styles.save}
        ref={buttonRef}
        onClick={onSave}
      >
        Αποθήκευση
      </ButtonWithLoading>
      {application && (
        <FileUploadModal
          application={application}
          filePaths={filePaths}
          uploadUrl={uploadUrl}
          onClose={onFileUploadClose}
          onUploadSuccess={onFileUploadSuccess}
          onUploadFailure={onFileUploadFailure}
        />
      )}
    </>
  );
};
