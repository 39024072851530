import React from "react";
// ----------------------------------------------------------------
import { BsClipboardData, BsFolder2 } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";

// ----------------------------------------------------------------
import { SideNavPage } from "../../../components/SideNavPage";
// ----------------------------------------------------------------
import TraineeRegistryApplications from "./TraineeRegistryApplications";
import TraineeRegistryArchived from "./TraineeRegistryArchived";
import TraineeRegistryRankings from "./TraineeRegistryRankings";
// ----------------------------------------------------------------

const options = [
  {
    Icon: HiPencilAlt,
    text: 'Aξιολόγηση Αιτήσεων',
    path: 'applications',
    innerRouting: true,
    element: <TraineeRegistryApplications />
  },
  {
    Icon: BsClipboardData,
    text: 'Κατάταξη και Σύζευξη',
    path: 'ranking',
    innerRouting: true,
    element: <TraineeRegistryRankings />
  },
  {
    Icon: BsFolder2,
    text: 'Ιστορικό',
    path: 'archived',
    innerRouting: true,
    element: <TraineeRegistryArchived />
  },
];

function TraineeRegistryPage() {
  return (
    <SideNavPage prefix='trainee' options={options} />
  );
}

export default TraineeRegistryPage;
