import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import styles from '../../styles/ForgotPassword.module.css';
import { useToasts } from "../../components/ToastMessageWrapper";
import { useClient } from '../../components/ClientWrapper';
import { Form } from '../../react-form/src/Form';
import description from "../../assets/data/forgotPassword.json";

export const ForgotPassword = () => {
  const recaptchaToken = useRef(null);
  const recaptchaRef = useRef(null);
  const buttonRef = useRef(null);
  const addToast = useToasts();
  const axios = useClient();
  const formRef = useRef();
  const errorRef = useRef(false);

  const recaptchaOnChange = value => recaptchaToken.current = value;

  useEffect(() => {
    Form.registerRestrictionCheck({
      tag: "validateForgotPassword",
      func: () => { return !errorRef.current; },
      explanation: "Δεν βρέθηκε εγγεγραμμένος λογαριασμός με αυτό το email"
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    formRef.current.clearSanityCheck();
    errorRef.current = false;

    const errors = formRef.current.sanityCheckForm();

    if (recaptchaToken.current && !errors.length)
      requestPasswordReset();
  };

  const requestPasswordReset = () => {
    const {email} = formRef.current.getData();

    buttonRef.current.setLoading(true);

    axios.post(
      '/user/forgot-password',
      { email: email }
    )
      .then(res => {
        addToast({
          type: "success",
          message: `Σας έχει αποσταλθεί σύνδεσμος επαναφοράς του κωδικού πρόσβασης σας στη διεύθυνση ${email}`,
          duration: 10000
        });
        errorRef.current = false;
      })
      .catch((err) => {
        errorRef.current = true;
      })
      .finally(() => {
        formRef.current?.sanityCheckForm();
        recaptchaRef.current.reset();
        recaptchaToken.current = undefined;
        buttonRef.current.setLoading(false);
      })
  }

  return (
    <div className={styles.wholePage}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}> Επαναφορά Κωδικού Πρόσβασης </div>
          <div>
            Πληκτρολογήστε τη διεύθυνση email που χρησιμοποιήσατε για την εγγραφή σας στο σύστημα.
            Θα σας αποσταλεί email με έναν σύνδεσμο για να αλλάξετε τον κωδικό πρόσβασής σας.
          </div>
        </div>
        <Form ref={formRef} description={description} />
        <div className={styles.recaptcha}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
            onChange={recaptchaOnChange}
          ></ReCAPTCHA>
        </div>
        <ButtonWithLoading ref={buttonRef} onClick ={handleSubmit}> Αποστολή </ButtonWithLoading>
      </div>
    </div>
  );
};