import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Navigate, Route, Routes } from "react-router-dom";
import TraineeRegistryAllApplications from "./TraineeRegistryAllApplications";
import TraineeRegistryEvaluation from "./TraineeRegistryEvaluation";

function TraineeRegistryApplications() {
    return (
        <Routes>
            <Route path="/all" element={<TraineeRegistryAllApplications />} />
            <Route path="/:appId" element={<TraineeRegistryEvaluation />} />
            <Route path="/" element={<Navigate to="all" />} />
            <Route path="/*" element={<Navigate to="/error404" />} />
        </Routes>
    );
}

export default TraineeRegistryApplications;
