import axios from "axios";
import React, { useState } from "react"
import styles from "../styles/Profile.module.css"
import { useClient } from "./ClientWrapper";
import { useUser } from "./UserWrapper";

const generateIcon = (letter) => <div className={styles.icon}> {letter} </div>;

export const UserInfo = ({ firstName, lastName, email }) => {
  return (
    <div className={styles.userInfo}>
      <div className={styles.iconContainerLarge}>
        {generateIcon(firstName[0].toUpperCase())}
      </div>
      <div className={styles.infoRow}> {firstName} {lastName} </div>
      <div className={`${styles.infoRow} ${styles.email}`}> {email} </div>
    </div>
  );
}

export const Profile = (props) => {
  const [panelIsVisible, setPanelIsVisible] = useState(false);

  const toggle = () => setPanelIsVisible(!panelIsVisible);

  const client = useClient();
  const { user, setUser } = useUser();

  const logOut = () => {
    client.post('/user/logout')
      .then(() => {
        axios.defaults.headers.common['Authorization'] = ``;
        window.location.href = '/';
      })
      .catch(() => { })
      .finally(() => { })
  }

  return user && (
    <div className={styles.profileContainer}>
      <div className={styles.iconContainerMedium} onClick={toggle} >
        {generateIcon(user.firstName[0].toUpperCase())}
      </div>
      <div className={panelIsVisible ? styles.profilePanel : styles.invisible}>
        <UserInfo
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
        />
        <button className = "defaultButton" onClick={logOut}> Αποσύνδεση </button>
      </div>
    </div>
  );
}