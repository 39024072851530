import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useClient } from "../../components/ClientWrapper";
import { usePageInfo } from "../../components/PageInfoWrapper";
import { useUser } from "../../components/UserWrapper";
import styles from "../../styles/Register.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { Form } from "../../react-form/src/Form";
import description_ from "../../assets/data/register.json";
import { usePageSize } from "../../components/withPageSize";

function RegisterPage() {
  const recaptchaToken = useRef(null);
  const recaptchaRef = useRef(null);
  const buttonRef = useRef(null);
  const formRef = useRef(null);
  const errorRef = useRef(false);

  const { user, setUser } = useUser();
  const axios = useClient();

  const width = usePageSize();

  const description = useMemo(() => {
    const desc = JSON.parse(JSON.stringify(description_));

    if (width <= 1000){
      desc.questions.forEach(q => q.space = '100%')

      return desc;
    }

    return desc;
  }, [width]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recaptchaToken.current) {
      formRef.current.clearSanityCheck();
      errorRef.current = false;
      const errors = formRef.current.sanityCheckForm();

      if (!errors.length) {
        buttonRef.current.setLoading(true);

        const { firstName, lastName, email, password } =
          formRef.current.getData();

        axios
          .post(`/user/register`, {
            firstName,
            lastName,
            email,
            password,
            recaptchaToken: recaptchaToken.current,
          })
          .then((res) => {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.token}`;
            errorRef.current = false;
            setUser(res.data.user);
          })
          .catch((err) => {
            console.log(err.response.data.error);
            recaptchaRef.current.reset();
            recaptchaToken.current = null;
            errorRef.current = true;
          })
          .finally(() => {
            buttonRef.current?.setLoading(false);
            formRef.current?.clearSanityCheck();
            formRef.current?.sanityCheckForm();
          });
      }
    }
  };

  const recaptchaOnChange = (value) => (recaptchaToken.current = value);

  useEffect(() => {
    Form.registerRestrictionCheck({
      tag: "validateRegistration_Register",
      func: () => {
        return !errorRef.current;
      },
      explanation:
        "Αυτό το email ήδη χρησιμοποιείται από κάποιο λογαριασμό στο σύστημα",
    });
  }, []);

  if (user) {
    return <Navigate to="/"></Navigate>;
  }

  return (
    <div className={styles.wholePage}>
      <div className={styles.container}>
        <div className={styles.title}> Εγγραφή </div>
        <div className={styles.content}>
          <Form ref={formRef} description={description} />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
            onChange={recaptchaOnChange}
            size={width <= 400 ? 'compact' : 'normal'}
            className={styles.recaptcha}
          ></ReCAPTCHA>
        </div>
        <div className={styles.bottom}>
          <div className={styles.loginInstead}>
            <div className={styles.alreadyRegistered}>
              Έχετε ήδη λογαριασμό;
            </div>
            <Link className="link" to="/login">
              Σύνδεση
            </Link>
          </div>
          <ButtonWithLoading ref={buttonRef} onClick={handleSubmit} className={styles.registerButton}>
            Εγγραφή
          </ButtonWithLoading>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
