import { SiMicrosoftexcel } from "react-icons/si";
import styles from "../styles/DownloadCsvButton.module.css";
import { useCSVDownloader } from 'react-papaparse';

export const DownloadCsvButton = (props) => {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <CSVDownloader
      className={styles.csv}
      type={Type.Button}
      filename={props.filename || 'Πίνακας'}
      bom
      config={
        {
          delimiter: ';',
        }
      }
      data={[
        props.headers,
        ...props.data
      ]}
    >
      Λήψη
      <SiMicrosoftexcel />
    </CSVDownloader>
  );
}
