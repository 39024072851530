import ErrorPrompt from './ErrorPrompt';
import { Input } from './Input';

// ---------------------------------------------------------------------------------------

export class NumberInput extends Input {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e, onFinish) {
        const value_ = Number.parseInt(e.target.value);
        const value = isNaN(value_) ? null : value_;

        this.valueUpdate(this.state.value, value);
        this.setState({ value: value }, onFinish);
    }

    readOnlyRender() {
        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap'>{this.state.value || '-'}</div>
            </>
        );
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div style={this.props.style}>
                    <input
                        aria-label={this.props.description.title || this.props.description.name}
                        className={`form-item-input`}
                        disabled={this.props.description.isDisabled}
                        name={this.props.description.name}
                        value={this.state.value !== null ? this.state.value : ''}
                        placeholder={this.placeholder}
                        onChange={this.onChange}
                        type='number'
                    />
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}

// ---------------------------------------------------------------------------------------
