import businessInfoJson from "../../../assets/data/business-info.json";
import { ApplicationForm } from "../../../components/ApplicationPageUtils/ApplicationForm";
import unit2municipality from "../../../assets/data/unit2municipality.json";
import region2unit from "../../../assets/data/region2unit.json";

export const BusinessInfoForm = ({
    info,
    formRef,
    readOnly,
    onDirtyChange
}) => {

    const dependancies = [
        {
            path: /^.*address\/area+$/,
            dependent: 'regionalUnit',
            options: (value) => {
                return region2unit[value];
            }
        },
        {
            path: /^.*address\/regionalUnit+$/,
            dependent: 'municipality',
            options: (value) => {
                return unit2municipality[value];
            }
        }
    ]

    return (
        <ApplicationForm
            formRef={formRef}
            description={businessInfoJson}
            onDirtyChange={onDirtyChange}
            application={info}
            readOnly={readOnly}
            dependancies={dependancies}
            DownloadFileUrl={'/business/application/file/download'}
        />
    );

}