import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";
import Select from "react-select";

// ---------------------------------------------------------------------------------------

export class DropdownInput extends Input {

    constructor(props) {

        super(props);

        this.state.choices = props.description.choices || [];

        this.onChange = this.onChange.bind(this);
        this.setOptions =  this.setOptions.bind(this);

        this.colourStyles = {
            placeholder: styles => ({
                ...styles,
                color: '#727272'
            }),
            dropdownIndicator: (styles, options) => ({
                ...styles,
                color: '#727272',
                "&:hover": {
                    color: "#545454"
                }
            }),
            control: (styles) => {
                return {
                    ...styles,
                    outline: 'none'
                }  
            },
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                width: 'calc(100% - 8px)',
                marginLeft: '4px',
                marginRight: '4px',
                marginTop: '4px',
                marginBottom: '4px',
                borderRadius: '3px',
                backgroundColor: isSelected ? 'var(--primary)' : (isFocused)? 'lightgrey': 'white',
                color: isSelected ? 'var(--main-theme-text-over-primary)' : 'black',
                // color: isSelected ? 'var(--main-theme-text-over-primary)' : (data.value !== null)?'black': 'grey',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              };
            },
          };

    }
    

    setOptions(choices) {
        this.setState({
            choices
        });
    }

    getOptions() {
        const options = [];

        if(!this.props.description.isRequired)
            options.push({ value: null, label: '-'});
            
        options.push(...this.state.choices.map(
            opt => (
                {value: opt.value, label: opt.title || opt.name}
            )
        ));
        
        return options;
    }

    onMenuOpen() {
        setTimeout(()=>{
            const selectedEl = document.getElementsByClassName("MyDropdown__menu")[0];
            if(selectedEl){
                selectedEl.scrollIntoView({ block: "nearest", inline: "nearest"});
            }
        }, 15);
    };

    getData() {
        if(this.state.value === 'default') 
            return null;
        return this.state.value;
    }

    onChange(e, onFinish) {
        const value = (e.target)? e.target.value: e.value;
        this.valueUpdate(this.state.value, value);
        if(!e.target) {
            this.setState({ value });
        } else {
            this.setState({ value }, onFinish);
        }
    }

    readOnlyRender() {
        const options = this.getOptions();
        
        const selectedOption = options.find(c => c.value === this.state.value) || null;

        return <>
            {this.props.label}
            <div className="readOnly-value-wrap">
                {selectedOption ? selectedOption.label : '-'}
            </div>
        </>
    }

    render() {

        if(this.state.isHidden) {
            return null;
        }

        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        const options = this.getOptions();
        
        const selectedOption = options.find(c => c.value === this.state.value) || null;
        
        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <div className="form-group" style={this.props.style}>
                <label style={{width: '100%'}} aria-label={this.props.description.title || this.props.description.name}>
                    <Select
                        value={selectedOption}
                        options={options}
                        placeholder={'Επιλογή...'}
                        styles={this.colourStyles}
                        onChange={this.onChange}
                        onMenuOpen={this.onMenuOpen}
                        isDisabled={this.props.description.isDisabled}
                        className ={"MyDropdown"}
                        classNamePrefix={"MyDropdown"}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: '0.25rem',
                            colors: {
                                ...theme.colors,
                                primary: 'var(--primary)',
                            },
                        })}
                    >
                    </Select>
                </label>
            </div>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>

    }

};

// ---------------------------------------------------------------------------------------