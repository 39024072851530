import { useState, useRef, useCallback } from "react";
import { AcceptRejectModal } from "../AcceptRejectModal";
import { useClient } from "../ClientWrapper";
import { useToasts } from "../ToastMessageWrapper";
import { MdCancel } from "react-icons/md";

export const UnfinalizeButton = ({
  unfinalizeUrl,
  redirectUrl
}) => {
  const addToast = useToasts();
  const buttonRef = useRef();
  const axios = useClient();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const onUnfinalize = async e => {
    try {
      var res = await axios.post(unfinalizeUrl);
    }
    catch (err) {
      throw "Υπήρξε κάποιο πρόβλημα με την ακύρωση της οριστικοποίησης της αίτησης σας. Παρακαλώ ξαναπροσπαθήστε.";
    }

    return res;
  }

  const onSubmitSuccess = useCallback(res => {
    addToast({
      type: "success",
      message: "Ακυρώσατε την οριστικοποίηση της αίτησης σας.",
      duration: 5000,
    });

    if (redirectUrl) window.location.href = redirectUrl;
  }, [])

  const onSubmitFailure = useCallback(err => {
    addToast({
      type: "failure",
      message: err,
      duration: 5000,
    });
  }, []);

  const onClose = () => {
    setModalIsVisible(false);
  }

  return (
    <>
      <button
        className='defaultButton'
        onClick={() => setModalIsVisible(true)}
        ref={buttonRef}
      >
        <MdCancel />
        Ακύρωση Οριστικοποίησης
      </button>
      {
        modalIsVisible &&
        <AcceptRejectModal
          title='Ακύρωση Οριστικοποίησης Αίτησης'
          message={
            <>
              Είστε σίγουροι ότι θέλετε να ακυρώσετε την οριστικοποίηση της αίτησης σας;
              <br /> <br />
              Μετά την ακύρωση οριστικοποίησης θα μπορείτε να επεξεργαστείτε ξανά την αίτηση σας, ωστόσο για τη συμμετοχή σας στο πρόγραμμα
              θα πρέπει να την οριστικοποιήσετε ξανά πριν τη λήξη της προθεσμίας.
              <br /> <br />
              Επισημαίνεται ότι η συμμετοχή στο πρόγραμμα πρακτικής κρίνεται απο την ημερομηνία της τελευταίας υποβολής.
            </>
          }
          continueButtonText='Συνέχεια'
          cancelButtonText='Κλείσιμο'
          submit={onUnfinalize}
          onSubmitFailure={onSubmitFailure}
          onSubmitSuccess={onSubmitSuccess}
          onClose={onClose}
        />
      }
    </>
  );
}