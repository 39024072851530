import React, { useEffect } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Business/BusinessInfo.module.css";
import { VscTriangleRight } from "react-icons/vsc";
import { FRONTEND_URI } from "../../../components/ClientWrapper";
import { FaSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

const KYA_PATH = `${FRONTEND_URI}/KYA_ΠρακτικήΆσκηση.pdf`;
const DETAILED_INFO_PATH = `${FRONTEND_URI}/ΑναλυτικηΠεριγραφη.pdf`; //δεν έχει για τις επιχειρήσεις?
const MY_APPS_PATH = `/business/application`;
const MANUAL_PATH = `${FRONTEND_URI}/ΕγχειρίδιοΧρήσηςΕπιχειρήσεων.pdf`;
const CONTACT_PATH = `${FRONTEND_URI}/Επικοινωνία.pdf`;

export const BusinessInfo = () => {
  return (
    <Panel headerBar="Πληροφορίες">
      <div className={styles.infoContainer}>
        <div className={styles.infoSegment}>
          <div className={styles.title}> Το έργο «Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης επαγγελματικής εμπειρίας» </div>
          <div className={styles.info}>
            Σκοπός του έργου με τίτλο «Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης επαγγελματικής εμπειρίας» αποτελεί η ανάπτυξη ενός ολοκληρωμένου συστήματος ένταξης των εκπαιδευόμενων Ι.Ε.Κ., στην αγορά εργασίας, μέσω της απόκτησης επαγγελματικής εμπειρίας με πρακτική άσκηση. Απευθύνεται σε 2.000 εκπαιδευόμενους από δημόσια Ι.Ε.Κ. του Υπουργείου Παιδείας και Θρησκευμάτων και ιδιωτικά Ι.Ε.Κ., οι οποίοι έχουν ολοκληρώσει το θεωρητικό μέρος των σπουδών τους, ενώ δεν έχουν πραγματοποιήσει το εξάμηνο της πρακτικής άσκησης και ως εκ τούτου δεν έχουν λάβει την αντίστοιχη πιστοποίηση από τον Ε.Ο.Π.Π.Ε.Π. Αφορά τόσο τους ωφελούμενους που ξεκινούν για πρώτη φορά την πρακτική τους άσκηση όσο και αυτούς που, για οποιοδήποτε λόγο, διέκοψαν ή δεν πρόλαβαν να ολοκληρώσουν την πρακτική τους άσκηση.
          </div>
          <div className={styles.info}>
            Το έργο υλοποιείται από σύμπραξη φορέων των κοινωνικών εταίρων: ΙΝΕ & ΚΑΝΕΠ ΓΣΕΕ, ΙΜΕ/ ΓΣΕΒΕΕ, ΚΑΕΛΕ/ ΕΣΕΕ, καθώς και ΕΟΠΠΕΠ με Δικαιούχο-Συντονιστή Φορέα το ΙΝΕ/ ΓΣΕΕ. Οι φορείς του έργου αναλαμβάνουν την υλοποίηση του στις περιφέρειες εφαρμογής (διαφορετικός φορέας ανά περιφέρεια).
          </div>
          <div className={styles.title}> Καινοτομικά στοιχεία του έργου </div>
          <div className={styles.info}>
            Τα καινοτομικά στοιχεία του έργου αναφέρονται κυρίως στη μεθοδολογία υλοποίησης της πρακτικής άσκησης που εστιάζει σε ποιοτικά στοιχεία στα επίπεδα:
            <br></br><br></br>
            - Της σύζευξης ωφελούμενων και επιχειρήσεων (σύμβουλοι σύζευξης που διερευνούν τη συμβατότητα ωφελούμενου – θέσης πρακτικής, με εργαλεία σύζευξης και ατομικές συνεδρίες)<br></br>
            - Της διερεύνησης των αναγκών, προσδοκιών και δυνατοτήτων των ωφελούμενων αλλά και της ικανοποίησης τους ή όχι από τη θέση πρακτικής (μέντορες υποστήριξης)<br></br>
            - Της διερεύνησης των συνθηκών υλοποίησης της πρακτικής άσκησης, ώστε να αποφεύγονται καταχρηστικές πρακτικές από την πλευρά των επιχειρήσεων, να διασφαλίζεται ο εκπαιδευτικός χαρακτήρας της πρακτικής άσκησης κλπ. (Ποιοτικός έλεγχος πρακτικής άσκησης)
          </div>
          <div className={styles.title}> Δράσεις του έργου </div>
          <div className={styles.infoBullets}>

            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Πρόσκληση εκπαιδευόμενων και επιχειρήσεων για συμμετοχή στο έργο
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Επιλογή 2.000 εκπαιδευόμενων  ΙΕΚ, ως εξής:<br></br>
              </div>
            </div>
            <div className={styles.infoBullets}>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Ανατολική Μακεδονία / Θράκη 178 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Δυτική Ελλάδα 314 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στη Δυτική Μακεδονία 40 εκπαιδευόμενοι (ΙΝΕ ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βόρειο Αιγαίο, 41 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Ήπειρο 118 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Θεσσαλία 313 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στα Ιόνια Νησιά 22 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βόρειο Αιγαίο, 41 εκπαιδευόμενοι (ΙΝΕ/ΓΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στη Στερεά Ελλάδα 128 εκπαιδευόμενοι (ΙΜΕ/ΓΣΕΒΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Κεντρική Μακεδονία 270 εκπαιδευόμενοι (ΚΑΕΛΕ/ΕΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Πελοπόννησο 100 εκπαιδευόμενοι (ΚΑΕΛΕ/ΕΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Κρήτη 120 εκπαιδευόμενοι (ΚΑΕΛΕ /ΕΣΕΕ)
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Στην Αττική 314 εκπαιδευόμενοι (ΚΑΝΕΠ/ΓΣΕΕ)
                </div>
              </div>
            </div>


            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Παροχή εξ αποστάσεως ασύγχρονης θεωρητικής κατάρτισης 25 ωρών σε οριζόντιες επαγγελματικές και κοινωνικές δεξιότητες προς όλους τους εκπαιδευόμενους που θα επιλεγούν. Οι θεματικές ενότητες που θα διδαχθούν είναι οι εξής:
              </div>
            </div>
            <div className={styles.infoBullets}>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Επαγγελματικό περιβάλλον & δεοντολογία
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Επικοινωνιακές δεξιότητες και διαχείριση συγκρούσεων
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Υγεία και ασφάλεια στην εργασία
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Δεξιότητες πλοήγησης στην αγορά εργασίας
                </div>
              </div>
              <div className={styles.infoBullet}>
                <VscTriangleRight className={styles.bullet} />
                <div className={styles.info}>
                  Βασικές αρχές λειτουργίας των επιχειρήσεων
                </div>
              </div>
            </div>
            <div className={styles.underlinedInfo}>
              Επισημαίνεται ότι η επιτυχής ολοκλήρωση της θεωρητικής κατάρτισης αποτελεί απαραίτητη προϋπόθεση για την περαιτέρω συμμετοχή των εκπαιδευόμενων στο έργο.
            </div>

            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Συμβουλευτική υποστήριξη των εκπαιδευόμενων, από την εισαγωγή τους στο πρόγραμμα και σε όλη τη διάρκεια της θεωρητικής τους εκπαίδευσης και της πρακτικής τους άσκησης από μέντορες συμβουλευτικής υποστήριξης. (8 συνεδρίες συμβουλευτικής εκπαιδευόμενο)
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Σύζευξη / τοποθέτηση των 2.000 εκπαιδευόμενων Ι.Ε.Κ. με αντίστοιχες θέσεις σε επιχειρήσεις βάσει συγκεκριμένου συστήματος/μεθοδολογίας. Εξειδικευμένοι σύμβουλοι εκπαιδευομένων και επιχειρήσεων (τακτικό και έκτακτο προσωπικό των εταίρων του έργου) με τη χρήση ειδικά διαμορφωμένων εργαλείων θα εξειδικεύσουν το ταίριασμα του προφίλ των εκπαιδευομένων με τις προσφερόμενες θέσεις πρακτικής άσκησης.
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Υλοποίηση πρακτικής άσκησης διάρκειας 960 ώρες (έως 6 μήνες) για τους εκπαιδευόμενους του προγράμματος.
              </div>
            </div>
            <div className={styles.infoBullet}>
              <FaSquare className={styles.bullet} />
              <div className={styles.info}>
                Παρακολούθηση και ποιοτικός έλεγχος της διαδικασίας πρακτικής άσκησης.
              </div>
            </div>
          </div>

          <div className={styles.title}>H πρακτική άσκηση και η συμμετοχή των επιχειρήσεων</div>
          <div className={styles.info}>Η πρακτική άσκηση καθώς και η συμμετοχή ωφελούμενων και επιχειρήσεων στο έργο γίνεται βάση του  <a href={`${KYA_PATH}`} target="_blank" rel="noopener noreferrer">ΦΕΚ 3938/Β/26-8-2021 Κοινή Υπουργική Απόφαση υπ’ αριθμ. Κ5/97484.</a>
            <br></br><br></br>
            Στο πλαίσιο του έργου καλούμε τις επιχειρήσεις για προσφορά θέσεων πρακτικής άσκησης στους απόφοιτους των ΙΕΚ που έχουν ολοκληρώσει τη θεωρητική τους εκπαίδευση και που θα κάνουν αντίστοιχα την αίτησή τους για συμμετοχή στο έργο. Η σύζευξη των εκπαιδευόμενων με τις προσφερόμενες θέσεις πρακτικής θα γίνει βάση τα κριτήρια : ειδικότητα, πόλη, περιφέρεια των αιτούμενων, μέσω της ηλεκτρονικής πλατφόρμας και θα εξειδικευτεί περαιτέρω από τους σύμβουλους του έργου.
            <br></br>
            Η πρακτική άσκηση θα έχει διάρκεια 960 ώρες (6 μήνες).
          </div>
          <div className={styles.title}>Κριτήρια συμμετοχής των επιχειρήσεων</div>

          <div className={styles.info}>
            Τα κριτήρια για συμμετοχή των επιχειρήσεων που παρέχουν θέσεις πρακτικής (κριτήρια on/ off) σύμφωνα με το <a href={`${KYA_PATH}`} target="_blank" rel="noopener noreferrer">ΦΕΚ 3938/Β/26-8-2021 Κοινή Υπουργική Απόφαση υπ’ αριθμ. Κ5/97484</a> είναι τα εξής:
            <br></br><br></br>
            Η πρακτική άσκηση δύναται να πραγματοποιείται σε θέσεις που προσφέρονται από φυσικά πρόσωπα, Ν.Π.Δ.Δ., Ν.Π.Ι.Δ., δημόσιες υπηρεσίες, Ο.Τ.Α. α’ και β’ βαθμού και επιχειρήσεις, οι οποίοι στο πλαίσιο της παρούσας κοινής υπουργικής απόφασης καλούνται «εργοδότες», με τους όρους και τις προϋποθέσεις του ν.4763/2020 (Α’ 254) και του Κανονισμού Λειτουργίας των Ι.Ε.Κ., και με ευθύνη του Ι.Ε.Κ. στο οποίο φοιτούν.
            <br></br>
            - Εξαιρούνται οι φορείς:
          </div>
          <div className={styles.infoBullets}>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Προσωρινής απασχόλησης
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Τα νυχτερινά κέντρα
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Παροχής καθαριότητας και φύλαξης
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Τα πρακτορεία τυχερών παιχνιδιών
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Κάθε επιχείρηση στην οποία δεν είναι εφικτός ο έλεγχος της εκπαίδευσης από τον αρμόδιο φορέα.
              </div>
            </div>
          </div>
          <div className={styles.info}>
            Ο ανώτατος αριθμός πρακτικά ασκούμενων ανά εργοδότη εξαρτάται από τον αριθμό των εργαζομένων, όπως αυτός παρουσιάζεται στην ετήσια κατάσταση προσωπικού προς την Επιθεώρηση Εργασίας.
            <br></br>
            Ειδικότερα:
            <br></br>
          </div>
          <div className={styles.infoBullets}>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Οι ατομικές επιχειρήσεις, χωρίς κανέναν εργαζόμενο, μπορούν να δέχονται έναν (1) πρακτικά ασκούμενο
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Οι εργοδότες που απασχολούν 1-10 άτομα μπορούν να προσφέρουν θέσεις πρακτικής άσκησης που αντιστοιχούν στο 25% (1-2 άτομα) των εργαζόμενων εξαρτημένης εργασίας.
                <br></br>
                Ειδικότερα για εργοδότες που απασχολούν 1-5 άτομα το αποτέλεσμα της ποσόστωσης στρογγυλοποιείται προς τα πάνω, όπως και για εργοδότες που απασχολούν από 6-10 άτομα
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Οι εργοδότες που απασχολούν από 10 και πάνω εργαζόμενους μπορούν να δέχονται πρακτικά ασκούμενους που αντιστοιχούν στο 17% των εργαζομένων εξαρτημένης εργασίας, με ανώτατο όριο τα 40 άτομα σε κάθε περίπτωση.
              </div>
            </div>
            <div className={styles.infoBullet}>
              <VscTriangleRight className={styles.bullet} />
              <div className={styles.info}>
                Οι εργοδότες που απασχολούν πάνω 250 εργαζόμενους μπορούν να δέχονται πρακτικά ασκούμενους που αντιστοιχούν στο 17% των εργαζομένων εξαρτημένης εργασίας ανά υποκατάστημα, με ανώτατο όριο τα 40 άτομα σε κάθε περίπτωση, αν ο αριθμός που προκύπτει από την ποσόστωση είναι μεγαλύτερος. Τα νομικά πρόσωπα δημοσίου δικαίου, τα οποία δεν διαθέτουν υποκαταστήματα, μπορούν να δέχονται πρακτικά ασκούμενους που αντιστοιχούν στο 17% των υπαλλήλων τους.
              </div>
            </div>
          </div>
          <div className={styles.info}>
            Σε περίπτωση που ο εργοδότης παρέχει παράλληλα θέσεις μαθητείας ή πρακτικής άσκησης άλλων εκπαιδευτικών βαθμίδων τα ανωτέρω ποσοστά λειτουργούν σωρευτικά, γι αυτό και καλούνται οι επιχειρήσεις να δηλώσουν και τυχόν θέσεις που καλύπτονται ήδη από πρακτικά ασκούμενους
          </div>

          <div className={styles.title}>Ορισμός επόπτη πρακτικής άσκησης</div>

          <div className={styles.info}> 
            Οι επιχειρήσεις που παρέχουν θέσεις πρακτικής άσκησης οφείλουν να δηλώσουν στην αίτηση τους Επόπτη πρακτικής άσκησης. Όπως αναφέρεται στο ΦΕΚ 3938/Β/26-8-2021 Κοινή Υπουργική Απόφαση υπ’ αριθμ. Κ5/97484: Ο εργοδότης ορίζει έμπειρο στέλεχος συναφούς επαγγελματικής ειδικότητας με τον πρακτικά ασκούμενο ως «εκπαιδευτή στο χώρο εργασίας», ο οποίος αναλαμβάνει την αποτελεσματική υλοποίηση των εκπαιδευτικών δραστηριοτήτων στο χώρο εργασίας, και την παρακολούθηση της προόδου του πρακτικά ασκούμενου.
          </div>
          <div className={styles.title}>Η υποβολή της αίτησης</div>

          <div className={styles.info}>
            Η αίτηση συμμετοχής πραγματοποιείται ηλεκτρονικά στην πλατφόρμα  <Link to={`${MY_APPS_PATH}`}>εδώ</Link><br></br>
            Για την ολοκλήρωση της αίτησής θα χρειαστεί να επισυνάψετε την ετήσια κατάσταση προσωπικού της επιχείρησης σας (αρχείο pdf)
            <br></br><br></br>
            Η πρόσκληση είναι ανοιχτή και μπορείτε να κάνετε την αίτηση σας, μέχρι να συμπληρωθούν οι διαθέσιμες θέσεις και η σύζευξη τους με τους εκπαιδευόμενους ανά περιφέρεια. Οι επιχειρήσεις γίνονται δεκτές με βάση τη χρονική σειρά κατάθεσης της αίτησης τους και εφόσον πληρούν τα κριτήρια συμμετοχής.
            <br></br><br></br>
            Μετά την υποβολή και οριστικοποίηση της αίτησής θα σας δοθεί ο Κωδικός Αριθμός Υποβολής Αίτησης Συμμετοχής (ΚΑΥΑΣ)
            <br></br>
            Παρακαλούμε σημειώστε τον (ΚΑΥΑΣ) γιατί θα τον χρειαστείτε για κάθε μελλοντική αναφορά στην αίτησή σας.
            <br></br><br></br>

            Πριν την ένταξή σας στο έργο θα πραγματοποιηθεί έλεγχος των στοιχείων που έχετε αναρτήσει, από τον αντίστοιχο πάροχο κατάρτισης στην περιφέρειά σας και σε περίπτωση αρνητικού ελέγχου, η αίτησή σας θα απορριφθεί.<br></br>

            Μπορείτε να δείτε αναλυτικά τις <a href={`${MANUAL_PATH}`} target="_blank" rel="noopener noreferrer">οδηγίες για τη χρήση της πλατφόρμας και τη συμπλήρωση της αίτησης σας εδώ.</a>
            <br></br><br></br>
            Σε περίπτωση που αντιμετωπίζετε οποιοδήποτε πρόβλημα σχετικά με την υποβολή της αίτησής σας παρακαλούμε <a href={`${CONTACT_PATH}`} target="_blank" rel="noopener noreferrer">επικοινωνήστε</a> με τα κατά τόπους παραρτήματα των φορέων υλοποίησης
            <br></br>
          </div>

        </div>
      </div>

      <div className={styles.infoSegment}>
        <div className={styles.title}> Απαιτούμενα Δικαιολογητικά </div>
        <div className={styles.infoBullets}>
          <div className={styles.infoBullet}>
            <VscTriangleRight className={styles.bullet} />
            <div className={styles.info}>Ετήσια κατάσταση προσωπικού της επιχείρησης σας (αρχείο pdf)</div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
