import React from "react";
import styles from "../styles/File.module.css";
import { BsFillFileEarmarkPdfFill, BsDownload } from 'react-icons/bs'
import { DispatchFileIcon } from '../react-form/src/form-inputs/FileInput'

export default function File({ name, onClick }) {
  return (
    <div className={styles.file} onClick = {onClick}>
      <span className={styles.fileIcon}> { DispatchFileIcon(name) } </span>
      <span className={styles.fileName}> {name} </span>
      <BsDownload className={styles.download} />
    </div>
  );
}
