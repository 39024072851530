import React, { useState } from 'react'
import styles from '../styles/Sidebar.module.css'
import { UserInfo } from './Profile'
import { useUser } from './UserWrapper'

export const Sidebar = ({ options }) => {
  const { user } = useUser();

  const select = (option) => {    
    option.onClick && option.onClick();
  };

  return user && (
    <div className={styles.sidebar}>
      <div className={styles.userInfo}>
        <UserInfo
          firstName={user.firstName}
          lastName={user.lastName}
          email={user.email}
        />
      </div>
      <div className={styles.options}>
        {
          options.map(
            (option, i) => {
              const { Icon, text } = option;

              return (
                <div
                  key={i}
                  className={
                    option.selected ?
                      `${styles.option} ${styles.selected}` :
                      `${styles.option}`
                  }
                  onClick = {() => select(option)}
                >
                  <Icon className={styles.icon} />
                  <div className={styles.text}> {text} </div>
                </div>
              );
            }
          )
        }
      </div>
    </div>
  );
}