import React, { createContext, useContext, useState } from "react";

export const PageInfoContext = createContext();

export const usePageInfo = () => useContext(PageInfoContext);

export function PageInfoWrapper({ children }) {
  const [info, setPageInfo] = useState(
    <>
      <div> Κεντρική Διαδικτυακή Πύλη </div>
      {
        "«Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης επαγγελματικής εμπειρίας (ΙΕΚ)» (MIS) 5069416"
      }
    </>
  );

  function getPageInfo() {
    return info;
  }

  const [options, setNavOptions] = useState([]);

  function getNavOptions() {
    return options;
  }
  return (
    <PageInfoContext.Provider value={{ getPageInfo, setPageInfo, setNavOptions, getNavOptions }}>
      {children}
    </PageInfoContext.Provider>
  );
}
