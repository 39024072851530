import ErrorPrompt from "./ErrorPrompt";
import { Input } from "./Input";

// ---------------------------------------------------------------------------------------

export class RadioInput extends Input {

    constructor(props) {

        super(props);

        this.onChange = this.onChange.bind(this);

    }

    onChange(e, onFinish) {
        this.valueUpdate(this.state.value, e.target.value);
        this.setState({ value: e.target.value }, onFinish);
    }

    readOnlyRender() {
        const ch = this.props.description.choices.find((c) => c.value === this.state.value);
        return <>
            {this.props.label}
            <div className="readOnly-value-wrap">{ch?ch.title: '-'}</div>
        </>
    }

    render() {

        if(this.state.isHidden) {
            return null;
        }
        
        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        this.choices = this.props.description.choices.map((c, i) => {
            const value = c.value || i;
            const isChecked = value === this.state.value;
            return <div key={`${this.props.description.title}-${i}`}>
                <input id={`${this.props.description.name}-${i}`} className="form-item-input-check" disabled={this.props.isDisabled} style={{marginRight: '6px', cursor: 'pointer'}} checked={isChecked} value={value} onChange={this.onChange} type="radio"/>
                <label style={{cursor: 'pointer'}} htmlFor={`${this.props.description.name}-${i}`}> {c.title || c.value} </label>
            </div>
        });
        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem?'-error':''}`}>{this.props.label}</div>
            <div name={this.props.description.title} style={this.props.style}>
                {this.choices}
            </div>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
    }

};

// ---------------------------------------------------------------------------------------