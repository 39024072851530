import React from "react";
import { createPortal } from "react-dom";
import styles from "../styles/Modal.module.css";
import { BsX } from "react-icons/bs";

function SimpleHeader({ title, onClose }) {
  return (
    <div className={styles.simpleHeader}>
      {title}
      <BsX onClick={onClose} className={styles.close}></BsX>
    </div>
  );
}

export default function Modal({ header, content, footer, onClose, className, style }) {
  const header_ = typeof header === 'string' ?
    <SimpleHeader title= {header} onClose = {onClose}/>:
    header;

  return createPortal(
    <div className={styles.container}>
      <div className={styles.overlay} />

      <div className={`${styles.modal} ${className}`} style={style}>
        {
          header &&
          <div className={styles.header}>
            {header_}
          </div>
        }
        {
          content &&
          <div className={styles.content}>
            {content}
          </div>
        }
        {
          footer &&
          <div className={styles.footer}>
            {footer}
          </div>
        }
      </div>
    </div>,
    document.getElementById('root')
  );
}
