import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useClient } from "../../../components/ClientWrapper";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Trainee/TraineeRegistryMatches.module.css";
import { IoIosArrowBack } from "react-icons/io";
import PositionInfo from "../../../components/PositionInfo";
import { TraineeApplicationForm } from "../TraineeApplication/TraineeApplicationForm";
import { DownloadCsvButton } from "../../../components/DownloadCsvButton";

function TraineeRegistryMatches() {
    const { appId } = useParams();
    const axios = useClient();

    const [app, setApplication] = useState(null);
    const [matchedPositionsCsv, setMatchedPositionsCsv] = useState({ data: [] });

    const appFormRef = useRef();

    useEffect(() => {
        getApplication();
    }, []);

    const getApplication = () => {
        axios
            .get(`/trainee/application/${appId}`)
            .then((res) => {
                res.data.application.programInfo = { program: res.data.application?.program._id };

                setMatchedPositionsCsv(
                    generateMatchedPositionsCsv(res.data.application)
                )
                setApplication(res.data.application || {});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const generateMatchedPositionsCsv = (app) => {
        if (!app.matchedPositions)
            return;


        const headers = [
            'Επωνυμία',
            'Περιφέρεια',
            'Δήμος',
            'Πόλη',
            'Οδός',
            'Τ.Κ.',
            'Ζητούμενος Αριθμός Ασκούμενων',
            'Ζητούμενες Ειδικότητες',
        ];

        const data = [];

        for (const position of app.matchedPositions) {
            if (!position) return;

            const address = position.useDefaultAddress ?
                position.businessApplication.businessInfo.address
                :
                position.address
                ;

            data.push([
                position.businessApplication.businessInfo.basicInfo.name,
                address.area,
                address.municipality,
                address.city,
                `${address.street} ${address.streetNumber}`,
                address.postalCode,
                position.askedCount,
                position.iekSpecialities.join(','),
            ])
        }

        return { headers, data };
    }

    return (
        <Panel headerBar="Πληροφορίες Σύζευξης">
            {
                app &&
                <div className={styles.container}>
                    <div className={styles.titleContainer}>
                        <Link className="link" to="../all">
                            <IoIosArrowBack></IoIosArrowBack>
                            Πίσω
                        </Link>
                        <h2> Αίτηση του χρήστη {app.personalInfo.firstName} {app.personalInfo.lastName}</h2>
                        <div className={styles.csvContainer}>
                            {matchedPositionsCsv && <DownloadCsvButton
                                headers={matchedPositionsCsv.headers}
                                data={matchedPositionsCsv.data}
                                filename={`Σύζευξη_${app.kayas}`}
                            />}
                        </div>
                    </div>
                    <div className={styles.contentContainer}>
                        <div className={styles.left}>
                            <div className={styles.positionsContainer}>
                                {app && app.matchedPositions && app.matchedPositions.map((position, i) =>
                                    <div key={i}>
                                        <PositionInfo position={position} />
                                    </div>
                                )}
                                {
                                    app && !!app.rankingDate && app.matchedPositions.length === 0 &&
                                    <div> Δεν βρέθηκαν θέσεις πρακτικής που να αντιστοιχούν στις ειδικότητες του χρήστη </div>
                                }
                                {
                                    app && !app.rankingDate &&
                                    <div> Δεν έχετε εφαρμόσει κατάταξη και σύζευξη για αυτήν την αίτηση </div>
                                }
                            </div>

                        </div>
                        <div className={styles.right}>
                            <TraineeApplicationForm
                                formRef={appFormRef}
                                application={app}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            }
        </Panel>)
}

export default TraineeRegistryMatches