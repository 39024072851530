import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { useClient } from "../../../components/ClientWrapper";
import { Panel } from "../../../components/Panel";
import styles from "../../../styles/Trainee/TraineeApplication.module.css";
import {
  ToastMessage,
  useToasts,
} from "../../../components/ToastMessageWrapper";
import { TraineeApplicationForm } from "./TraineeApplicationForm";
import { FinalizeButton } from "../../../components/ApplicationPageUtils/ApplicationFinalizeButton";
import { SaveApplicationButton } from "../../SaveApplicationButton";
import { ModalBlocker } from "../../../components/ModalBlocker";
import { UnfinalizeButton } from "../../../components/ApplicationPageUtils/ApplicationUnfinalizeButton";
import { DeletionButton } from "../../../components/ApplicationPageUtils/ApplicationDeletionButton";
import { ProgramSelection } from "../../../components/ApplicationPageUtils/Program";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Link } from "react-router-dom";

export const TraineeApplication = () => {
  const FILE_PATHS = useRef({
    gradingInfo: [
      "iekGradeFile",
      "unemployementCardFile",
      "salaryCriteriaFile",
    ],
    documents: ["iekFile", "nonParticipationFile"],
  });

  const axios = useClient();
  const [application, setApplication] = useState(null);

  const [programs, setPrograms] = useState(null);
  const selectedProgram = useRef(null);
  const buttonRef = useRef(null);
  const addToast = useToasts();

  const [dirty, setDirty] = useState(false);
  const formRef = useRef();

  const getPrograms = () => {
    axios
      .get("/program?isPublished=true&isCompleted=false&inTraineeDeadline=true")
      .then((res) => {
        const programs = res.data || [];

        programs.forEach((program) => {
          program.deadlines.startDate = program.deadlines.traineeStartDate;
          program.deadlines.endDate = program.deadlines.traineeEndDate;
        });

        setPrograms(programs);
      })
      .catch((err) => { });
  };

  const getApplication = async () => {
    try {
      var res = await axios.get(`/trainee/application/`);
    } catch (err) {
      console.log(err);
      return;
    }

    const application_ = res.data.application;

    if (application_) {
      deleteGradingCriteriaIfProgramHasNone(application_.program);
      setApplication(application_);
    } else getPrograms();
  };

  const deleteGradingCriteriaIfProgramHasNone = (program) => {
    const gradingCriteria = program.gradingInfo;

    if (
      !gradingCriteria ||
      Object.values(gradingCriteria).every((criteria) => !criteria)
    ) {
      delete FILE_PATHS.current.gradingInfo;
    }
  };

  const createApplication = async () => {
    const program = selectedProgram.current;

    buttonRef.current?.setLoading(true);

    try {
      const { data: application_ } = await axios.post("/trainee/application", {
        program: program._id,
      });

      if (application_) {
        deleteGradingCriteriaIfProgramHasNone(application_.program);
        setApplication(application_);
      }
    } catch (err) {
      addToast({
        type: "failure",
        message:
          "Υπήρξε κάποιο πρόβλημα με τη δημιουργία της αίτησης σας. Παρακαλώ ξαναπροσπαθήστε.",
        duration: 5000,
      });
    } finally {
      buttonRef.current?.setLoading(false);
    }
  };

  const onProgramSelection = useCallback((program) => {
    selectedProgram.current = program;
  }, []);

  useEffect(() => {
    getApplication();
  }, []);

  const onDirtyChange = useCallback((dirty) => setDirty(dirty), []);

  const checkpoint = useCallback(
    (application) => {
      formRef.current.checkpoint(application);
    },
    [formRef.current]
  );

  const onSuccessfulFinalize = useCallback(
    (application) => {
      formRef.current.checkpoint(
        application,
        () => (window.location.href = "/trainee/application")
      );
    },
    [formRef.current]
  );

  const programExpired =
    application?.program.isCompleted ||
    new Date() > new Date(application?.program.deadlines.traineeEndDate);
  return (
    <Panel
      headerBar="Η Αίτηση μου"
      footerBar={
        <>
          {application && !application.isFinalized && !programExpired && (
            <div className={styles.actions}>
              <SaveApplicationButton
                formRef={formRef}
                filePaths={FILE_PATHS.current}
                uploadUrl="/trainee/application/file"
                deleteUrl="/trainee/application/file"
                saveUrl="/trainee/application"
                onSuccess={checkpoint}
              />
              <FinalizeButton
                formRef={formRef}
                filePaths={FILE_PATHS.current}
                uploadUrl={"/trainee/application/file"}
                deleteUrl={"/trainee/application/file"}
                saveUrl={`/trainee/application/`}
                finalizeUrl={"/trainee/application/finalize"}
                onSuccess={onSuccessfulFinalize}
              />
            </div>
          )}
          {!application && programs && programs.length > 0 && (
            <ButtonWithLoading onClick={createApplication} ref={buttonRef}>
              Δημιουργία Αίτησης
            </ButtonWithLoading>
          )}
        </>
      }
    >
      {!application && programs && programs.length > 0 && (
        <div className={styles.container}>
          <h2> Δημιουργία Αίτησης </h2>
          <ProgramSelection
            programs={programs}
            onProgramSelection={onProgramSelection}
          />
        </div>
      )}
      {!application && programs && programs.length === 0 && (
        <div className={styles.noPrograms}>
          Δεν υπάρχουν διαθέσιμες υλοποιήσεις του προγράμματος πρακτικής άσκησης
          για τη δημιουργία αίτησης. <br />
          <div>
            Μπορείτε να επισκέπτεστε την <Link to="/">αρχική σελίδα</Link> για
            να ενημερώνεστε για τα ενεργά προγράμματα πρακτικής άσκησης.
          </div>
        </div>
      )}
      {application && (
        <>
          <ModalBlocker
            title="Η αίτηση σας δεν έχει αποθηκευτεί"
            message="Αν συνεχίσετε θα χάσετε τις αλλαγές που έχετε κάνει"
            when={dirty}
          ></ModalBlocker>
          <div className={styles.appTItle}>
            {application.program.information.areas.length > 1 ? (
              <h2>
                {" "}
                Αίτηση συμμετοχής για υλοποίηση πρακτικής άσκησης στις
                περιφέρειες {application.program.information.areas.join(
                  ", "
                )} - {application.program.information.carrier.title}{" "}
              </h2>
            ) : (
              <h2>
                {" "}
                Αίτηση συμμετοχής για υλοποίηση πρακτικής άσκησης στην
                περιφέρεια {application.program.information.areas[0]} -{" "}
                {application.program.information.carrier.title}{" "}
              </h2>
            )}

            {!application.isEvaluated && (
              <div className={styles.actions}>
                {application.isFinalized && (
                  <UnfinalizeButton
                    redirectUrl="/trainee/application"
                    unfinalizeUrl="/trainee/application/unfinalize"
                  />
                )}

                <DeletionButton
                  redirectUrl="/trainee/application"
                  deletionUrl="/trainee/application"
                />
              </div>
            )}
          </div>
          {!application.isFinalized && programExpired && (
            <ToastMessage
              className={styles.warning}
              type="failure"
              message={`Δεν προλάβατε να οριστικοποιήσετε την αίτηση σας. Οι αιτήσεις στο πρόγραμμα έχουν ολοκληρωθεί είτε λόγω συμπλήρωσης ατόμων είτε λόγω λήξης της προθεσμίας`}
            />
          )}
          {!application.isFinalized && !programExpired && (
            <>
              <ToastMessage
                className={styles.warning}
                type="warning"
                message={`Η αίτηση σας δεν έχει οριστικοποιηθεί. Η καταληκτική ημερομηνία οριστικοποίησης είναι στις ${new Date(
                  application.program.deadlines.traineeEndDate
                ).toLocaleDateString("el-GR", { dateStyle: "long" })}`}
              />
              <ToastMessage
                className={styles.warning}
                type="info"
                message={`Με την οριστικοποίηση της αίτησης θα εκδοθεί Κωδικός Αριθμός Υποβολής Αίτησης Συμμετοχής (ΚΑΥΑΣ) τον οποίο θα χρειαστείτε για κάθε μελλοντική αναφορά στην αίτησή σας. 
                Ο κωδικός αυτός θα φαίνεται στη σελίδα κατάστασης της αίτησης καθώς και στην προεπισκόπηση της οριστικοποιημένης αίτησης.`}
              />
              <p className={styles.agreement}>Eπισημαίνεται ότι η αίτηση σας υπέχει θέση υπεύθυνης δήλωσης στην οποία δηλώνεται:<br /><br />
                - ότι τα δηλωθέντα στοιχεία σας στην αίτηση είναι αληθή<br />
                - ότι αποδέχεστε  τους όρους συμμετοχής  της πρόσκλησης εκδήλωσης ενδιαφέροντος για συμμετοχή στην Πράξη «Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης επαγγελματικής εμπειρίας (ΙΕΚ)», όπως αυτή είναι δημοσιευμένη στο πληροφοριακό σύστημα, που έχει δημιουργηθεί αποκλειστικά για τους σκοπούς του έργου με MIS 5069416 και βρίσκεται στην ηλεκτρονική διεύθυνση www.e-iekpraktiki.gr</p>
            </>
          )}
          {application.isFinalized &&
            !application.isEvaluated &&
            !programExpired && (
              <ToastMessage
                className={styles.warning}
                type="info"
                message={`Μπορείτε να ακυρώσετε την οριστικοποίηση της αίτησης σας μέχρι αυτή να αξιολογηθεί από τον αρμόδιο φορέα.`}
              />
            )}
          {application.isFinalized && (
            <>
              <ToastMessage
                className={styles.warning}
                type="info"
                message={`Ο ΚΑΥΑΣ της αίτησης σας είναι: ${application.kayas}.`}
              />
              <ToastMessage
                className={styles.warning}
                type="info"
                message={`Πριν την ένταξή σας στο πρόγραμμα κατάρτισης θα πραγματοποιηθεί έλεγχος των δικαιολογητικών που έχετε αναρτήσει, από τον πάροχο κατάρτισης και σε περίπτωση αρνητικού ελέγχου, η αίτησή σας θα απορριφθεί.`}
              />
            </>
          )}
          <TraineeApplicationForm
            formRef={formRef}
            application={application}
            onDirtyChange={onDirtyChange}
            readOnly={application.isFinalized}
          />
        </>
      )}
    </Panel>
  );
};
