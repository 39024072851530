import React, { useEffect, useState } from "react";
import { usePageInfo } from "../../components/PageInfoWrapper";
import { SideNavPage } from "../../components/SideNavPage";
import { Users } from "./Users";
import { MdAppRegistration, MdManageAccounts } from "react-icons/md";
import { ProgramManagement } from "./ProgramManagement";

const options = [
  {
    Icon: MdManageAccounts,
    text: 'Διαχείριση Χρηστών',
    path: 'users',
    element: <Users />
  },
  {
    Icon: MdAppRegistration,
    text: 'Διαχείριση Προγράμματος',
    path: 'program-management',
    innerRouting: true,
    element: <ProgramManagement />
  },
];

function AdminPage() {
  const [hasAccess, setHasAccess] = useState(true);

  return (
    hasAccess &&
    <SideNavPage prefix='admin' options={options} />
  );
}

export default AdminPage;
