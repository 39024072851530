import businessApplicationJson_ from "../../../assets/data/business-application.json";
import { ApplicationForm } from "../../../components/ApplicationPageUtils/ApplicationForm";
import unit2municipality from "../../../assets/data/unit2municipality.json";
import region2unit from "../../../assets/data/region2unit.json";
import { usePageSize } from "../../../components/withPageSize";

export const BusinessApplicationForm = ({
  application,
  formRef,
  readOnly,
  onDirtyChange,
  businessApplicationJson = businessApplicationJson_
}) => {

  const dependancies = [
    {
      path: /^.*address\/area+$/,
      dependent: 'regionalUnit',
      options: (value) => {
        return region2unit[value];
      }
    },
    {
      path: /^.*address\/regionalUnit+$/,
      dependent: 'municipality',
      options: (value) => {
        return unit2municipality[value];
      }
    }
  ]

  return (
    <ApplicationForm
      formRef={formRef}
      description={businessApplicationJson}
      onDirtyChange={onDirtyChange}
      application={application}
      readOnly={readOnly}
      dependancies={dependancies}
      DownloadFileUrl={'/business/application/file/download'}
    />
  );

}