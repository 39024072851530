import { Form } from "../react-form/src/Form";

export function isAlpha(v) {
  return (
    typeof v === 'string' &&
    /^[a-zA-ZΆ-ωΑ-ώ\s]+$/.test(v)
  );
}

export function isAlphanumeric(v) {
  return (
    typeof v === 'string' &&
    /^[a-zA-Z0-9Ά-ωΑ-ώ\s]+$/.test(v)
  );
}

export function isPositiveInteger(v) {
  return (
    typeof v === 'number' &&
    /^[0-9]+$/.test(v)
  );
}

const MAX_STR_LENGTH = 255;

export function validateFirstName(v) {
  return isAlpha(v) && v.length < MAX_STR_LENGTH;
}

export function validateLastName(v) {
  return isAlpha(v) && v.length < MAX_STR_LENGTH;
}

export function validateFatherName(v) {
  return isAlpha(v) && v.length < MAX_STR_LENGTH;
}

export function validateMotherName(v) {
  return isAlpha(v) && v.length < MAX_STR_LENGTH;
}

export function validateDateOfBirth(v) {
  return true;
}

export function validateEmail(v) {
  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v)
  );
}

export function validatePassword(v) {
  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/.test(v)
  );
}

export function validateMobilePhoneNumber(v) {
  return typeof v === 'string' && /^69([0-9]{8})$/.test(v);
}

export function validateAfm(v) {
  const isNumber9 = typeof v === 'string' && /^[0-9]{9}$/.test(v);

  if (!isNumber9) return false;

  const afm = v.split('').map(digit => Number(digit));

  let sum = 0;
  let po2 = 1;

  for (let i = 0; i < afm.length - 1; ++i) {
    po2 = po2 * 2;
    sum += afm[afm.length - 2 - i] * po2;
  }

  return (sum % 11) % 10 === afm[8];
}

export function validateAmka(v) {
  const isNumber11 = typeof v === 'string' && /^[0-9]{11}$/.test(v);

  if (!isNumber11)
    return false;

  const amka = v.split('').map(digit => Number(digit));

  let sum = 0;

  for (let i = 0; i < amka.length - 1; ++i)
    if (i % 2 === 0)
      sum += amka[i];
    else {
      const doubled = 2 * amka[i];
      sum += (doubled <= 9) ? doubled : (doubled % 10 + Math.floor(doubled / 10) % 10);
    }

  return (sum * 9 % 10) === amka[10];
}

export function validateAma(v) {
  //todo
  //online sources say it's either 5 / 7 / 9 digits? https://www.fomo.gr/evresi-ama-ika-online/
  //cannot find a checksum or some sort of validation...

  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH &&
    /^[0-9]+$/.test(v)
  );
}

// TODO: check again
export function validateIdentity(v) {
  //todo
  //needs more research as online sources may be depricated
  //accept greek and latin and convert to latin in the backend?
  return true;
  return typeof v === 'string' && /^[A-Z]{2}\s?[0-9]{6}$/.test(v);
}

export function validateDoy(v) {
  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH
  );
}

export function validateIek(v) {
  return typeof v === 'string' && v.length < MAX_STR_LENGTH;
}

export function validateCity(v) {
  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH
  );
}

export function validateStreet(v) {
  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH
  );
}

export function validateStreetNumber(v) {
  return (
    typeof v === 'string' &&
    v.length < MAX_STR_LENGTH &&
    /^[1-9]+[0-9]*$/.test(v)
  );
}

export function validatePostalCode(v) {
  return (
    typeof v === 'string' &&
    /^[1-9][0-9]{4}$/.test(v)
  );
}

export function validatePhoneNumber(v) {
  return typeof v === 'string' && /^([0-9]{10})$/.test(v);
}

export function validateCurrentTrainees(v) {
  return isPositiveInteger(v);
}

export function validateAskedCount(v) {
  return isPositiveInteger(v);
}

export function registerFormRestrictions() {
  Form.registerRestrictionCheck({
    tag: "validateFirstName",
    func: validateFirstName,
    explanation: "Το όνομα μπορεί να περιέχει μόνο λατινικούς και ελληνικούς χαρακτήρες"
  });
  Form.registerRestrictionCheck({
    tag: "validateLastName",
    func: validateFirstName,
    explanation: "Το επώνυμο μπορεί να περιέχει μόνο λατινικούς και ελληνικούς χαρακτήρες"
  });
  Form.registerRestrictionCheck({
    tag: "validateLastName",
    func: validateLastName,
    explanation: "Το επώνυμο μπορεί να περιέχει μόνο λατινικούς και ελληνικούς χαρακτήρες"
  });
  Form.registerRestrictionCheck({
    tag: "validateFatherName",
    func: validateFatherName,
    explanation: "Το όνομα πατρός μπορεί να περιέχει μόνο λατινικούς και ελληνικούς χαρακτήρες"
  });
  Form.registerRestrictionCheck({
    tag: "validateMotherName",
    func: validateMotherName,
    explanation: "Το όνομα μητρός μπορεί να περιέχει μόνο λατινικούς και ελληνικούς χαρακτήρες"
  });
  Form.registerRestrictionCheck({
    tag: "validateDateOfBirth",
    func: validateDateOfBirth,
    explanation: ""
  });
  Form.registerRestrictionCheck({
    tag: "validateEmail",
    func: validateEmail,
    explanation: "Το email που πληκτρολογήσατε δεν έχει την αναμενόμενη μορφή. Παράδειγμα: example@domain.com"
  });
  Form.registerRestrictionCheck({
    tag: "validatePassword",
    func: validatePassword,
    explanation: "Ο κωδικός πρόσβασης πρέπει να αποτελείται απο 8 έως 20 ψηφία με λατινικούς χαρακτήρες και να περιέχει τουλάχιστον: ένα κεφαλαίο γράμμα, ένα μικρό γράμμα, έναν αριθμό και ένα σύμβολο από τα !@#$%^&*_=+-"
  });
  Form.registerRestrictionCheck({
    tag: "validateMobilePhoneNumber",
    func: validateMobilePhoneNumber,
    explanation: "Το τηλέφωνο που πληκτρολογήσατε δεν έχει τη μορφή αριθμού κινητού τηλεφώνου"
  });
  Form.registerRestrictionCheck({
    tag: "validateAfm",
    func: validateAfm,
    explanation: "To Α.Φ.Μ πρέπει να αποτελείται από 9 ψηφία και να είναι ορθό"
  });
  Form.registerRestrictionCheck({
    tag: "validateAmka",
    func: validateAmka,
    explanation: "To Α.Μ.Κ.Α πρέπει να αποτελείται από 11 ψηφία και να είναι ορθό"
  });
  Form.registerRestrictionCheck({
    tag: "validateAma",
    func: validateAma,
    explanation: "To Α.Μ.Α πρέπει να είναι αριθμός"
  });
  Form.registerRestrictionCheck({
    tag: "validateIdentity",
    func: validateIdentity,
    explanation: "Ο αριθμός ταυτότητας πρέπει να αποτελείται από 2 κεφαλαία λατινικά γράμματα και 6 ψηφία"
  });
  Form.registerRestrictionCheck({
    tag: "validateDoy",
    func: validateDoy,
    explanation: ""
  });
  Form.registerRestrictionCheck({
    tag: "validateIek",
    func: validateIek,
    explanation: ""
  });
  Form.registerRestrictionCheck({
    tag: "validateCity",
    func: validateCity,
    explanation: ""
  });
  Form.registerRestrictionCheck({
    tag: "validateStreet",
    func: validateStreet,
    explanation: ""
  });
  Form.registerRestrictionCheck({
    tag: "validateStreetNumber",
    func: validateStreetNumber,
    explanation: "Παρακαλώ πληκτρολογήστε αριθμό"
  });
  Form.registerRestrictionCheck({
    tag: "validatePostalCode",
    func: validatePostalCode,
    explanation: "Ο ταχυδρομικός κώδικας πρέπει να αποτελείται από 5 ψηφία"
  });

  Form.registerRestrictionCheck({
    tag: "validatePhoneNumber",
    func: validatePhoneNumber,
    explanation: "Το τηλέφωνο που πληκτρολογήσατε δεν έχει τη μορφή σταθερού ή κινητού τηλεφώνου"
  });
  Form.registerRestrictionCheck({
    tag: "validateCurrentTrainees",
    func: validateCurrentTrainees,
    explanation: "Παρακαλώ πληκτρολογήστε αριθμό"
  });
  Form.registerRestrictionCheck({
    tag: "validateAskedCount",
    func: validateAskedCount,
    explanation: "Παρακαλώ πληκτρολογήστε αριθμό"
  });



}