import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useClient } from "../../components/ClientWrapper";
import LoaderWrapper from "../../components/LoaderWrapper";
import { SuccessMessage } from "../../components/SuccessMessage";
import { useToasts } from "../../components/ToastMessageWrapper";
import { useUser } from "../../components/UserWrapper";

import styles from '../../styles/VerifyEmail.module.css'

export const VerifyEmail = () => {
  const [searchParams] = useSearchParams();

  const { user, setUser } = useUser();
  const axios = useClient();

  const token = searchParams.get("token");

  const addToast = useToasts();

  const navigate = useNavigate();

  const triedToVerify = useRef(false);


  useEffect(() => {
    if (!triedToVerify.current && user.isVerified) return navigate("/");

    if (!triedToVerify.current && !user.isVerified) {
      triedToVerify.current = true;
      verify();
    }
  }, []);

  const verify = async () => {
    try {
      await axios.post(`user/verify-email`, { token });

      setUser({ ...user, isVerified: true });
    } catch (err) {
      addToast({
        type: "failure",
        message: 'Ο σύνδεσμος επιβεβαίωσης που χρησιμοποιήσατε δεν είναι ορθός',
        duration: 5000,
      });
      navigate("/");
    }
  };

  return (
    <div className={styles.container}>
      <LoaderWrapper size={'75'} thickness={'7.5px'} loading={!(user.isVerified && triedToVerify.current)} >
          <SuccessMessage
            message={
                <div className={styles.messageContainer}>
                    Η διεύθυνση ηλεκτρονικού ταχυδρομείου σας επιβεβαιώθηκε επιτυχώς
                    <ButtonWithLoading onClick={() => window.location.href = '/'}> Πίσω στην αρχική </ButtonWithLoading>
                </div>
            }
          />
      </LoaderWrapper>
    </div>
  );
};
