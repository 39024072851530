import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../components/ClientWrapper";
import LoaderWrapper from "../../components/LoaderWrapper";
import { Panel } from "../../components/Panel";
import { Tabs } from "../../components/Tabs";
import { useUser } from "../../components/UserWrapper";
import styles from "../../styles/Admin/Programs.module.css";

const ProgramTab = (props) => {
  const [programs, setPrograms] = useState(null);
  const axios = useClient();

  const getUrl = () => {
    let url = "/program",
      isFirst = true;

    for (let searchParam of ["isPublished", "isCompleted", "carrier"]) {
      if (props[searchParam] !== undefined) {
        url += `${isFirst ? "?" : "&"}${searchParam}=${props[searchParam]}`;
        isFirst = false;
      }
    }

    return url;
  };

  useEffect(() => {
    setPrograms(null);

    const url = getUrl();

    axios
      .get(url)
      .then((res) => {
        setPrograms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.isPublished, props.isCompleted]);

  return (
    <div className={styles.programTabContainer}>
      <LoaderWrapper size={'75'} thickness={'7.5px'} loading={!programs}>
        <ProgramList programs={programs} />
      </LoaderWrapper>
    </div>
  );
};

const ProgramList = ({ programs }) => {
  const navigate = useNavigate();

  const viewProgram = (program) => {
    navigate(`../view-program/${program._id}`);
  };

  if (!programs)
    return null;

  return (
    <div className={styles.programTitles}>
      {programs.map((program) => (
        <div
          key={program._id}
          className={styles.programTitle}
          onClick={() => viewProgram(program)}
        >
          {program.information.title}
        </div>
      ))}
    </div>
  );
};

const AllPrograms_CarrierAdmin = ({ carrierId }) => {
  const navigate = useNavigate();
  const [carrier, setCarrier] = useState();
  const axios = useClient();

  const tabs = [
    {
      title: "Μη Δημοσιευμένες",
      content: <ProgramTab isPublished={false} carrier={carrierId} />,
    },
    {
      title: "Δημοσιευμένες",
      content: <ProgramTab isPublished={true} isCompleted={false} carrier={carrierId} />,
    },
    {
      title: "Ολοκληρωμένες",
      content: <ProgramTab isCompleted={true} carrier={carrierId} />,
    },
  ];

  const getCarrier = () => {
    axios.get(`/carrier/${carrierId}`)
      .then(res => {
        setCarrier({
          id: res.data._id,
          title: res.data.title
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(getCarrier, []);

  return (
    <Panel headerBar={"Διαχείριση Προγράμματος"}>
      {
        carrier &&
        <>
          <div className={`${styles.titleContainer} ${styles.allPrograms}`}>
            <h2> Όλες οι υλοποιήσεις από το φορέα {carrier.title} </h2>
            <button
              className="defaultButton"
              onClick={() => navigate("../create-program")}
            >
              <BsPlus size="25px" />
              Δημιουργία Υλοποίησης Προγράμματος
            </button>
          </div>
          <Tabs tabs={tabs} />
        </>
      }
    </Panel>
  );
}

const AllPrograms_SuperAdmin = () => {
  const navigate = useNavigate();

  const tabs = [
    {
      title: "Μη Δημοσιευμένες",
      content: <ProgramTab isPublished={false} isCompleted={false} />,
    },
    {
      title: "Δημοσιευμένες",
      content: <ProgramTab isPublished={true} isCompleted={false} />,
    },
    {
      title: "Ολοκληρωμένες",
      content: <ProgramTab isCompleted={true} />,
    },
  ];

  return (
    <Panel headerBar={"Διαχείριση Προγράμματος"}>
      <div className={`${styles.titleContainer} ${styles.allPrograms}`}>
        <h2> Όλες οι υλοποιήσεις </h2>
        <button
          className="defaultButton"
          onClick={() => navigate("../create-program")}
        >
          <BsPlus size="25px" />
          Δημιουργία Υλοποίησης Προγράμματος
        </button>
      </div>
      <Tabs tabs={tabs} />
    </Panel>
  );
}

export const AllPrograms = () => {
  const { user } = useUser();

  if (!user || !user.isAdmin) return null;

  if (user.carrier)
    return <AllPrograms_CarrierAdmin carrierId={user.carrier} />;
  else
    return <AllPrograms_SuperAdmin />;
};