import React, { useEffect, useState } from "react";
import { BsInfoCircleFill, BsClipboardData } from "react-icons/bs";
import { TraineeStatus } from "./TraineeStatus";
import { TraineeInfo } from "./TraineeInfo";
import { SideNavPage } from "../../../components/SideNavPage";
import { useClient } from "../../../components/ClientWrapper";
import LoaderWrapper from "../../../components/LoaderWrapper";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { TraineeApplication } from "./TraineeApplication";
import { AiTwotoneFile } from "react-icons/ai";

const options = [
  {
    Icon: BsInfoCircleFill,
    text: 'Πληροφορίες',
    path: 'info',
    element: <TraineeInfo />
  },
  {
    Icon: AiTwotoneFile,
    text: 'Η Αίτηση μου',
    path: 'application',
    element: <TraineeApplication />
  },
  {
    Icon: BsClipboardData,
    text: 'Κατάσταση Αίτησης',
    path: 'status',
    element: <TraineeStatus />
  }
];

function TraineePage() {
  const [application, setApplication] = useState(null);
  const axios = useClient();

  const getApplication = () => {
    axios.get(`/trainee/application/`)
      .then(res => {
        setApplication(res.data.application || {});
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    getApplication();
  }, []);

  return (
    <LoaderWrapper loading={!application} size={'75'} thickness={'7.5px'}>
      {
        application &&
        <SideNavPage options={options} />
      }
    </LoaderWrapper>
  );
}

export default TraineePage;
