import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePageInfo } from "../../../components/PageInfoWrapper";
import { useClient } from "../../../components/ClientWrapper";
import { Panel } from "../../../components/Panel";
import { ModalBlocker } from "../../../components/ModalBlocker";
import { SaveApplicationButton } from "../../SaveApplicationButton";
import { BusinessApplicationForm } from "./BusinessApplicationForm";
import styles from "../../../styles/Business/BusinessApplication.module.css";
import GridWithFilters from "../../../components/GridWithFilters";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { ProgramSelection } from "../../../components/ApplicationPageUtils/Program";
import { Link } from "react-router-dom";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";
import { IoOpenOutline } from "react-icons/io5";
import { _ } from "gridjs-react";
import { FinalizeButton } from "../../../components/ApplicationPageUtils/ApplicationFinalizeButton";
import { UnfinalizeButton } from "../../../components/ApplicationPageUtils/ApplicationUnfinalizeButton";
import { DeletionButton } from "../../../components/ApplicationPageUtils/ApplicationDeletionButton";
import {
  ToastMessage,
  useToasts,
} from "../../../components/ToastMessageWrapper";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Form } from "../../../react-form/src/Form";
import businessPositionsJson from "../../../assets/data/business-positions.json";

import { BsPencilFill } from "react-icons/bs";
import BusinessEditInfoPage from "./BusinessEditInfoPage";
import { BusinessInfoForm } from "./BusinessInfoForm";
import { usePageSize } from "../../../components/withPageSize";

const FILE_PATHS = { documents: ["yearlyStaffStatusFile"] };

export const BusinessApplications = () => {
  return (
    <Routes>
      <Route path="/all-applications" element={<AllApplications />} />
      <Route path="/edit-info" element={<BusinessEditInfoPage />} />
      <Route path="/create" element={<CreateApplication />} />
      <Route path="/:id" element={<BusinessApplication />} />
      <Route path="/" element={<BusinessApplicationDetermineRedirection />} />
    </Routes>
  );
};

const BusinessApplicationDetermineRedirection = () => {
  const axios = useClient();
  const navigate = useNavigate();

  const determineFirstVisit = () => {
    axios
      .get("/business/application/info")
      .then((response) => {
        if (response.data !== null) {
          navigate("all-applications");
        } else {
          navigate("edit-info");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  determineFirstVisit();

  return <Panel headerBar="Αίτηση Επιχείρησης" />;
};

const BusinessApplication = () => {
  const { id: appId } = useParams();
  const axios = useClient();
  const navigate = useNavigate();

  const formRef = useRef();
  const errorRef = useRef(null);

  const [application, setApplication] = useState(null);
  const [dirty, setDirty] = useState(false);

  const getApplication = () => {
    axios
      .get(`/business/application/${appId}`)
      .then((res) => {
        // --!-- setting fields to app form business info to correctly align with description
        let app = res.data.application;
        app = { ...app.businessInfo, ...app };

        setApplication(app);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Form.registerRestrictionCheck({
      tag: "validateAskedCount",
      func: () => {
        return !errorRef.current;
      },
      explanation: "-",
    });
    getApplication();
  }, []);

  const onDirtyChange = useCallback((dirty) => setDirty(dirty), []);

  const checkpoint = useCallback(
    (application) => {
      formRef.current.checkpoint(application);
    },
    [formRef.current]
  );

  const onSuccessfulFinalize = useCallback(
    (application) => {
      formRef.current.checkpoint(application, () =>
        navigate("../all-applications")
      );
    },
    [formRef.current]
  );

  const programExpired =
    application?.program.isCompleted ||
    new Date() > new Date(application?.program.deadlines.businessEndDate);
  return (
    <Panel
      headerBar="Αίτηση Επιχείρησης"
      footerBar={
        application &&
        !application.isFinalized &&
        !programExpired && (
          <>
            <SaveApplicationButton
              formRef={formRef}
              filePaths={FILE_PATHS}
              uploadUrl="/business/application/file"
              deleteUrl="/business/application/file"
              saveUrl={`/business/application/${appId}`}
              onSuccess={checkpoint}
            />
            <FinalizeButton
              formRef={formRef}
              filePaths={FILE_PATHS}
              uploadUrl={"/business/application/file"}
              deleteUrl={"/business/application/file"}
              saveUrl={`/business/application/${appId}`}
              finalizeUrl={`/business/application/${appId}/finalize`}
              onSuccess={onSuccessfulFinalize}
            />
          </>
        )
      }
    >
      {application && (
        <>
          <ModalBlocker
            title="Η αίτηση σας δεν έχει αποθηκευτεί"
            message="Αν συνεχίσετε θα χάσετε τις αλλαγές που έχετε κάνει"
            when={dirty}
          />
          <div className={styles.appContainer}>
            <div className={styles.title}>
              <Link className="link" to="..">
                <IoIosArrowBack />
                Πίσω
              </Link>

              {application.program.information.areas.length > 1 ? (
                <h2>
                  {" "}
                  Αίτηση συμμετοχής για υλοποίηση πρακτικής άσκησης στις
                  περιφέρειες {application.program.information.areas.join(
                    ", "
                  )}{" "}
                  - {application.program.information.carrier.title}{" "}
                </h2>
              ) : (
                <h2>
                  {" "}
                  Αίτηση συμμετοχής για υλοποίηση πρακτικής άσκησης στην
                  περιφέρεια {application.program.information.areas[0]} -{" "}
                  {application.program.information.carrier.title}{" "}
                </h2>
              )}

              {!application.isEvaluated && (
                <div className={styles.actions}>
                  {application.isFinalized && (
                    <UnfinalizeButton
                      redirectUrl={`/business/application/${appId}`}
                      unfinalizeUrl={`/business/application/${appId}/unfinalize`}
                    />
                  )}

                  <DeletionButton
                    redirectUrl="/business/application/all-applications"
                    deletionUrl={`/business/application/${appId}`}
                  />
                </div>
              )}
            </div>
            {!application.isFinalized && programExpired && (
              <ToastMessage
                className={styles.warning}
                type="failure"
                message={`Δεν προλάβατε να οριστικοποιήσετε την αίτηση σας. Οι αιτήσεις στο πρόγραμμα έχουν ολοκληρωθεί είτε λόγω συμπλήρωσης ατόμων είτε λόγω λήξης της προθεσμίας`}
              />
            )}
            {!application.isFinalized && !programExpired && (
              <>
                <ToastMessage
                  className={styles.warning}
                  type="warning"
                  message={`Η αίτηση σας δεν έχει οριστικοποιηθεί.`}
                />
                <ToastMessage
                  className={styles.warning}
                  type="info"
                  message={`Με την οριστικοποίηση της αίτησης θα εκδοθεί Κωδικός Αριθμός Υποβολής Αίτησης Συμμετοχής (ΚΑΥΑΣ) τον οποίο θα χρειαστείτε για κάθε μελλοντική αναφορά στην αίτησή σας. Ο κωδικός αυτός θα φαίνεται στον πίνακα αιτήσεων σας.`}
                />
              </>
            )}
            {application.isFinalized &&
              !application.isEvaluated &&
              !programExpired && (
                <ToastMessage
                  className={styles.warning}
                  type="info"
                  message={`Πριν την ένταξή σας στο πρόγραμμα κατάρτισης θα πραγματοποιηθεί έλεγχος των δικαιολογητικών που έχετε αναρτήσει, από τον πάροχο κατάρτισης και σε περίπτωση αρνητικού ελέγχου, η αίτησή σας θα απορριφθεί.\n
                Μπορείτε να ακυρώσετε την οριστικοποίηση της αίτησης σας ή να τη διαγράψετε μέχρι αυτή να αξιολογηθεί από τον αρμόδιο φορέα.`}
                />
              )}
            {application.isEvaluated && (
              <ToastMessage
                className={styles.warning}
                type={application.evaluation.accepted ? "success" : "failure"}
                message={
                  <div className={styles.evaluationMessage}>
                    {application.evaluation.accepted
                      ? "Η αίτηση σας έγινε αποδεκτή από τον αρμόδιο φορέα"
                      : "Η αίτηση σας απορρίφθηκε από τον αρμόδιο φορέα"}
                    {application.evaluation.comment && (
                      <div className={styles.evaluationComment}>
                        <div> Σχόλιο Αξιολόγησης </div>
                        <div> {application.evaluation.comment} </div>
                      </div>
                    )}
                  </div>
                }
              />
            )}
            {!application.isFinalized && (
              <div>
                <BusinessInfoExpandable
                  businessInfo={application.businessInfo}
                />
                <p className={styles.agreement}>Eπισημαίνεται ότι η αίτηση σας υπέχει θέση υπεύθυνης δήλωσης στην οποία δηλώνεται:<br /><br />
                  - ότι τα δηλωθέντα στοιχεία σας στην αίτηση είναι αληθή<br />
                  - ότι αποδέχεστε  τους όρους συμμετοχής  της πρόσκλησης εκδήλωσης ενδιαφέροντος για συμμετοχή στην Πράξη «Ειδικά προγράμματα διεξαγωγής πρακτικής άσκησης και απόκτησης επαγγελματικής εμπειρίας (ΙΕΚ)», όπως αυτή είναι δημοσιευμένη στο πληροφοριακό σύστημα, που έχει δημιουργηθεί αποκλειστικά για τους σκοπούς του έργου με MIS 5069416 και βρίσκεται στην ηλεκτρονική διεύθυνση www.e-iekpraktiki.gr</p>
                <BusinessApplicationForm
                  businessApplicationJson={businessPositionsJson}
                  formRef={formRef}
                  application={application}
                  onDirtyChange={onDirtyChange}
                  readOnly={false}
                />
              </div>
            )}
            {application.isFinalized && (
              <BusinessApplicationForm
                formRef={formRef}
                application={application}
                onDirtyChange={onDirtyChange}
                readOnly
              />
            )}
          </div>
        </>
      )}
    </Panel>
  );
};

const BusinessInfoExpandable = ({ businessInfo }) => {
  const businessInfoRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <ToastMessage
        className={`${styles.warning} ${styles.businessInfoWarning}`}
        type="info"
        message={
          <div className={styles.businessInfoExpandable} onClick={toggle}>
            Μαζί με την αίτηση σας αποστέλλονται και τα βασικά στοιχεία
            επιχείρησης σας.
            {isExpanded ? (
              <IoIosArrowDown className={styles.arrow} />
            ) : (
              <IoIosArrowForward className={styles.arrow} />
            )}
          </div>
        }
      />
      {isExpanded && (
        <div className={styles.businessInfoExpandableContent}>
          <BusinessInfoForm
            formRef={businessInfoRef}
            info={businessInfo}
            readOnly={true}
          />
        </div>
      )}
    </div>
  );
};

const ApplicationTable = ({ applications }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const width = usePageSize();

  useEffect(() => {
    setData(
      applications.map((app) => {
        return [
          app._id,
          app.kayas || "Δεν έχει εκδοθεί",
          app.program.information.areas.join(", "),
          app.isEvaluated
            ? "Αξιολογημένη"
            : app.isFinalized
              ? "Οριστικοποιημένη"
              : "Μη υποβεβλημένη",
        ];
      })
    );
  }, [applications]);

  const columns = [
    {
      id: "id",
      name: "id",
      hidden: true,
    },
    {
      id: "kayas",
      name: "ΚΑΥΑΣ",
    },
    {
      id: "program",
      name: "Περιφέρεια",
    },
    {
      id: "status",
      name: "Κατάσταση",
    },
    {
      id: "action",
      name: "action",
      formatter: (cell, row) => {
        return _(
          <div className={styles.openButtonContainer}>
            <IoOpenOutline
              className={styles.openButton}
              onClick={() => navigate(`../${row.cells[0].data}`)}
            />
          </div>
        );
      },
    },
  ];

  const language = {
    noRecordsFound: "Δεν βρέθηκαν αιτήσεις...",
  };

  if (!data) return null;

  return (
    <div>
      {width <= 1000 ? (
        <div className={styles.applicationCards}>
          {data.map((applicationRow) => (
            <div className={styles.applicationCard} onClick={() => navigate(`../${applicationRow[0]}`)}>
              <div> Αίτηση στην περιφέρεια {applicationRow[2]} </div>
              <div className={styles.kayas}>
                {applicationRow[1] !== "Δεν έχει εκδοθεί" &&
                  applicationRow[1]}
              </div>
              <div className={styles.status}> {applicationRow[3]} </div>
            </div>
          ))}
        </div>
      ) : (
        <GridWithFilters data={data} columns={columns} language={language} />
      )}
      <div className={styles.createApplicationWrapper}>
        <button className="defaultButton" onClick={() => navigate("../create")}>
          Νέα Αίτηση
        </button>
      </div>
    </div>
  );
};

export const CreateApplication = () => {
  const axios = useClient();
  const [programs, setPrograms] = useState(null);
  const selectedProgram = useRef(null);
  const navigate = useNavigate();
  const addToast = useToasts();
  const buttonRef = useRef(null);

  useEffect(() => {
    getPrograms();
  }, []);

  const getPrograms = () => {
    axios
      .get(
        "/program?isPublished=true&isCompleted=false&inBusinessDeadline=true"
      )
      .then((res) => {
        const programs = res.data || [];

        programs.forEach((program) => {
          program.deadlines.startDate = program.deadlines.businessStartDate;
          program.deadlines.endDate = program.deadlines.businessEndDate;
        });

        setPrograms(programs);
      })
      .catch((err) => { });
  };

  const onProgramSelection = useCallback((program) => {
    selectedProgram.current = program;
  }, []);

  const createApplication = async () => {
    const program = selectedProgram.current;
    buttonRef.current.setLoading(true);

    try {
      await axios.post("/business/application", { program: program._id });

      navigate("../all-applications");
    } catch (err) {
      addToast({
        type: "failure",
        message:
          "Δεν μπορείτε να υποβάλλετε δεύτερη αίτηση στην ίδια περιφέρεια",
        duration: 5000,
      });
    } finally {
      buttonRef.current?.setLoading(false);
    }
  };

  return (
    <Panel
      headerBar="Αίτηση Επιχείρησης"
      footerBar={
        programs &&
        programs.length > 0 && (
          <ButtonWithLoading onClick={createApplication} ref={buttonRef}>
            Επιβεβαίωση Επιλογής
          </ButtonWithLoading>
        )
      }
    >
      {programs && programs.length === 0 && (
        <div className={styles.noPrograms}>
          Δεν υπάρχουν διαθέσιμες υλοποιήσεις του προγράμματος πρακτικής άσκησης
          για τη δημιουργία αίτησης. <br />
          <div>
            Μπορείτε να επισκέπτεστε την <Link to="/">αρχική σελίδα</Link> για
            να ενημερώνεστε για τα ενεργά προγράμματα πρακτικής άσκησης.
          </div>
        </div>
      )}
      {programs && programs.length > 0 && (
        <div className={styles.container}>
          <div className={styles.title}>
            <Link className="link" to="..">
              <IoIosArrowBack />
              Πίσω
            </Link>
            <h2> Δημιουργία Αίτησης - Επιλογή Περιφέρειας </h2>
          </div>
          <ToastMessage
            className={styles.warning}
            type="info"
            message={`Μετά την επιλογή περιφέρειας θα πρέπει να επεξεργαστείτε και να οριστικοποιήσετε την αίτηση συμμετοχής απο τον πίνακα αιτήσεων σας.`}
          />
          <ProgramSelection
            programs={programs}
            onProgramSelection={onProgramSelection}
          />
        </div>
      )}
    </Panel>
  );
};

export const AllApplications = () => {
  const [applications, setApplications] = useState(null);
  const [unfinalizedApplications, setUnfinalizedApplications] = useState(null);
  const axios = useClient();
  const navigate = useNavigate();

  useEffect(() => {
    getApplications();
  }, []);

  const getApplications = async () => {
    try {
      const res = await axios.get("/business/application");
      setApplications(res.data.applications);
      setUnfinalizedApplications(
        countUnfinalizedApplications(res.data.applications)
      );
    } catch (err) { }
  };

  const countUnfinalizedApplications = (apps) => {
    let numberOfUnfinalizedApplications = 0;

    apps.forEach((app) => {
      if (!app.isFinalized) numberOfUnfinalizedApplications++;
    });

    return numberOfUnfinalizedApplications;
  };

  const constructUnfinalizedApplicationsMessage = () => {
    if (unfinalizedApplications > 1)
      return `Εκκρεμούν ${unfinalizedApplications} αιτήσεις που δεν έχουν οριστικοποιηθεί.`;
    else if (unfinalizedApplications === 1)
      return `Εκκρεμεί ${unfinalizedApplications} αίτηση που δεν έχει οριστικοποιηθεί.`;
  };

  return (
    <Panel headerBar="Αίτηση Επιχείρησης">
      {applications && (
        <div className={styles.container}>
          <div className={styles.title}>
            <h2> Στοιχεία Επιχείρησης</h2>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Βασικά Στοιχεία Επιχείρησης</div>
            <button
              className={`defaultButton ${styles.edit} ${styles.cardEdit}`}
              onClick={() => navigate(`../edit-info`)}
            >
              <BsPencilFill />
              Επεξεργασία
            </button>
          </div>
          {unfinalizedApplications > 0 && (
            <ToastMessage
              className={styles.warning}
              type="warning"
              message={constructUnfinalizedApplicationsMessage()}
            />
          )}

          <div className={styles.title}>
            <h2> Οι Αιτήσεις μου </h2>
          </div>

          <ApplicationTable applications={applications} />
        </div>
      )}
    </Panel>
  );
};
