import { Input } from "./Input";
import CreatableSelect from "react-select/creatable";
import ErrorPrompt from "./ErrorPrompt";

// ---------------------------------------------------------------------------------------

const COMMA_KEY_CODE = 188;

// ---------------------------------------------------------------------------------------

const placeholder = 'Εισαγωγή...';
const noOptionsMessage = () => `Πληκτρολογείστε για να ξεκινήσετε`;
const formatCreateLabel = label => `Προσθήκη '${label}'`;

// ---------------------------------------------------------------------------------------

export class KeywordInput extends Input {

    constructor(props) {

        super(props);

        this.onChange = this.onChange.bind(this);
        this.onChangeSelection = this.onChangeSelection.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.state.value = this.state.value || "";

        this.colourStyles = {
            option: (styles, { isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                width: 'calc(100% - 8px)',
                marginLeft: '4px',
                marginRight: '4px',
                marginTop: '4px',
                marginBottom: '4px',
                borderRadius: '3px',
                backgroundColor: isSelected ? 'var(--primary)' : isFocused ? 'lightgrey' : 'white',
                color: isSelected ? 'var(--main-theme-text-over-primary)' : 'black',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              };
            },
        };

    }

    getData() {
        return (this.state.value.length === 0) ? null : this.state.value;
    }

    onChange(e, onFinish) {
        const value = e.map(i => i.value).join(',');
        this.setState({ value }, onFinish);
    }

    onChangeSelection(e) {
        const value = e.map(i => i.value).join(',');
        this.setState({ value });
    }

    onKeyDown(e) {
        const keyPressed = Number(e.which || e.keyCode);
        const isComma = keyPressed === COMMA_KEY_CODE;
        if(isComma) { e.preventDefault(); }
    }

    readOnlyRender() {

        const keywords = this.state.value.split(',').map(keyword => 
            <span key={keyword} className="badge">{keyword}</span>
        );

        return <>
            {this.props.label}
            <div className="readOnly-value-wrap readOnly-value-wrap-keyword">
                {keywords}
            </div>
        </>

    }

    computeDefaultValue() {
        if(!this.state.value) { return []; }
        return this.state.value.split(',').map(value => ({ 
            label: value, 
            value 
        }));
    }

    render() {
        
        if(this.state.isHidden) {
            return null;
        }

        if(this.props.readOnly) {
            return this.readOnlyRender();
        }

        const defaultValue = this.computeDefaultValue();

        return <>
            <div ref={this.labelRef} className={`form-item-input-title${this.state.problem ? '-error' : ''}`}>{this.props.label}</div>
            <label style={{width: '100%'}} aria-label={this.props.description.title || this.props.description.name}>
                <CreatableSelect
                    isMulti
                    defaultValue={defaultValue}
                    onKeyDown={this.onKeyDown}
                    styles={this.colourStyles}
                    placeholder={placeholder}
                    onChange={this.onChangeSelection}
                    isDisabled={this.props.description.isDisabled}
                    className ={"MyDropdown"}
                    classNamePrefix={"MyDropdown"}
                    formatCreateLabel={formatCreateLabel}
                    noOptionsMessage={noOptionsMessage}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.25rem',
                        colors: {
                            ...theme.colors,
                            primary: 'var(--primary)',
                        },
                    })}
                />
            </label>
            {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
        </>
    }

};

// ---------------------------------------------------------------------------------------