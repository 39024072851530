import { Route } from "react-router-dom";
import { ProtectRoute } from "../../components/ProtectRoute";
import { Error403 } from "../Error/Error403";
import LoginPage from "./LoginPage";

export const PERMISSIONS = { Admin: 'isAdmin', Employee: 'isEmployee' };

export const ProtectedRoute = (
  {path, permissions, element, ...props}
) => {

  return (
    <Route
      path={path}
      element={
        <ProtectRoute
          LoginPage={<LoginPage/>}
          Error403Page={<Error403/>}
          permissions={permissions}
        >
          {element}
        </ProtectRoute>
      }
      {...props}
    />
  );
}
